import { BaseFilter, DotNotationPaths } from '@draftt-io/drafttql-syntax'
import { PolicyComponentAttributes } from '@draftt-io/shared-types'
import { jwtDecode } from 'jwt-decode'
import { get } from 'lodash'

export type ObjectValues<T> = T[keyof T]

export const isInternalEmployee = (userEmail: string | undefined, sessionToken: string) => {
  const decodedToken = jwtDecode(sessionToken)
  const isImpersonation = get(decodedToken, 'act.sub') ? true : false
  return (userEmail && userEmail.endsWith('@draftt.io')) || isImpersonation
}

export const allowedGridFilterOperators = ['equals', 'contains', 'doesNotEqual', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf', '<', '=', '>', '>=', '<='] as const
export type GridFilterOperation = typeof allowedGridFilterOperators[number]
export function gridFilterToDrafttQLOperator(key: DotNotationPaths<PolicyComponentAttributes>, value: any, operator: GridFilterOperation): BaseFilter<PolicyComponentAttributes> | null{
  if (operator !== 'isEmpty' && operator !== 'isNotEmpty'){
    if (value === undefined){
      return null
    }
  }
  switch (operator){
    case 'equals':
    case '=':
      return {[key]: {$eq: value}}
    case 'doesNotEqual':
      return {[key]: {$ne: value}}
    case 'contains':
      return {[key]: {$ilike: `*${value}*`}}
    case 'endsWith':
      return {[key]: {$ilike: `*${value}`}}
    case 'startsWith':
      return {[key]: {$ilike: `${value}*`}}
    case 'isAnyOf':
      return {[key]: {$in: value}}
    case 'isEmpty':
      return {[key]: {$exists: false}}
    case 'isNotEmpty':
      return {[key]: {$exists: true}}
    case '>':
      return {[key]: {$gt: value}}
    case '<':
      return {[key]: {$lt: value}}
    case '>=':
      return {[key]: {$gte: value}}
    case '<=':
      return {[key]: {$lte: value}}
}
}

export function defaultInventoryV2(userTenant: string){
  return !['T2h3BLxkCYc7fSYSGpbtATqN6Kot'].includes(userTenant)
}