import { Collapse, Grid, FormControlLabel, Checkbox, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import { InventoryFilters } from "../../inventoryV2"

export interface AdditionalFiltersProps{
    expanded: boolean   
    handleFilterChange: (filterType: keyof InventoryFilters, value: any) => void
    filters: InventoryFilters
}
export const AdditionalFilters: FC<AdditionalFiltersProps> = (input) => {
    const {expanded, handleFilterChange, filters} = input
    return (
    <Collapse in={expanded} sx={{ width: '100%' }}>
        <Grid container>
        <FormControlLabel
                control={
                <Checkbox
                    checked={!!filters.extendedSupport}
                    size="small"
                    onChange={(e) => {
                    const newStatus = e.target.checked ? true : false
                    handleFilterChange('extendedSupport', newStatus)
                    }}
                    sx={{ ml: 4 }}
                />
                }
                label={
                <Tooltip title='Components that are subject to extended support fees' sx={{ pointerEvents: 'none' }} disableInteractive>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                    Extended support
                    </Typography>
                </Tooltip>
                }
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={!!filters.drafttAvailable}
                    size="small"
                    onChange={(e) => {
                    const newStatus = e.target.checked ? true : false

                    handleFilterChange('drafttAvailable', newStatus)
                    }}
                    sx={{ ml: 4 }}
                />
                }
                label={
                <Tooltip title='Components that have an available upgrade analysis (Draftt)' sx={{ pointerEvents: 'none' }} disableInteractive>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                    Draftt Available
                    </Typography>
                </Tooltip>
                }
            />
        </Grid>
    </Collapse>
    )
}