import React from 'react'
import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import Ec2ImageDetails from './ec2ImageDetails'

const Ec2Details = ({ componentData }: any) => {
  return (
    <Grid container flexDirection="row" sx={{ mt: 1 }}>
      <Grid container>
        <Typography variant="subtitle2" gutterBottom>
          EC2 Instance Details
        </Typography>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            ID:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.uniqueIdentifier}
          </Typography>
        </Grid>
      </Grid>

      {componentData.details.instanceLifeCycle && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Lifecycle:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {componentData.details.instanceLifeCycle}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Type:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.instanceType}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            State:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.instanceState}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Launch Time:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.details.instanceLaunchTime).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      <Ec2ImageDetails componentData={componentData} />
    </Grid>
  )
}

export default Ec2Details
