import _ from 'lodash'
import IntegrationSummaryCard from './components/integrationSummaryCard'

const CLOUD_PROVIDERS_INTEGRATIONS = {
  AWS: 'aws',
  GCP: 'gcp',
  AZURE: 'azure',
}

const IntegrationsSummary = (props: any) => {
  const allComponents = props.components
  const allIntegrations = props.integrations
  const allCertificates = props.certificates

  const calculateIntegrationDetails = (type: any) => {
    const integrations = _.filter(allIntegrations, { type: type })
    const integrationIds = _.map(integrations, 'id')
    const integrationComponents = _.filter(allComponents, (component) => _.includes(integrationIds, component.integrationId))
    const integrationCertificates = _.filter(allCertificates, (certificate) => _.includes(integrationIds, certificate.integrationId))

    return {
      type,
      integrationCount: integrations.length || 0,
      componentsCount: integrationComponents.length + integrationCertificates.length || 0,
    }
  }

  const integrationsSummaryData = [
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.AWS),
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.GCP),
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.AZURE),
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center' }}>
      {_.map(integrationsSummaryData, (integration, index) => {
        return <IntegrationSummaryCard integration={integration} key={index} />
      })}
    </div>
  )
}

export default IntegrationsSummary
