import { Typography, Grid } from '@mui/material'
import { useUser } from '@descope/react-sdk'
import DemoImpactAnalysis from './demoImpactAnalysis'
import { PolicyComponentAttributes } from '@draftt-io/shared-types'
import { FC } from 'react'

export interface ImpactAnalysisProps {
  componentData: PolicyComponentAttributes
}

const ImpactAnalysis: FC<ImpactAnalysisProps> = ({ componentData }) => {
  const { user } = useUser()

  let impact

  const tenants = user.userTenants ?? []
  const userTenant = tenants[0]?.tenantId
  const demoTennantIds = ['T2gmCAcui7hboYnu4U8ywwgOGvce', 'T2h3BLxkCYc7fSYSGpbtATqN6Kot']

  const isDemoAccount = userTenant && demoTennantIds.includes(userTenant)

  if (componentData.extendedSupportAnnualCost != null) {
    impact = `Using this outdated version incurs an annual extended support fee of $${componentData.extendedSupportAnnualCost}/year.`
  } else if (componentData.technology === 'Google CloudSQL MySQL') {
    impact = `Starting ${componentData.dueDate}, this component will be enrolled automatically into extended support, a paid service with hourly costs calculated per core. These fees can add up significantly. To avoid extended support charges and the eventual need for a forced upgrade, it’s essential to act proactively and prepare in advance.`
  } else if (componentData.technology === 'Amazon EKS') {
    impact = `Effective ${componentData.dueDate}, this component will automatically enter extended support, a paid service with costs calculated hourly per core. AWS charges $0.60 per cluster per hour, amounting to approximately $4,380 per year. These expenses can escalate quickly. To avoid incurring extended support fees and the eventual necessity of a forced upgrade, it’s crucial to take proactive steps and prepare ahead of time.`
  } else if (componentData.technology.includes('Amazon MQ')) {
    impact = `When a version reaches end of support, Amazon MQ upgrades all brokers on this version to the next supported version automatically. This upgrade takes place during your broker's scheduled maintenance windows, within the 45 days following the end-of-support date.`
  } else if (componentData.technology === 'Amazon Neptune') {
    impact = `When an engine version reaches its end of life, you will no longer be able to create new clusters or instances using that version, nor will autoscaling be able to create instances using that version.\nAn engine version that actually reaches its end of life will automatically be upgraded during a maintenance window.`
  }

  return (
    <Grid>
      {isDemoAccount && <DemoImpactAnalysis componentData={componentData} />}

      {!isDemoAccount && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Impact
          </Typography>

          <Typography variant="body2" gutterBottom>
            {impact}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default ImpactAnalysis
