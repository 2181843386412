import React, { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { Box, Typography, LinearProgress, IconButton } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'

interface FileDropzoneProps {
  accepts?: Accept
  type?: string
  onFileUpload: (payload: Record<string, any> | null) => void
}

export const FileDropzone: React.FC<FileDropzoneProps> = ({ accepts, type, onFileUpload }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isUploading) return
      setIsUploading(true)
      setUploaded(false)

      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const jsonData = JSON.parse(reader.result as string)
            onFileUpload(jsonData)
            setUploaded(true)
          } catch (error) {
            console.error('Error parsing JSON file:', error)
          }
          setIsUploading(false)
        }
        reader.readAsText(file)
      })
    },
    [isUploading, onFileUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accepts,
    disabled: isUploading || uploaded,
  })

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setUploaded(false)
    onFileUpload(null)
  }

  return (
    <Box
      {...getRootProps()}
      sx={{
        position: 'relative',
        minWidth: '30%',
        width: '100%',
        maxWidth: '600px',
        margin: 2,
        height: 200,
        border: '2px dashed #ccc',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDragActive ? 'grey.300' : 'background.paper',
        cursor: isUploading ? 'not-allowed' : 'pointer',
      }}
    >
      <input {...getInputProps()} />
      {uploaded && !isUploading && (
        <IconButton
          onClick={handleClear}
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            backgroundColor: 'white',
            '&:hover': { backgroundColor: 'grey.100' },
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box textAlign="center">
        {uploaded ? <CheckCircleIcon sx={{ fontSize: 40, mb: 1 }} /> : <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />}
        <Typography variant="h6">{uploaded ? 'File Uploaded' : isDragActive ? `Drop the ${type} file here...` : 'Upload Here'}</Typography>
      </Box>
      {isUploading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        />
      )}
    </Box>
  )
}
