import _ from 'lodash'
import DemoMetricsInsightsSummaryCard from './DemoMetricsInsightsSummaryCard'
import { FC } from 'react'

export interface DemoMetricsInsightsSummaryProps {
  potentialSaving: number
  outdatedPrecentage: number
}

const DemoMetricsInsightsSummary: FC<DemoMetricsInsightsSummaryProps> = ({ potentialSaving, outdatedPrecentage }) => {
  const prettifyNumber = (value: number, style: 'percent' | 'currency') => {
    return value
      .toLocaleString('en-US', {
        style,
        currency: 'USD',
      })
      .split('.')[0]
  }

  const metricsData = [
    {
      title: 'Number of active draftts',
      value: 2,
      icon: (
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H10M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V10M9 17H11.5M9 13H14M9 9H10M14 21L16.025 20.595C16.2015 20.5597 16.2898 20.542 16.3721 20.5097C16.4452 20.4811 16.5147 20.4439 16.579 20.399C16.6516 20.3484 16.7152 20.2848 16.8426 20.1574L21 16C21.5523 15.4477 21.5523 14.5523 21 14C20.4477 13.4477 19.5523 13.4477 19 14L14.8426 18.1574C14.7152 18.2848 14.6516 18.3484 14.601 18.421C14.5561 18.4853 14.5189 18.5548 14.4903 18.6279C14.458 18.7102 14.4403 18.7985 14.405 18.975L14 21Z"
            stroke="#000000"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      title: 'Draftts with no progress',
      value: 1,
      icon: (
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
            stroke="#1C274C"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <circle cx="12" cy="16" r="1" fill="#1C274C" />
          <path
            d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
            stroke="#1C274C"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      ),
    },
    {
      title: 'Average Time to Upgrade',
      value: '18.3 Days',
      icon: (
        <svg width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 6h-2V4zM12 3.2a11.747 11.747 0 0 1 7.444 2.649l.032-.032.707.707-4.972 4.972-.56-.56-.044.042A4.79 4.79 0 0 0 7.307 16H.25a11.9 11.9 0 0 1-.05-1A11.8 11.8 0 0 1 12 3.2zM6.2 15a5.761 5.761 0 0 1 .94-3.154L3.565 8.272A10.74 10.74 0 0 0 1.2 15zM4.228 7.52l3.533 3.534a5.77 5.77 0 0 1 7.393-.915l3.573-3.573a10.753 10.753 0 0 0-14.5.954zM22.8 15h-5a5.763 5.763 0 0 0-1.058-3.328l-.72.72a4.741 4.741 0 0 1 .67 3.608h7.058c.027-.33.05-.662.05-1a11.746 11.746 0 0 0-2.783-7.603l-.71.71A10.75 10.75 0 0 1 22.8 15zM0 21h24v-1H0z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      ),
    },
    {
      title: 'Potential Saving',
      value: prettifyNumber(potentialSaving, 'currency'),
      icon: (
        <svg
          fill="#000000"
          height="32px"
          width="32px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <g>
            <g>
              <path
                d="M272.72,89.236L312.9,15.057c1.712-3.161,1.634-6.99-0.205-10.077C310.854,1.892,307.526,0,303.931,0h-95.862
         c-3.594,0-6.923,1.892-8.762,4.98c-1.84,3.087-1.917,6.916-0.206,10.077l40.181,74.179c-96.423,8.492-172.3,89.681-172.3,188.271
         v183.43c0,28.156,22.906,51.063,51.063,51.063h275.912c28.156,0,51.062-22.907,51.062-51.063v-183.43
         C445.019,178.916,369.142,97.728,272.72,89.236z M225.193,20.398h61.614L256,77.273L225.193,20.398z M424.621,460.937h-0.001
         c0,16.909-13.756,30.665-30.664,30.665H118.045c-16.909,0-30.665-13.756-30.665-30.665v-183.43
         c0-92.978,75.643-168.62,168.621-168.62s168.62,75.642,168.62,168.62V460.937z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M263.941,293.807v-69.279c20.951,1.118,29.332,10.336,34.36,10.336s8.1-6.704,8.1-10.336
         c0-9.218-22.348-16.202-42.461-17.041v-8.1c0-2.794-3.352-5.308-6.425-5.308c-3.632,0-6.424,2.514-6.424,5.308v8.659
         c-24.304,1.677-50.842,12.851-50.842,48.048c0,35.757,26.26,44.137,50.842,52.798v81.291c-34.64-0.837-40.786-22.906-47.49-22.906
         c-4.19,0-8.381,5.866-8.381,10.336c0,10.056,20.672,29.891,55.87,30.17v10.055c0,2.794,2.794,5.308,6.424,5.308
         c3.073,0,6.425-2.514,6.425-5.308v-10.895c27.936-3.632,47.491-20.951,47.491-54.753
         C311.432,313.921,287.128,302.188,263.941,293.807z M251.65,289.618c-18.158-6.424-33.801-13.408-33.801-35.198
         c0-20.672,16.202-28.214,33.801-29.61V289.618z M263.104,389.626v-76.263c16.761,6.984,30.449,16.202,30.449,40.506
         C293.553,374.541,282.658,386.553,263.104,389.626z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M400.829,364.112c-5.632,0-10.199,4.567-10.199,10.199v86.693c0,5.632,4.567,10.199,10.199,10.199
         c5.632,0,10.199-4.567,10.199-10.199v-86.693C411.028,368.679,406.461,364.112,400.829,364.112z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M400.829,323.315c-5.632,0-10.199,4.567-10.199,10.199v6.12c0,5.632,4.567,10.199,10.199,10.199
         c5.632,0,10.199-4.567,10.199-10.199v-6.12C411.028,327.882,406.461,323.315,400.829,323.315z"
              />
            </g>
          </g>
        </svg>
      ),
    },
    {
      title: 'Components Requiring Action',
      value: '28',
      style: {
        gap: 0,
      },
      icon: (
        <svg width="40" height="40" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#0F0F0F" style={{ marginLeft: '10px' }}>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 1.5C6.72386 1.5 6.5 1.72386 6.5 2V3.5H5C3.61929 3.5 2.5 4.61929 2.5 6V20C2.5 21.3807 3.61929 22.5 5 22.5H12.483C12.1334 22.1054 11.823 21.6728 11.5612 21.2H5C4.72386 21.2 4.5 20.9761 4.5 20.7V11.5H19.5V11.7492C19.9958 11.8953 20.4677 12.1195 20.9 12.412V6C20.9 4.61929 19.7807 3.5 18.4 3.5H17.5V2C17.5 1.72386 17.2761 1.5 17 1.5C16.7239 1.5 16.5 1.72386 16.5 2V3.5H7.5V2C7.5 1.72386 7.27614 1.5 7 1.5ZM16.5 6V5.5H7.5V6C7.5 6.27614 7.27614 6.5 7 6.5C6.72386 6.5 6.5 6.27614 6.5 6V5.5H5C4.72386 5.5 4.5 5.72386 4.5 6V9.5H19.5V6C19.5 5.72386 19.2761 5.5 19 5.5H17.5V6C17.5 6.27614 17.2761 6.5 17 6.5C16.7239 6.5 16.5 6.27614 16.5 6Z"
          />
          <path d="M17.5 16C17.5 15.7239 17.7239 15.5 18 15.5C18.2761 15.5 18.5 15.7239 18.5 16V17.496L19.567 18.563C19.7623 18.7583 19.7623 19.0757 19.567 19.271C19.3717 19.4663 19.0543 19.4663 18.859 19.271L17.792 18.204C17.6572 18.0692 17.5928 17.897 17.6 17.725V16Z" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 12.25C14.8244 12.25 12.25 14.8244 12.25 18C12.25 21.1756 14.8244 23.75 18 23.75C21.1756 23.75 23.75 21.1756 23.75 18C23.75 14.8244 21.1756 12.25 18 12.25ZM13.75 18C13.75 15.6528 15.6528 13.75 18 13.75C20.3472 13.75 22.25 15.6528 22.25 18C22.25 20.3472 20.3472 22.25 18 22.25C15.6528 22.25 13.75 20.3472 13.75 18Z"
          />
        </svg>
      ),
    },
    {
      title: 'Outdated Software',
      value: prettifyNumber(outdatedPrecentage, 'percent'),
      icon: (
        <svg width="32px" height="32px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000" className="bi bi-clock-history">
          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
        </svg>
      ),
    },
  ]

  return (
    <div
      style={{
        display: 'grid',
        gridGap: '15px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        height: '100%',
        alignContent: 'center',
        width: '100%',
      }}
    >
      {_.map(metricsData, (metric, index) => {
        return <DemoMetricsInsightsSummaryCard metric={metric} key={index} />
      })}
    </div>
  )
}

export default DemoMetricsInsightsSummary
