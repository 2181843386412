import React, { useEffect, useState } from 'react'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Box, Tooltip, Typography, IconButton, Grid, Button } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { DATAGRID_PROPS } from '../../../../../consts'

const AFFECTED_RESOURCES_FIELDS = {
  NAME: 'name',
  NAMESPACE: 'namespace',
  SOURCE: 'source',
  LAST_APPLIED: 'lastApplied',
  HELM_RELEASE_NAME: 'helmReleaseName',
  HELM_RELEASE_NAMESPACE: 'helmReleaseNamespace',
  HELM_RELEASE_CHART: 'helmReleaseChart',
  HELM_RELEASE_VERSION: 'helmReleaseVersion',
}

const DeprecatedResources = (props: any) => {
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [tableHeight, setTableHeight] = useState(DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    [AFFECTED_RESOURCES_FIELDS.LAST_APPLIED]: false,
    [AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_NAME]: false,
    [AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_NAMESPACE]: false,
    [AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_CHART]: false,
    [AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_VERSION]: false,
  })

  const getDeprecatedResourceDetails = (resourceIndex: number) => {
    return props.deprecatedResources.at(resourceIndex)
  }

  const columns: GridColDef[] = [
    {
      field: AFFECTED_RESOURCES_FIELDS.NAME,
      headerName: dic.draftt.affected_resources.name,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.NAMESPACE,
      headerName: dic.draftt.affected_resources.namespace,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.SOURCE,
      headerName: dic.draftt.affected_resources.source.title,
      flex: 1,
      sortable: true,
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" sx={{ fontWeight: '500' }}>
              {dic.draftt.affected_resources.source.title}
            </Typography>
            <Tooltip
              title={
                <>
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <b>{dic.draftt.affected_resources.source_tooltip.last_applied.title}</b>
                    {dic.draftt.affected_resources.source_tooltip.last_applied.description}
                  </Typography>
                  <br />
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <b>{dic.draftt.affected_resources.source_tooltip.manifest.title}</b>
                    {dic.draftt.affected_resources.source_tooltip.manifest.description}
                  </Typography>
                  <br />
                  <Typography variant="body2" sx={{ fontSize: '12px' }}>
                    <b>{dic.draftt.affected_resources.source_tooltip.helm_chart.title}</b>
                    {dic.draftt.affected_resources.source_tooltip.helm_chart.description}
                  </Typography>
                </>
              }
            >
              <IconButton aria-label="explanation" size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.LAST_APPLIED,
      headerName: dic.draftt.affected_resources.last_applied,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_NAME,
      headerName: dic.draftt.affected_resources.helm_release_name,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_NAMESPACE,
      headerName: dic.draftt.affected_resources.helm_release_namespace,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_CHART,
      headerName: dic.draftt.affected_resources.helm_release_chart,
      flex: 1,
      sortable: true,
    },
    {
      field: AFFECTED_RESOURCES_FIELDS.HELM_RELEASE_VERSION,
      headerName: dic.draftt.affected_resources.helm_release_version,
      flex: 1,
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Details',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      hideable: false,
      renderCell: ({ row }) => (
        <>
          {getDeprecatedResourceDetails(row.index) && (
            <Button onClick={() => props.onRequiredChangeInfoClick(getDeprecatedResourceDetails(row.index))}>
              View Details
            </Button>
          )}
        </>
      ),
    },
  ]

  const getSource = (deprecatedResource: any) => {
    let result

    const helmReleaseChart = _.get(deprecatedResource, 'helmRelease.chart')

    if (helmReleaseChart) {
      result = dic.draftt.affected_resources.source.options.helm_chart
    } else if (deprecatedResource.lastApplied) {
      result = dic.draftt.affected_resources.source.options.last_applied
    } else {
      result = dic.draftt.affected_resources.source.options.manifest
    }

    return result
  }

  const rows = _.map(props.deprecatedResources, (deprecatedResource, index) => ({
    index,
    id: _.get(deprecatedResource, 'name') + _.get(deprecatedResource, 'namespace'),
    name: _.get(deprecatedResource, 'name'),
    namespace: _.get(deprecatedResource, 'namespace'),
    source: getSource(deprecatedResource),
    lastApplied: _.get(deprecatedResource, 'lastApplied'),
    helmReleaseName: _.get(deprecatedResource, 'helmRelease.name'),
    helmReleaseNamespace: _.get(deprecatedResource, 'helmRelease.namespace'),
    helmReleaseChart: _.get(deprecatedResource, 'helmRelease.chart'),
    helmReleaseVersion: _.get(deprecatedResource, 'helmRelease.version'),
  }))

  useEffect(() => {
    const height =
      paginationModel.pageSize > rows.length
        ? DATAGRID_PROPS.BASE_HEIGHT + rows.length * DATAGRID_PROPS.ROW_HEIGHT
        : DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT
    setTableHeight(height)
  }, [paginationModel, rows.length])

  return (
    <Grid container flexDirection="column">
      <Typography variant="body1" sx={{ fontWeight: 500, padding: '8px 0 8px 0' }}>
        {dic.draftt.affected_resources.title}
      </Typography>
      <Grid item xs={12}>
        <DataGridPro
          sx={{ height: tableHeight }}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick={true}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10]}
          slots={{
            toolbar: () => {
              return (
                <GridToolbarContainer sx={{ pt: '8px' }}>
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                </GridToolbarContainer>
              )
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DeprecatedResources
