import React, { Dispatch, SetStateAction } from 'react'
import { InventoryFilters, InventoryTableData } from '../..'
import { Box, Grid, Typography } from '@mui/material'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

const PolicyStatusGraph = ({
  data,
  filtersData,
}: {
  data: InventoryTableData[]
  filtersData: { filters: InventoryFilters; setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  return (
    <div>
      <Grid
        container
        flexDirection="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          flexWrap: 'nowrap',
        }}
      >
        <Grid item sx={{ pr: 2, minWidth: 165 }}>
          <Typography variant="caption" color="text.secondary">
            Policy compliance status:
          </Typography>
        </Grid>

        <Grid
          container
          item
          flexDirection="row"
          alignItems={'center'}
          sx={{
            flexWrap: 'nowrap',
            borderRadius: 10,
            overflow: 'hidden',
          }}
        >
          <Grid
            item
            onClick={() =>
              filtersData.setFilters((previous) => ({
                ...previous,
                status: previous.status.includes(PolicyComponentStatuses.Outdated) ? [] : [PolicyComponentStatuses.Outdated],
              }))
            }
            sx={{
              width: `${(data.filter((row) => row.status === PolicyComponentStatuses.Outdated).length / data.length) * 100 || 0}%`,
              height: 8,
              cursor: 'pointer',
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              bgcolor: 'error.main',
              transition: 'all 0.3s ease',
              '&:hover': { bgcolor: 'error.dark' },
            }}
          />
          <Grid
            item
            onClick={() =>
              filtersData.setFilters((previous) => ({
                ...previous,
                status: previous.status.includes(PolicyComponentStatuses.Impending) ? [] : [PolicyComponentStatuses.Impending],
              }))
            }
            sx={{
              width: `${(data.filter((row) => row.status === PolicyComponentStatuses.Impending).length / data.length) * 100 || 0}%`,
              height: 8,
              cursor: 'pointer',
              bgcolor: 'warning.main',
              transition: 'all 0.3s ease',
              '&:hover': { bgcolor: 'warning.dark' },
            }}
          />
          <Grid
            item
            onClick={() =>
              filtersData.setFilters((previous) => ({
                ...previous,
                status: previous.status.includes(PolicyComponentStatuses.Supported) ? [] : [PolicyComponentStatuses.Supported],
              }))
            }
            sx={{
              width: `${(data.filter((row) => row.status === PolicyComponentStatuses.Supported).length / data.length) * 100 || 0}%`,
              height: 8,
              cursor: 'pointer',
              bgcolor: 'success.main',
              transition: 'all 0.3s ease',
              '&:hover': { bgcolor: 'success.dark' },
            }}
          />
        </Grid>
        <Grid item sx={{ pl: 2, minWidth: 100 }}>
          <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500, fontSize: '13px' }}>
            {data.length} total
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default PolicyStatusGraph
