import { Grid, Typography } from '@mui/material'
import { DemoMetricsInsightsSummaryCardContainer } from './DemoMetricsInsightsSummaryCard.style'

const DemoMetricsInsightsSummaryCard = (props: any) => {
  return (
    <DemoMetricsInsightsSummaryCardContainer>
      <Grid container flexDirection="row" justifyContent="center" alignItems="center" height={'100%'}>
        <div style={{ width: '100%', alignContent: 'center', display: 'flex', justifyContent: 'center' }}>{props.metric.icon}</div>
        <div style={{ width: '100%', alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
          <Grid flexDirection="row" sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" gutterBottom={false} sx={{ display: 'inline' }}>
              {props.metric.value}
            </Typography>
            <Typography variant="body2" fontSize={'12px'} textAlign={'center'} height={'25px'}>
              {props.metric.title}
            </Typography>
          </Grid>
        </div>
      </Grid>
    </DemoMetricsInsightsSummaryCardContainer>
  )
}

export default DemoMetricsInsightsSummaryCard
