import { createObjectCsvStringifier } from "csv-writer-browser";
import { InventoryTableData } from "../..";
import { GridColDef } from "@mui/x-data-grid-pro";
import { pick } from "lodash";

export function exportDataToCsv(input: {data: InventoryTableData[], columns: GridColDef[]}){
    let {data, columns} = input
    const writer = createObjectCsvStringifier({header: columns.map((col => {return {
        id: col.field,
        title: col.headerName || col.field
    }}))})
    const rows = data.map(row => {
        return pick(row, ...columns.map(col => col.field))
    })
    const csv = writer.stringifyRecords(rows)
    const blob =new Blob([writer.getHeaderString() as string, csv], {type: 'text/csv'})
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = `drafttExport-${Date.now()}`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}