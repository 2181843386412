import { styled } from '@mui/material/styles'
import { ReactComponent as Logo } from '../../static/logo_transparent.svg'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { SIDEBAR_WIDTH } from '../../consts'
import { Chip } from '@mui/material'

export const Footer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 100px;

  .MuiIconButton-root {
    color: white;
  }
`

export const SidebarDrafttLogo = styled(Logo)`
  margin: 20px 20px 10px 5px;
  height: 60px;
`

export const SidebarContainer = styled(Grid)`
  width: ${SIDEBAR_WIDTH};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  height: 100%;
`
export const SidebarChip = styled(Chip)`
  color: white;
  background-color: #7380fa;
  height: 15px;
  width: 40px;
  font-size: 11px;
  line-height: 11px;
`
export const SidebarItem = styled(ListItem)`
  height: 48px;
  color: white;
  padding-left: 0;

  .MuiListItemButton-divider {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    margin-left: 12px;
  }

  .MuiListItemText-primary {
    font-size: 14px;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  &&:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const SidebarIcon = styled(ListItemIcon)`
  color: white;
  min-width: 40px;
`
