import { Dispatch, FC, Fragment, SetStateAction } from 'react'
import { Grid, Link, Typography } from '@mui/material'
import { CodeViewer, FileDropzone } from '../../../../../../commons'
import dic from '../../../../../../../dictionary'
import { IntegrationAttributes } from '@draftt-io/shared-types'

interface GCPServiceAccountProps {
  setJsonPayload: Dispatch<SetStateAction<IntegrationAttributes | null>>
}

export const GCPServiceAccount: FC<GCPServiceAccountProps> = ({ setJsonPayload }) => {
  const gcpWifCliInstructionsDict = dic.integrations.instructions.gcp.service_account

  const handleJsonPayloadChange = (payload: Record<string, any> | null) => {
    setJsonPayload(payload as IntegrationAttributes)
  }

  return (
    <>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.connect}</Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.header}</h3>
        <Grid flexDirection="column" margin="10px 0">
          <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_1.title_1}</Typography>
            <Link target="blank" href="https://console.cloud.google.com/iam-admin/serviceaccounts">
              {gcpWifCliInstructionsDict.step_1.step_1.link_1}
            </Link>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_1.title_2}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_1.step_1.action_2}
            </Typography>
          </Grid>

          <Grid flexDirection="column" marginLeft={'25px'}>
            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_2.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_1.step_2.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_2.title_2}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_1.step_2.action_2}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Grid container flexDirection="row" alignItems="center" gap={'5px'} margin="10px 0">
                <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_3.title}</Typography>
                <CodeViewer language="text" code="Viewer" size="small" style={{ width: '90px' }} />
                <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_3.title_2}</Typography>
                <CodeViewer language="text" code="iam.securityReviewer" size="small" style={{ width: '210px' }} />
                <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_3.title_3}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid flexDirection="column" margin="10px 0">
          <Grid flexDirection="column">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_1.title}</Typography>
          </Grid>

          <Grid flexDirection="column" margin="0 25px">
            <Grid flexDirection="column">
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_2.title}</Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_3.title}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_3.action}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_4.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_4.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_4.title_2}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_4.action_2}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_5.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_5.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_5.title_2}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_5.action_2}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_6.title_1}</Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_6.title_2}</Typography>
              <Grid container flexDirection="column" alignItems="start" gap={'5px'}>
                <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
                  <CodeViewer language="text" code="resourcemanager.folders.get" size="small" style={{ width: '280px' }} />
                </Grid>
                <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
                  <CodeViewer language="text" code="resourcemanager.folders.list" size="small" style={{ width: '280px' }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_7.title}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_7.action}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_8.title}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_8.action}
              </Typography>
            </Grid>

            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_9.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_9.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_9.title_2}</Typography>
            </Grid>
            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_10.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_10.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_10.title_2}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_2.step_10.action_2}
              </Typography>
            </Grid>
          </Grid>

          <Grid flexDirection="column" margin="10px 0">
            <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_1.title_1}</Typography>
              <Typography variant="subtitle1" fontWeight={'600'}>
                {gcpWifCliInstructionsDict.step_3.step_1.action_1}
              </Typography>
              <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_1.title_2}</Typography>
            </Grid>

            <Grid flexDirection="column" margin="0 25px">
              <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
                <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_2.title}</Typography>
                <Typography variant="subtitle1" fontWeight={'600'}>
                  {gcpWifCliInstructionsDict.step_3.step_2.action}
                </Typography>
              </Grid>

              <Grid container flexDirection="row" alignItems="center" gap={'5px'}>
                <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_3.title}</Typography>
                <Typography variant="subtitle1" fontWeight={'600'}>
                  {gcpWifCliInstructionsDict.step_3.step_3.action}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
          <Grid flexDirection="column">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_4.title}</Typography>
          </Grid>
          <FileDropzone onFileUpload={handleJsonPayloadChange} accepts={{ 'application/json': ['.json'] }} />
        </Grid>
      </Grid>
    </>
  )
}
