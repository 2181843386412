import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import _ from 'lodash'
import { DrafttClickableChip } from './componentDetails.style'
import dayjs from 'dayjs'
import DrafttAvailableBadge from './drafttAvailableBadge'
import EcrDetails from './ecrDetails'
import Ec2Details from './ec2Details'
import { AWS_TECHNOLOGIES } from '../../../../../../consts'
import ImpactAnalysis from './ImpactAnalysis'

const ComponentDetails = (props: any) => {
  let description = dayjs(props.componentData.dueDate).isAfter(dayjs())
    ? `${props.componentData.technology} is running on version ${props.componentData.currentVersion}. Update version to ${props.componentData.requiredVersion} by ${props.componentData.dueDate}.`
    : `This component has reached end-of-life on ${props.componentData.dueDate}.`

  const extractGcpProject = (fullName: string) => {
    let result

    if (_.startsWith(fullName, 'projects/')) {
      result = _.nth(fullName.split('/'), 1)
    } else if (_.includes(props.componentData.details.fullName, 'googleapis.com/')) {
      result = _.nth(fullName.split('/'), 5)
    }

    return result
  }

  return (
    <Grid>
      {props.componentData.dueDate !== 'No Due Date' && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Description
          </Typography>

          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
        </Grid>
      )}

      {props.componentData.dueDate !== 'No Due Date' && <ImpactAnalysis componentData={props.componentData} />}

      <Grid sx={{ p: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Details
        </Typography>

        <Grid container flexDirection="row">
          <Grid container item xs={6}>
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                Integration:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="body2" gutterBottom>
                {props.componentData.integrationName}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={6}>
            <Grid item xs={3}>
              <Typography variant="body2" gutterBottom>
                Last Updated:
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="body2" gutterBottom>
                {dayjs(props.componentData.updated_at).format('MMM D, YYYY, h:mm A')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {(_.startsWith(props.componentData.details.fullName, 'projects/') || _.includes(props.componentData.details.fullName, 'googleapis.com/')) && (
          <Grid container flexDirection="row">
            <Grid container item xs={6}>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  Project:
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  {extractGcpProject(props.componentData.details.fullName)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}></Grid>
          </Grid>
        )}

        {props.componentData.componentTechnology === AWS_TECHNOLOGIES.ECR && <EcrDetails componentData={props.componentData} />}
        {props.componentData.componentTechnology === AWS_TECHNOLOGIES.EC2 && <Ec2Details componentData={props.componentData} />}
      </Grid>

      {props.componentData.tags.length > 0 && (
        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            {props.componentData.componentTechnology === AWS_TECHNOLOGIES.ECR ? 'Repository Tags' : 'Tags'} ({props.componentData.tags.length})
          </Typography>

          <Grid container flexDirection="row" alignItems="center">
            {_.map(props.componentData.tags, (tag: any) => (
              <Box key={tag} mr={1}>
                <DrafttClickableChip
                  size="small"
                  color="secondary"
                  variant={props.filters.tags.includes(tag) ? 'filled' : 'outlined'}
                  label={tag}
                  onClick={() => props.onTagClick(tag)}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      )}

      {props.componentData.drafttDetails.id && <DrafttAvailableBadge componentData={props.componentData} />}
    </Grid>
  )
}

export default ComponentDetails
