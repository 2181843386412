import dic from '../../../../../../dictionary'
import { Grid, Typography } from '@mui/material'
import { get } from 'lodash'
import { IntegrationIcon, IntegrationSummaryCardContainer } from './integrationSummaryCard.style'

const IntegrationsSummaryCard = (props: any) => {
  return (
    <IntegrationSummaryCardContainer>
      <Grid container flexDirection="row" justifyContent="center" alignItems="center" height={'100%'}>
        <div style={{ width: '100%', alignContent: 'center', display: 'grid', placeItems: 'center' }}>
          <IntegrationIcon
            src={`https://draftt-public.s3.amazonaws.com/icons/${props.integration.type}.svg`}
            alt={get(props.integration, 'type')}
            style={{ marginLeft: '5px' }}
          />
        </div>
        <div style={{ width: '100%', alignContent: 'center', display: 'grid', placeItems: 'center' }}>
          <Grid flexDirection="row" sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" gutterBottom={false} sx={{ display: 'inline' }}>
              {props.integration.integrationCount}
            </Typography>
            <Typography variant="body2" sx={{ display: 'inline' }}>
              {dic.dashboard.integration_summary.card.integrations}
            </Typography>
          </Grid>

          <Grid flexDirection="row" sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle2" sx={{ display: 'inline' }}>
              {props.integration.componentsCount}
            </Typography>
            <Typography variant="body2" sx={{ display: 'inline' }}>
              {dic.dashboard.integration_summary.card.components}
            </Typography>
          </Grid>
        </div>
      </Grid>
    </IntegrationSummaryCardContainer>
  )
}

export default IntegrationsSummaryCard
