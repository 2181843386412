import React, { useState } from 'react'
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  Tooltip,
  Divider,
} from '@mui/material'
import { Clear, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { defaultDrafttQLInventoryQuery, emptyFilters, InventoryFilters, InventoryPolicy } from '../../inventoryV2'
import { PolicyComponentStatus, PolicyComponentStatuses } from '@draftt-io/shared-types'
import { TechnologiesFilter } from './technologiesFilter'
import { TagFilters } from './tagFilters'
import { AdditionalFilters } from './additionalFilters'
import { IntegrationFilters } from './IntegrationFilters'
import { GridFilterModel } from '@mui/x-data-grid-pro'
import { filter, find, isArray, isString, map } from 'lodash'

interface SidebarProps {
  filters: InventoryFilters
  setFilters: React.Dispatch<React.SetStateAction<InventoryFilters>>
  policies: InventoryPolicy[]
  selectedPolicy: InventoryPolicy
  setSelectedPolicy: React.Dispatch<React.SetStateAction<InventoryPolicy>>
  setDataGridFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>
}

export const FiltersSidebarV2: React.FC<SidebarProps> = ({
  policies,
  selectedPolicy,
  setSelectedPolicy,
  filters,
  setFilters,
  setDataGridFilterModel,
}) => {
  const [statusExpanded, setStatusExpanded] = useState(true)
  const [tagsExpanded, setTagsExpanded] = useState(false)
  const [additionalFiltersExpanded, setAdditionalFiltersExpanded] = useState(false)
  const [technologiesExpanded, setTechnologiesExpanded] = useState(false)
  const [cloudProvidersExpanded, setCloudProvidersExpanded] = useState(false)

  const handleFilterChange = (filterType: keyof InventoryFilters, value: any) => {
    setFilters((prev) => ({ ...prev, [filterType]: value }))
  }

  const handleSelectedPolicyChange = (policyId: string | number | bigint) => {
    const policy = find(policies, { id: policyId }) as InventoryPolicy | undefined
    if (policy) {
      setSelectedPolicy(policy)
    }
  }
  const toggleCloudProvidersExpanded = () => {
    setCloudProvidersExpanded(!cloudProvidersExpanded)
  }
  const toggleStatusExpand = () => {
    setStatusExpanded(!statusExpanded)
  }

  const toggleTagsExpand = () => {
    setTagsExpanded(!tagsExpanded)
  }

  const toggleAdditionalFiltersExpand = () => {
    setAdditionalFiltersExpanded(!additionalFiltersExpanded)
  }

  const toggleTechnologiesExpand = () => {
    setTechnologiesExpanded(!technologiesExpanded)
  }

  const handleTechnologiesChange = (event: React.SyntheticEvent | null, value?: string[] | string) => {
    if (isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: value,
      }))
    } else if (isString(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: filter(prev.technologies, (technology) => technology !== value),
      }))
    }
  }

  const getTooltipTitle = (status: any) => {
    let result

    if (status === PolicyComponentStatuses.Impending) {
      result = 'The component is approaching its End-of-Life (EOL) and needs attention soon to remain supported.'
    }
    if (status === PolicyComponentStatuses.Outdated) {
      result = 'The component has surpassed its End-of-Life (EOL) and is no longer supported, requiring immediate action.'
    }
    if (status === PolicyComponentStatuses.Supported) {
      result = 'The component is within its supported lifecycle and requires no immediate action.'
    }

    return result
  }

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Grid container>
        <FormControl size="small" fullWidth sx={{ mb: 2 }}>
          <InputLabel>Policy</InputLabel>
          <Select value={selectedPolicy.id} onChange={(e) => handleSelectedPolicyChange(e.target.value)} label="Policy">
            {map(policies, (policy) => (
              <MenuItem key={policy.id} value={policy.id as number}>
                <Typography variant="caption">{policy.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleStatusExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{statusExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Status
          </Typography>
        </Grid>
        <Collapse in={statusExpanded}>
          {[PolicyComponentStatuses.Supported, PolicyComponentStatuses.Impending, PolicyComponentStatuses.Outdated].map((status) => (
            <Grid item xs={12} key={status}>
              <FormControlLabel
                key={status}
                control={
                  <Checkbox
                    checked={filters.statuses.includes(status as PolicyComponentStatus)}
                    size="small"
                    onChange={(e) => {
                      const newStatus = e.target.checked ? [...filters.statuses, status] : filters.statuses.filter((s) => s !== status)

                      handleFilterChange('statuses', newStatus)
                    }}
                    sx={{ ml: 4 }}
                  />
                }
                label={
                  <Tooltip title={getTooltipTitle(status)} sx={{ pointerEvents: 'none' }} disableInteractive>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                      {status}
                    </Typography>
                  </Tooltip>
                }
              />
            </Grid>
          ))}
        </Collapse>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleCloudProvidersExpanded} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{cloudProvidersExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Cloud Providers
          </Typography>
        </Grid>
        <IntegrationFilters expanded={cloudProvidersExpanded} filters={filters} setFilters={setFilters} />
        <Grid container flexDirection="row" alignItems="center" onClick={toggleTechnologiesExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{technologiesExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Technologies ({filters.technologies.length})
          </Typography>
        </Grid>
        <TechnologiesFilter
          expanded={technologiesExpanded}
          filters={filters}
          handleTechnologiesChange={handleTechnologiesChange}
          query={defaultDrafttQLInventoryQuery(selectedPolicy.id as bigint)}
        />
        <Grid container flexDirection="row" alignItems="center" onClick={toggleTagsExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{tagsExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Tags ({filters.tags.length})
          </Typography>
        </Grid>
        <TagFilters expanded={tagsExpanded} filters={filters} setFilters={setFilters} />
        <Grid container flexDirection="row" alignItems="center" onClick={toggleAdditionalFiltersExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{additionalFiltersExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Additional Filters
          </Typography>
        </Grid>
        <AdditionalFilters expanded={additionalFiltersExpanded} filters={filters} handleFilterChange={handleFilterChange} />
      </Grid>
      <Divider sx={{ mt: 1 }} />
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        sx={{ cursor: 'pointer', fontSize: '12px', ':hover': { backgroundColor: '#e0e0e0' } }}
        onClick={() => {
          setDataGridFilterModel({ items: [] })
          setFilters(emptyFilters())
        }}
      >
        <IconButton size="small">{<Clear fontSize="small" />}</IconButton>
        Clear All Filters
      </Grid>
    </Paper>
  )
}
