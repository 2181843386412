import { CircularProgress, Divider, Grid, Popover, Typography } from "@mui/material";
import dayjs from "dayjs";
import { TimelineNodeV2 } from "./futureTimelineV2V2";
import { getAxiosInstance } from "../../../../axiosInstance";
import { InventoryQuery } from "../../inventoryV2";
import { useQuery } from "@tanstack/react-query";
import { ComponentTechnology, TECHNOLOGY_TYPE_DISPLAY_NAMES } from "@draftt-io/shared-types";

export async function getTimelineNodeData(query: InventoryQuery, dueDate: string){
    const date = new Date(dueDate).toISOString()
    const result = await getAxiosInstance().post('/views/inventory/timelineNode', {query, dueDate: date})
    return result.data as {technology: string, count: number, versions: string[]}[]
}
export const NodePopover = (
    {open, nodeData, handlePopoverClose, anchorEl, nodeColor, activeQuery}:
     {open: boolean, nodeData: TimelineNodeV2, handlePopoverClose: () => void, anchorEl:HTMLElement | null, nodeColor: string, activeQuery: InventoryQuery}) => {
       const {data: timelineNodeInfo, error, isLoading} = useQuery({queryKey: ['timelineNode', activeQuery, nodeData.dueDate], queryFn: () => getTimelineNodeData(activeQuery, nodeData.dueDate),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000*60*30
       })
       if (error){
        console.error(error)
        return null
       }else{
    return  (<Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        disableScrollLock={true}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key={`${nodeData.dueDate}`}
        onClose={handlePopoverClose}
        disableAutoFocus
      >
        <Grid container sx={{ p: 1, maxWidth: 350 }} flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="caption" color={nodeColor} sx={{ fontWeight: 500, mb: 0 }}>
            {dayjs(nodeData.dueDate).format('MMM D YYYY').toString()}
          </Typography>
          {isLoading ? <CircularProgress size={'30px'}/>
           : timelineNodeInfo!.map((node, index) => {
            return (
              <Grid item sx={{ width: '100%' }} key={`${nodeData.dueDate}-${node.technology}-${index}`}>
                <Grid
                  item
                  container
                  alignItems={'center'}
                  flexDirection={'column'}
                  sx={{ width: '100%' }}
                >
                  <Typography variant="caption" sx={{ fontSize: '12px', fontWeight: 500 }} align="center">
                    {TECHNOLOGY_TYPE_DISPLAY_NAMES[node.technology as ComponentTechnology] || node.technology}
                  </Typography>
                  <Typography
                    align="center"
                    variant="caption"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: 'text.secondary',
                      maxWidth: '250px',
                    }}
                  >
                    {node.versions.length > 1 ? 'Versions' : 'Version'}: {Array.from(node.versions).join(', ')}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }} align="center">
                    Components: {node.count}
                  </Typography>
                </Grid>
                {timelineNodeInfo!.length > 1 && index !== timelineNodeInfo!.length - 1  && (
                  <Grid item>
                    <Divider sx={{ width: '100%' }} key={`divider-${nodeData.dueDate}-${index}`} />
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      </Popover>)
    }
}