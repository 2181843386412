import { Paper, Typography } from '@mui/material'

const MetricsInsightCard = (props: any) => {
  return (
    <Paper
      sx={{ p: '11.5px', height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '15px', ...props.metric.style }}
      variant="outlined"
    >
      <div style={{ height: 'fit-content', display: 'grid', placeItems: 'center' }}>{props.metric.icon}</div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" fontSize={'18px'} textAlign={'center'} height={'25px'}>
          {props.metric.value}
        </Typography>
        <Typography variant="body2" fontSize={'12px'} textAlign={'center'} height={'25px'}>
          {props.metric.title}
        </Typography>
      </div>
    </Paper>
  )
}

export default MetricsInsightCard
