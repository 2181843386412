import { FC } from 'react'
import { Popover, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

export interface ImpactAnalysisTimelineBaselinePopoverProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  date: Date
  color: string
  status: string
}

export const ImpactAnalysisTimelineBaselinePopover: FC<ImpactAnalysisTimelineBaselinePopoverProps> = ({
  isOpen,
  anchorEl,
  onClose,
  date,
  color,
  status,
}) => {
  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{
        pointerEvents: 'none',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
      disableAutoFocus
    >
      <Grid container sx={{ p: 1, maxWidth: 350 }} flexDirection="column" alignItems="center" justifyContent="center">
        <Typography variant="caption" color={color} sx={{ fontWeight: 500, mb: 0 }}>
          {dayjs(date).format('MMM D YYYY').toString()}
        </Typography>
        <Typography variant="caption" sx={{ fontSize: '12px', fontWeight: 500, color: 'text.secondary' }} align="center">
          {status}
        </Typography>
      </Grid>
    </Popover>
  )
}
