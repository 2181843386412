import { FC } from 'react'
import styles from './ImpactAnalysisTimelineBaseline.module.css'
import { ImpactAnalysisTimelineBaselineDot } from './ImpactAnalysisBaselineDot'
import { ImpactAnalysisTimelineBaselineLine } from './ImpactAnalysisBaselineLine'

export interface ImpactAnalysisTimelineBaselineProps {
  eol: number
  eos: number
  today: number
  eosDate: Date
  eolDate: Date
}

export const ImpactAnalysisTimelineBaseline: FC<ImpactAnalysisTimelineBaselineProps> = ({ eol, eos, today, eosDate, eolDate }) => {
  return (
    <div className={styles.container}>
      <ImpactAnalysisTimelineBaselineLine positionStart={0} positionEnd={eol} color={'#2F7C31'} />
      <ImpactAnalysisTimelineBaselineLine positionStart={eol} positionEnd={eos} color={'#f77a14'} />
      <ImpactAnalysisTimelineBaselineLine positionStart={eos} positionEnd={today <= eos ? eos : today + 0.05 } color={'#f70202'} />

      <ImpactAnalysisTimelineBaselineDot position={eol} color={'#f77a14'} date={eolDate} status="Entering Extended Support Period" />
      <ImpactAnalysisTimelineBaselineDot position={eos} color={'#f70202'} date={eosDate} status="Force Upgrade" />

      <div
        className={styles.todayBar}
        style={{
          left: `calc(${(today * 100).toFixed(2)}% - 1px)`,
        }}
      />
    </div>
  )
}
