import { createObjectCsvStringifier } from "csv-writer-browser";
import { GridColDef } from "@mui/x-data-grid-pro";
import { omit } from "lodash";
import { executeDrafttQLQuery } from "../../inventoryV2";
import { DrafttQLInput, DrafttQLResponse, DrafttQLSelect } from "@draftt-io/drafttql-syntax";
import { ComponentAttributes, PolicyComponentAttributes } from "@draftt-io/shared-types";

export async function exportDataToCsv(input: {query: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes>, columns: GridColDef<PolicyComponentAttributes>[]}){
    let {columns, query} = input
    const writer = createObjectCsvStringifier({header: columns.map((col => {
        return {
        id: col.field,
        title: col.headerName || col.field
    }}))})
    let result: Partial<PolicyComponentAttributes>[] = []
    let nextPageToken
    do {
        const exportQuery: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes> = {
            ...omit(query, 'sort', 'join.query.select'),
            pageToken: nextPageToken,
            pageSize: 1000,
            select: columns.map(col => col.field) as DrafttQLSelect<PolicyComponentAttributes>
        }
        const res = await executeDrafttQLQuery(exportQuery)
        if (res.items.length){
            const items = res.items.map(item => omit<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>(item, 'joined') as Partial<PolicyComponentAttributes>)
            result = result.concat(items)
        }
        nextPageToken = res.nextPageToken
    }while (nextPageToken)
    const csv = writer.stringifyRecords(result)
    const blob =new Blob([writer.getHeaderString() as string, csv], {type: 'text/csv'})
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = `drafttExport-${Date.now()}`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}