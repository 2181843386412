import { Dispatch, SetStateAction, useState } from 'react'
import { TimelineNodeV2 } from './futureTimelineV2V2'
import { InventoryFilters, InventoryQuery } from '../../inventoryV2'
import { NodePopover } from './timelineNodePopover'

export const TimelineNodeComponentV2 = ({
  nodeData,
  activeQuery,
  setFilters,
  isSelected,
}: {
  nodeData: { node: TimelineNodeV2; date: string; passedDueDate?: boolean }
  setFilters: Dispatch<SetStateAction<InventoryFilters>>
  isSelected: boolean
  activeQuery: InventoryQuery
}) => {
  const passedDueDate = nodeData.passedDueDate ?? false
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!isSelected) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const nodeColor = passedDueDate ? '#d32f2f' : '#4E3CAE'
  const open = Boolean(anchorEl)
  const handleOnClick = () => {
    setFilters((previous) => {
      const filters = {
        ...previous,
        dueDate: !isSelected ? nodeData.date : undefined,
      }
      return filters
  })

    handlePopoverClose()
  }
  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={handleOnClick}
      style={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: nodeColor,
      }}
    >
      {open &&<NodePopover activeQuery={activeQuery} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} nodeColor={nodeColor} nodeData={nodeData.node} open={open}/>}
    </div>
  )
}
