import React from 'react'
import dic from '../../../../../dictionary'
import { Paper, Grid, Typography } from '@mui/material'
import { ArrowForward, ErrorOutline, WarningAmber } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { NOTIFICATION_TYPES } from '../../../../../consts'
import { useNavigate } from 'react-router-dom'

const DemoInsightsCard = (props: any) => {
  const navigate = useNavigate()

  return (
    <Paper variant="outlined" sx={{ m: '5px 0', height: '40px' }}>
      <Grid container alignItems="center" wrap="nowrap" sx={{ width: '100%' }}>
        <Grid item sx={{ flexShrink: 0, width: '40px', display: 'flex', justifyContent: 'center' }}>
          {props.type === NOTIFICATION_TYPES.ERROR && <ErrorOutline fontSize="small" color="error" />}
          {props.type === NOTIFICATION_TYPES.WARNING && <WarningAmber fontSize="small" color="warning" />}
        </Grid>
        <Grid
          item
          sx={{
            flexGrow: 1,
            minWidth: 0,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.message}
          </Typography>
        </Grid>

        <Grid item sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
          <Button
            endIcon={<ArrowForward />}
            sx={{
              textTransform: 'none',
              mr: '10px',
            }}
            onClick={() => navigate(props.link)}
          >
            {dic.dashboard.insights.card.view}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DemoInsightsCard
