import { Dispatch, SetStateAction } from 'react'
import { Grid, Typography } from '@mui/material'
import { aggregateCountDrafttQLQuery, InventoryFilters } from '../../inventoryV2'
import { useQuery } from '@tanstack/react-query'
import { DrafttQLInput } from '@draftt-io/drafttql-syntax'
import { ComponentAttributes, PolicyComponentAttributes, PolicyComponentStatuses } from '@draftt-io/shared-types'

export const PolicyStatusGraphV2 = ({
  totalPolicyComponentCount,
  activeQuery,
  filtersData,
}: {
  totalPolicyComponentCount: number
  activeQuery: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes>
  filtersData: { setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  const {
    data: aggregate,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['policyComponentStatusAggregate', activeQuery],
    queryFn: () => {
      return aggregateCountDrafttQLQuery(activeQuery, 'status')
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30,
  })
  if (error) {
    console.error(error)
    return null
  } else {
    return (
      <div>
        <Grid
          container
          flexDirection="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            flexWrap: 'nowrap',
          }}
        >
          <Grid item sx={{ pr: 2, minWidth: 165 }}>
            <Typography variant="caption" color="text.secondary">
              Policy compliance status:
            </Typography>
          </Grid>
          {isLoading ? (
            <div style={{ opacity: '30%', height: 8, width: '100%', borderRadius: 10, backgroundColor: 'gray' }}></div>
          ) : (
            aggregate && (
              <Grid
                container
                item
                flexDirection="row"
                alignItems={'center'}
                sx={{
                  flexWrap: 'nowrap',
                  borderRadius: 10,
                  overflow: 'hidden',
                }}
              >
                <Grid
                  item
                  onClick={() =>
                    filtersData.setFilters((previous) => ({
                      ...previous,
                      statuses: previous.statuses.includes(PolicyComponentStatuses.Outdated) ? [] : [PolicyComponentStatuses.Outdated],
                    }))
                  }
                  sx={{
                    width: `${(aggregate?.aggregateCount[PolicyComponentStatuses.Outdated] || 0 / totalPolicyComponentCount) * 100 || 0}%`,
                    height: 8,
                    cursor: 'pointer',
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    bgcolor: 'error.main',
                    transition: 'all 0.3s ease',
                    '&:hover': { bgcolor: 'error.dark' },
                  }}
                />
                <Grid
                  item
                  onClick={() =>
                    filtersData.setFilters((previous) => ({
                      ...previous,
                      statuses: previous.statuses.includes(PolicyComponentStatuses.Impending) ? [] : [PolicyComponentStatuses.Impending],
                    }))
                  }
                  sx={{
                    width: `${(aggregate?.aggregateCount[PolicyComponentStatuses.Impending] || 0 / totalPolicyComponentCount) * 100 || 0}%`,
                    height: 8,
                    cursor: 'pointer',
                    bgcolor: 'warning.main',
                    transition: 'all 0.3s ease',
                    '&:hover': { bgcolor: 'warning.dark' },
                  }}
                />
                <Grid
                  item
                  onClick={() =>
                    filtersData.setFilters((previous) => ({
                      ...previous,
                      statuses: previous.statuses.includes(PolicyComponentStatuses.Supported) ? [] : [PolicyComponentStatuses.Supported],
                    }))
                  }
                  sx={{
                    width: `${(aggregate?.aggregateCount[PolicyComponentStatuses.Supported] || 0 / totalPolicyComponentCount) * 100 || 0}%`,
                    height: 8,
                    cursor: 'pointer',
                    bgcolor: 'success.main',
                    transition: 'all 0.3s ease',
                    '&:hover': { bgcolor: 'success.dark' },
                  }}
                />
              </Grid>
            )
          )}
          <Grid item sx={{ pl: 2, minWidth: 100 }}>
            <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500, fontSize: '13px' }}>
              {totalPolicyComponentCount} total
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }
}
