import {
  ComponentTechnology,
  PolicyComponentAttributes,
  PolicyComponentStatuses,
  PolicyComponentTimelineBreakdown,
  PolicyComponentTimelineBreakdownItem,
  TECHNOLOGY_TYPE_DISPLAY_NAMES,
} from '@draftt-io/shared-types'
import { Typography, Grid, Divider } from '@mui/material'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { ImpactAnalysisTimeline } from './ImpactAnalysisTimeline'

export interface ImpactAnalysisV2Props {
  policyComponent: PolicyComponentAttributes
}

export const ImpactAnalysisV2: FC<ImpactAnalysisV2Props> = ({ policyComponent }) => {
  const { impendingDate, outdatedDate } = policyComponent
  const inImpending = !!impendingDate && dayjs().isAfter(dayjs(impendingDate))
  const inOutdated = !!(outdatedDate ?? impendingDate) && dayjs().isAfter(dayjs(outdatedDate ?? impendingDate))
  const timelineBreakdown = policyComponent.details.timelineBreakdown
  const hasImpact = !!timelineBreakdown && !isEmpty(timelineBreakdown)
  const isTimelineTechnology = hasImpact && (timelineBreakdown.impending || timelineBreakdown.outdated)
  const hasTimeline = impendingDate || outdatedDate

  const todayImpact = hasImpact ? generateTodayImpact(timelineBreakdown, inImpending, inOutdated) : null
  const fallbackImpact = calculateOldImpact(policyComponent)

  return (
    <Grid sx={{ p: !fallbackImpact && !hasImpact ? 0 : 2 }}>
      {isTimelineTechnology && (
        <Grid>
          <Grid container alignItems={'center'} alignContent={'center'}>
            {hasTimeline && (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  Impact Timeline
                </Typography>
                <Grid container flexDirection="column" alignItems={'flex-start'} width={'100%'}>
                  <ImpactAnalysisTimeline eolDate={dayjs(impendingDate).toDate()} eosDate={outdatedDate ? dayjs(outdatedDate).toDate() : undefined} />
                </Grid>
              </>
            )}

            <Divider sx={{ width: '100%', margin: '20px 0' }} />

            <Grid container flexDirection="column" alignItems={'flex-start'} width={'100%'} gap={'5px'}>
              {todayImpact && (
                <Grid>
                  <Typography variant="subtitle2" component="span" sx={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                    {todayImpact.title}:
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ display: 'inline' }}>
                    {todayImpact.description}
                  </Typography>
                </Grid>
              )}
              {timelineBreakdown.impending && !inImpending && (
                <Grid>
                  <Typography variant="subtitle2" component="span" sx={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                    {timelineBreakdown.impending.title}:
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ display: 'inline' }}>
                    {timelineBreakdown.impending.description}
                  </Typography>
                </Grid>
              )}
              {timelineBreakdown.outdated && !inOutdated && (
                <Grid>
                  <Typography variant="subtitle2" component="span" sx={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
                    {timelineBreakdown.outdated.title}:
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ display: 'inline' }}>
                    {timelineBreakdown.outdated.description}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isTimelineTechnology && fallbackImpact && (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Impact
          </Typography>

          <Typography variant="body2" gutterBottom>
            {fallbackImpact}
          </Typography>
        </>
      )}
    </Grid>
  )
}

function generateTodayImpact(
  timelineBreakdown: PolicyComponentTimelineBreakdown,
  inImpending: boolean,
  inOutdated: boolean,
): PolicyComponentTimelineBreakdownItem {
  if (inOutdated) {
    return {
      title: 'Today',
      description: timelineBreakdown.outdated?.description,
    }
  }

  if (inImpending) {
    return {
      title: 'Today',
      description: timelineBreakdown.impending?.description,
    }
  }

  return {
    title: 'Today',
    description: 'No immediate impact',
  }
}

function calculateOldImpact(policyComponent: PolicyComponentAttributes) {
  if (policyComponent.extendedSupportAnnualCost != null && policyComponent.status !== PolicyComponentStatuses.Supported) {
    return `Using this outdated version incurs an annual extended support fee of $${policyComponent.extendedSupportAnnualCost}/year.`
  }

  const technology = TECHNOLOGY_TYPE_DISPLAY_NAMES[policyComponent.technology as ComponentTechnology] ?? policyComponent.technology

  if (technology === 'Google CloudSQL MySQL') {
    return `Starting ${policyComponent.dueDate}, this component will be enrolled automatically into extended support, a paid service with hourly costs calculated per core. These fees can add up significantly. To avoid extended support charges and the eventual need for a forced upgrade, it’s essential to act proactively and prepare in advance.`
  }

  if (technology === 'Amazon EKS') {
    return `Effective ${policyComponent.dueDate}, this component will automatically enter extended support, a paid service with costs calculated hourly per core. AWS charges $0.60 per cluster per hour, amounting to approximately $4,380 per year. These expenses can escalate quickly. To avoid incurring extended support fees and the eventual necessity of a forced upgrade, it’s crucial to take proactive steps and prepare ahead of time.`
  }

  if (technology.includes('Amazon MQ')) {
    return `When a version reaches end of support, Amazon MQ upgrades all brokers on this version to the next supported version automatically. This upgrade takes place during your broker's scheduled maintenance windows, within the 45 days following the end-of-support date.`
  }

  if (technology === 'Amazon Neptune') {
    return `When an engine version reaches its end of life, you will no longer be able to create new clusters or instances using that version, nor will autoscaling be able to create instances using that version.\nAn engine version that actually reaches its end of life will automatically be upgraded during a maintenance window.`
  }
}
