import styled from '@emotion/styled'

export const ImpactAnalysisTimelineBaselineTodayBar = styled.div`
  position: absolute;
  width: 1px;
  background-color: #4E3CAE;
  top: -5px;
  height: 16px;
`

export const ImpactAnalysisTimelineBaselineContainer = styled.div`
  position: relative;
  height: 6px;
  border-radius: 3px;
`

export const ImpactAnalysisTimelineLineBaselineDot = styled.div`
  position: absolute;
  top: -5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid #fff;
  cursor: pointer;
`

export const ImpactAnalysisTimelineLineBaselineLine = styled.div`
  position: absolute;
  top: 0px;
  height: 3px;
  border-radius: 3px;
`
