import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, Tab, Tabs, TextField } from '@mui/material'
import { INTEGRATIONS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import { ArrowBack, Person } from '@mui/icons-material'
import { isEmpty, replace } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import { getAxiosInstance } from '../../../../../axiosInstance'
import { GCP_INTEGRATION_METHODS, integrationTypes, type IntegrationAttributes } from '@draftt-io/shared-types'
import { useNotification } from '../../../../commons/notification/notificationProvider'
import dic from '../../../../../dictionary'
import { GCPWorkloadIdentityFederationCli, GCPWorkloadIdentityFederationUI, GCPServiceAccount } from './components'
import AltRouteIcon from '@mui/icons-material/AltRoute'
export const GcpIntegrationInstructions = () => {
  const gcpIntegrationInstructions = dic.integrations.instructions.gcp
  const navigate = useNavigate()
  const { showNotification } = useNotification()

  const [tabValue, setTabValue] = useState(0)
  const [integrationName, setIntegrationName] = useState('')
  const [integrationNameError, setIntegrationNameError] = useState<string | null>(null)
  const [jsonPayload, setJsonPayload] = useState<IntegrationAttributes | null>(null)
  const [createLoading, setCreateLoading] = useState(false)

  const selectedMethod = useMemo(() => {
    switch (tabValue) {
      case 0:
        return gcpIntegrationInstructions.wif.cli.title
      case 1:
        return gcpIntegrationInstructions.wif.ui.title
      case 2:
        return gcpIntegrationInstructions.service_account.title
      default:
        return gcpIntegrationInstructions.service_account.title
    }
  }, [tabValue])

  const connectionMethod = useMemo(() => {
    switch (tabValue) {
      case 0:
        return GCP_INTEGRATION_METHODS.WIF
      case 1:
        return GCP_INTEGRATION_METHODS.WIF
      case 2:
        return GCP_INTEGRATION_METHODS.SERVICE_ACCOUNT
      default:
        return GCP_INTEGRATION_METHODS.SERVICE_ACCOUNT
    }
  }, [tabValue])

  const submitButtonDisabled = useMemo(() => {
    return !jsonPayload || isEmpty(jsonPayload) || !!integrationNameError || !integrationName
  }, [jsonPayload, integrationNameError, integrationName])

  const validateIntegrationName = () => {
    setIntegrationNameError(!integrationName?.length ? 'Integration Name is required' : null)
  }

  const handleIntegrationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntegrationName(event.target.value)
    validateIntegrationName()
  }

  const onBackIconClick = () => {
    navigate(replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.gcp.type))
  }

  const createIntegration = async (payload: { name: string; details: IntegrationAttributes; type: string; connectionMethod: string }) => {
    setCreateLoading(true)
    const { name, details, type, connectionMethod } = payload

    const response = await getAxiosInstance().post<IntegrationAttributes>('/integration', {
      name,
      type,
      connectionMethod,
      details,
    })

    return response.data
  }

  const createIntegrationMutation = useMutation({
    mutationFn: createIntegration,
    onSuccess: () => {
      navigate(replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.gcp.type))
    },
    onError: (error) => {
      console.error(`Failed to create GCP ${selectedMethod}`, error)
      setCreateLoading(false)
      showNotification(`Failed to create GCP Workload Identity Federation Integration`, NOTIFICATION_TYPES.ERROR)
    },
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!jsonPayload) {
      setCreateLoading(false)
      return
    }

    createIntegrationMutation.mutate({
      name: integrationName,
      details: jsonPayload,
      type: integrationTypes.gcp,
      connectionMethod,
    })
  }

  return (
    <Grid style={{ width: '99%' }}>
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>{gcpIntegrationInstructions.title}</h1>
      </Box>

      <span> {gcpIntegrationInstructions.select_method} </span>

      <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} aria-label="Tabs" sx={{ pb: '20px', pt: '20px' }}>
        <Tab icon={<AltRouteIcon />} iconPosition="start" label={gcpIntegrationInstructions.wif.cli.title} />
        <Tab icon={<AltRouteIcon />} iconPosition="start" label={gcpIntegrationInstructions.wif.ui.title} />
        <Tab icon={<Person />} iconPosition="start" label={gcpIntegrationInstructions.service_account.title} />
      </Tabs>

      <form onSubmit={handleSubmit}>
        <Card variant="outlined" sx={{ borderRadius: '15px' }}>
          <CardContent>
            <Grid container flexDirection="column" alignItems="start">
              <span> {gcpIntegrationInstructions.fill_integration_name} </span>
              <TextField
                label={gcpIntegrationInstructions.integration_name}
                variant="standard"
                value={integrationName}
                onChange={handleIntegrationNameChange}
                error={!!integrationNameError}
                helperText={integrationNameError}
                required
                onBlur={validateIntegrationName}
                sx={{ mb: '10px', mt: '10px', minWidth: '300px', minHeight: '72px' }}
              />
            </Grid>

            {tabValue === 0 && <GCPWorkloadIdentityFederationCli setJsonPayload={setJsonPayload} />}
            {tabValue === 1 && <GCPWorkloadIdentityFederationUI setJsonPayload={setJsonPayload} />}
            {tabValue === 2 && <GCPServiceAccount setJsonPayload={setJsonPayload} />}

            <Grid container flexDirection="column" alignItems="flex-end" sx={{ marginBottom: '16px' }}>
              {!createLoading && (
                <Button type="submit" variant="contained" color="primary" disabled={submitButtonDisabled}>
                  {gcpIntegrationInstructions.create}
                </Button>
              )}

              {createLoading && <CircularProgress color="primary" />}
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Grid>
  )
}
