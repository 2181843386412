import { useMemo, useState, useEffect } from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Button, Divider, Grid } from '@mui/material'
import { FileDownload, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { GridColDef, GridColumnVisibilityModel, GridPagination, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import ComponentTags from '../../../commons/componentTags'
import { TAGS_DELIMITER, DATAGRID_PROPS, muiDataGridProDetailsColumn } from '../../../../consts'
import { exportDataToCsv } from '../../../inventory/components/componentsTable/exportData'
import { useUser } from '@descope/react-sdk'
import { InventoryTableData } from '../../../inventory'

const CompliantTable = (props: any) => {
  const compliantPolicyComponents = props.compliantPolicyComponents
  const sortedCompliantPolicyComponents = _.filter(compliantPolicyComponents, { isCompliant: true })

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [tableHeight, setTableHeight] = useState(DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT)

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'technology', headerName: dic.policy_tabs.compliant_table.technology, flex: 2 },
      { field: 'name', headerName: dic.policy_tabs.compliant_table.name, flex: 4 },
      { field: 'currentVersion', headerName: dic.policy_tabs.compliant_table.current_version, flex: 2 },
      { field: 'integrationName', headerName: dic.policy_tabs.compliant_table.integration_name, flex: 2 },
      ...props.tagsFilters,
    ]
  }, [props.tagsFilters])

  const rows = useMemo(() => {
    return _.map(compliantPolicyComponents, (component) => ({
      id: _.get(component, 'id'),
      technology: _.get(component, 'displayName'),
      name: _.get(component, 'name'),
      currentVersion: _.get(component, 'currentVersion'),
      tags: _.get(component, 'tags'),
      integrationName: _.get(component, 'integrationName'),
      updatedAt: _.get(component, 'updated_at'),
      ..._.reduce(
        _.get(component, 'tags', []),
        (acc: any, tag) => {
          acc[`${TAGS_DELIMITER}${_.get(tag, 'key')}`] = _.get(tag, 'value')
          return acc
        },
        {},
      ),
    }))
  }, [compliantPolicyComponents])

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const visibilityModel = _.reduce(
      props.tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )

    visibilityModel['integrationName'] = false
    return visibilityModel
  }, [props.tagsFilters])

  const getDetailPanelContent = (params: any) => {
    return (
      <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <strong>{dic.policy_tabs.compliant_table.collapsable_data.integration_name}: </strong>
          <span>{_.get(params, 'row.integrationName')}</span>
        </Grid>
        <Grid item>
          <strong>{dic.policy_tabs.compliant_table.collapsable_data.updated_at}:</strong>
          <span> {dayjs(_.get(params, 'row.updated_at')).format('MMM D, YYYY')}</span>
        </Grid>
        {_.get(params, 'row.tags', []).length > 0 && <ComponentTags tags={_.get(params, 'row.tags')} />}
      </Grid>
    )
  }

  useEffect(() => {
    const height =
      paginationModel.pageSize > sortedCompliantPolicyComponents.length
        ? DATAGRID_PROPS.BASE_HEIGHT + sortedCompliantPolicyComponents.length * DATAGRID_PROPS.ROW_HEIGHT
        : DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT
    setTableHeight(height)
  }, [paginationModel, sortedCompliantPolicyComponents.length])
  const apiRef = useGridApiRef()

  const { user } = useUser()
  const userTenant = _.get(user, 'userTenants[0].tenantId')
  const getFilteredRows = () => {
      if (apiRef.current?.state?.filter?.filteredRowsLookup) {
        const filteredRowIds = new Set(
          Object.entries(apiRef.current.state.filter.filteredRowsLookup)
            .filter(([_, value]) => value === true)
            .map(([key, _]) => key)
        );
        return rows.filter((item) => filteredRowIds.has(item.id)) as InventoryTableData[]
    }else{
      return rows
    }}
  return (
    <Grid item xs={12} sx={{ height: tableHeight }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        disableRowSelectionOnClick={true}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50]}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          footer: () => (
            <div>
              <Divider/>
              <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item
                sx={{ cursor: 'pointer', fontSize: '12px', ":hover": { backgroundColor: '#e0e0e0' }, ml: 2}}
                onClick={() => { 
                  userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' && exportDataToCsv({data: getFilteredRows(), columns: apiRef.current.getVisibleColumns().filter((column) => column.field !== muiDataGridProDetailsColumn)})
                }}
              >
                {userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' && <Button size="small" variant='outlined'>{
                  <FileDownload fontSize='small'/>}  Export
                  </Button>}
              </Grid>
              <Grid item>
                <GridPagination/>
              </Grid>
              </Grid>
            </div>
          ),
          detailPanelCollapseIcon: KeyboardArrowUp,
          toolbar: () => (
            <GridToolbarContainer sx={{ pt: '8px' }}>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </GridToolbarContainer>
          ),
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
        }}
      />
    </Grid>
  )
}

export default CompliantTable
