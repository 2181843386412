import { useEffect, useMemo, useState } from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Box, Button, Divider, Grid, LinearProgress, Tooltip, Typography } from '@mui/material'
import { AutoAwesome, FileDownload, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { GridColDef, GridColumnVisibilityModel, GridPagination, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'
import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone'
import dayjs from 'dayjs'
import { DATAGRID_PROPS, DEFAULT_POLICIES_IDS, muiDataGridProDetailsColumn, TAGS_DELIMITER } from '../../../../consts'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import ComponentTags from '../../../commons/componentTags'
import { useUser } from '@descope/react-sdk'
import { exportDataToCsv } from '../../../inventory/components/componentsTable/exportData'
import { InventoryTableData } from '../../../inventory'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

const DEFAULT_SUGGESTIONS = 15

const SuggestedTable = (props: any) => {
  const policyId = _.get(_.head(props.policyComponents), 'policyId')
  let suggestedComponents: any
  let sortedPolicyComponents: any
  let policyComponents = _.filter(
    props.policyComponents,
    (policyComponent) =>
      policyComponent.desiredVersion != null && policyComponent.recommendedVersion != null && parseInt(policyComponent.urgency) >= 25,
  )

  if (policyComponents.length === 0) {
    policyComponents = _.filter(props.policyComponents, (policyComponent) => policyComponent.desiredVersion != null)
    sortedPolicyComponents = _.orderBy(policyComponents, (policyComponent) => parseInt(policyComponent.urgency), 'desc')
    suggestedComponents = _.take(sortedPolicyComponents, DEFAULT_SUGGESTIONS)
  } else {
    suggestedComponents = _.orderBy(policyComponents, (policyComponent) => parseInt(policyComponent.urgency), 'desc')
  }

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [tableHeight, setTableHeight] = useState(DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT)

  const renderComponentNameCell = (params: any) => {
    let result

    const name = _.get(params, 'row.name')
    const extendedSupportYearlyCost = _.get(params, 'row.extendedSupportYearlyCost')

    if (extendedSupportYearlyCost != null) {
      result = (
        <Grid container flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs="auto" alignItems="center" sx={{ height: '100%', display: 'flex' }}>
            <Tooltip title={dic.policy_tabs.suggested_table.extended_support_tooltip}>
              <PaidTwoToneIcon fontSize="small" color="warning" sx={{ m: '0 3px 1px 0' }} />
            </Tooltip>
          </Grid>

          <Grid item xs sx={{ height: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {name}
          </Grid>
        </Grid>
      )
    } else {
      result = (
        <Grid container flexDirection="column" alignItems="flex-start" justifyContent="center" ml={1} sx={{ height: '100%' }}>
          <Typography variant="body2">{name}</Typography>
        </Grid>
      )
    }

    return result
  }

  let columns: GridColDef[] = [
    { field: 'technology', headerName: dic.policy_tabs.suggested_table.technology, flex: 2 },
    {
      field: 'name',
      headerName: dic.policy_tabs.suggested_table.name,
      flex: 4,
      // Use renderCell to display the name and conditionally render the Chip
      renderCell: renderComponentNameCell,
    },
    {
      field: 'urgency',
      headerName: dic.policy_tabs.suggested_table.urgency,
      flex: 2,
      renderCell: (params: any) => (
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs={8}>
            <Tooltip title={parseInt(params.value)}>
              <LinearProgress variant="determinate" value={parseInt(params.value)} />
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'priority',
      renderHeader: () => (
        <Box display="flex" alignItems="center" gap={1}>
          <AutoAwesome fontSize="small" />
          <Typography variant="body2" sx={{ fontWeight: '500' }}>
            {dic.policy_tabs.suggested_table.priority}
          </Typography>
        </Box>
      ),
      headerName: dic.policy_tabs.suggested_table.priority,
      flex: 2,
      renderCell: (params: any) => (
        <Grid container alignItems="center" justifyContent="center">
          <Gauge
            width={50}
            height={52}
            value={parseInt(params.value)}
            startAngle={-130}
            endAngle={130}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 13,
                transform: 'translate(0px, 0px)',
              },
              padding: '5px',
            }}
          />
        </Grid>
      ),
    },
    { field: 'currentVersion', headerName: dic.policy_tabs.suggested_table.current_version, flex: 2 },
    { field: 'recommendedVersion', headerName: dic.policy_tabs.suggested_table.recommended_version, flex: 2 },
    {
      field: 'dueDate',
      headerName: dic.policy_tabs.suggested_table.due_date,
      flex: 2,
      renderCell: (params: any) => (params.value ? dayjs(params.value).format('MMM D, YYYY') : dic.policy_tabs.suggested_table.no_due_date),
    },
    { field: 'integrationName', headerName: dic.policy_tabs.suggested_table.integration_name, flex: 2 },
    {
      field: 'isInExtendedSupport',
      headerName: 'Extended Support',
      flex: 2,
      renderCell: (params: any) => {
        let result = undefined

        const isInExtendedSupport = _.get(params, 'row.isInExtendedSupport')
        if (isInExtendedSupport) {
          result = (
            <Grid container flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
              <DoneOutlineTwoToneIcon fontSize="small" color="warning" sx={{ m: '0 3px 1px 0' }} />
            </Grid>
          )
        }

        return result
      },
    },

    ...props.tagsFilters,
  ]

  if (policyId === DEFAULT_POLICIES_IDS.EOL) {
    columns = _.concat(
      columns.slice(0, 5),
      [
        {
          field: 'desiredVersion',
          headerName: dic.policy_tabs.suggested_table.desired_version,
          flex: 2,
        },
      ],
      columns.slice(5),
    )
  }

  const isInExtendedSupport = (component: any) => {
    let result = undefined

    const extendedSupportAnnualCost = _.get(component, 'details.cost.extendedSupport.oneYearCost')
    const status = _.get(component, 'status')

    if (extendedSupportAnnualCost != null && status !== PolicyComponentStatuses.Supported) {
      result = true
    }

    return result
  }

  const rows = _.map(suggestedComponents, (component) => {
    return {
      id: _.get(component, 'id'),
      technology: _.get(component, 'displayName'),
      name: _.get(component, 'name'),
      urgency: _.get(component, 'urgency'),
      priority: _.get(component, 'urgency'),
      currentVersion: _.get(component, 'currentVersion'),
      desiredVersion: _.get(component, 'desiredVersion'),
      recommendedVersion: _.get(component, 'recommendedVersion'),
      dueDate: _.get(component, 'dueDate'),
      tags: _.get(component, 'tags'),
      updatedAt: _.get(component, 'updated_at'),
      isInExtendedSupport: isInExtendedSupport(component) || undefined,
      integrationName: _.get(component, 'integrationName'),
      extendedSupportYearlyCost: _.get(component, 'details.cost.extendedSupport.oneYearCost'),
      ..._.reduce(
        _.get(component, 'tags', []),
        (acc: any, tag) => {
          acc[`${TAGS_DELIMITER}${_.get(tag, 'key')}`] = _.get(tag, 'value')
          return acc
        },
        {},
      ),
    }
  })

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    const visibilityModel = _.reduce(
      props.tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )

    visibilityModel['isInExtendedSupport'] = false
    visibilityModel['integrationName'] = false
    return visibilityModel
  }, [props.tagsFilters])

  const getDetailPanelContent = (params: any) => {
    return (
      <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        {_.get(params, 'row.extendedSupportYearlyCost') != null && (
          <Grid item>
            <strong>{dic.policy_tabs.suggested_table.collapsable_data.draftt_insights}</strong>
            <span>
              {dic.policy_tabs.suggested_table.collapsable_data.extended_support_insights_message}
              <strong>${_.get(params, 'row.extendedSupportYearlyCost')}/year</strong>.
            </span>
          </Grid>
        )}
        <Grid item>
          <strong>{dic.policy_tabs.suggested_table.collapsable_data.integration_name}: </strong>
          <span>{_.get(params, 'row.integrationName')}</span>
        </Grid>
        <Grid item>
          <strong>{dic.policy_tabs.suggested_table.collapsable_data.updated_at}:</strong>
          <span> {dayjs(_.get(params, 'row.updated_at')).format('MMM D, YYYY')}</span>
        </Grid>
        {_.get(params, 'row.tags', []).length > 0 && <ComponentTags tags={_.get(params, 'row.tags')} />}
      </Grid>
    )
  }
  useEffect(() => {
    const height =
      paginationModel.pageSize > suggestedComponents.length
        ? DATAGRID_PROPS.BASE_HEIGHT + suggestedComponents.length * DATAGRID_PROPS.ROW_HEIGHT
        : DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT
    setTableHeight(height)
  }, [paginationModel])
  const { user } = useUser()
  const userTenant = _.get(user, 'userTenants[0].tenantId')
  const apiRef = useGridApiRef()

  const getFilteredRows = () => {
    if (apiRef.current?.state?.filter?.filteredRowsLookup) {
      const filteredRowIds = new Set(
        Object.entries(apiRef.current.state.filter.filteredRowsLookup)
          .filter(([_, value]) => value === true)
          .map(([key, _]) => key)
      );
      return rows.filter((item) => filteredRowIds.has(item.id)) as InventoryTableData[]
  }else{
    return rows
  }}

  return (
    <Grid item xs={12} sx={{ height: tableHeight }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        disableRowSelectionOnClick={true}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          footer: () => (
            <div>
              <Divider/>
              <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item
                sx={{ cursor: 'pointer', fontSize: '12px', ":hover": { backgroundColor: '#e0e0e0' }, ml: 2}}
                onClick={() => {
                  userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' && exportDataToCsv({data: getFilteredRows(), columns: apiRef.current.getVisibleColumns().filter((column) => column.field !== muiDataGridProDetailsColumn)})
                }}
              >
                {userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' && <Button size="small" variant='outlined'>{
                  <FileDownload fontSize='small'/>}  Export
                  </Button>}
              </Grid>
              <Grid item>
                <GridPagination/>
              </Grid>
              </Grid>
            </div>
          ),
          detailPanelCollapseIcon: KeyboardArrowUp,
          toolbar: () => {
            return (
              <GridToolbarContainer sx={{ pt: '8px' }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
              </GridToolbarContainer>
            )
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'priority', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
        }}
      />
    </Grid>
  )
}

export default SuggestedTable
