import { DrafttQLInput } from "@draftt-io/drafttql-syntax"
import { TagAttributes } from "@draftt-io/shared-types"
import { useInfiniteQuery } from "@tanstack/react-query"
import { executeDrafttQLQuery, InventoryFilters } from "../../inventoryV2"
import { FC, useState } from "react"
import { CircularProgress, Collapse, Grid, Autocomplete, TextField, Tooltip, AutocompleteChangeReason } from "@mui/material"
import React from "react"
import { FilteredChip } from "./filtersSidebar.style"
import { InfiniteListbox } from "../../../shared/infiniteScrollListbox"
import { debounce } from "lodash"

 
interface TagFilterProps{
    expanded: boolean
    filters: InventoryFilters
    setFilters:React.Dispatch<React.SetStateAction<InventoryFilters>>
}



export const TagFilters: FC<TagFilterProps> = (input) => {
    const {expanded, filters, setFilters} = input
    const [searchPrefix, setSearchPrefix] = useState<string | undefined>(undefined)
    const fetchTags = async (input: {pageParam?: string}) => {
      const query: DrafttQLInput<TagAttributes> = {
          resource: 'tags',
          sort: { occurances: 'desc' },
          filter: {},
          pageSize: 1000,
        }
      if (searchPrefix){
        if (searchPrefix?.includes(':')){
          const [key, value] = searchPrefix.split(':')
          query.filter!.key = {$ilike: `${key.trim()}*`}
          query.filter!.value = {$ilike: `*${value.trim()}*`}
        }else{
          query.filter!.$or = [
            {key: {$ilike: `*${searchPrefix}*`}},
            {value: {$ilike: `*${searchPrefix}*`}},
          ]
        }
      }
        return await executeDrafttQLQuery({...query, pageToken: input.pageParam})
  }
    const handleTagsChange = (_event: React.SyntheticEvent | null, value: (string | Partial<TagAttributes>)[], reason: AutocompleteChangeReason) => {  
        if (reason && reason === 'clear') {
          setFilters((prev) => ({
            ...prev,
            tags: []
          }))
        }else if (reason && reason === 'removeOption'){
          let tagValue = value[0] as TagAttributes
          setFilters((prev) => ({
            ...prev,
            tags: prev.tags.filter((tag) => tag.key !== tagValue.key && tag.value !== tagValue.value)
          }))
        }
        else if (value) {
          setFilters((prev) => ({
            ...prev,
            tags: value as TagAttributes[],
          }))
        }
      }
      
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
      } = useInfiniteQuery({
        queryKey: ['tags', searchPrefix],
        initialPageParam: undefined,
        queryFn: fetchTags,
        getNextPageParam: (lastPage) => lastPage.nextPageToken,
        enabled: expanded,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000*60*30
      })

      const pages = data?.pages || []
      // TODO: Improve performance here
      const tags = pages.reduce((acc, page) => acc.concat(page.items), [] as Partial<TagAttributes>[])
      
    
    return (
        <Collapse in={expanded} sx={{ width: '100%' }}>
          <Grid container>
            <Autocomplete
              multiple
              freeSolo
              options={
                tags.filter(tag => 
                  !filters.tags.find(
                    filtered => filtered.key === tag.key && filtered.value === tag.value
                  ))}
              getOptionLabel={(option) => typeof option === 'string' ? option : `${option.key}:${option.value}`}
              filterOptions={(options) => options}
              ListboxComponent={InfiniteListbox}
              value={filters.tags}
              onChange={handleTagsChange}
              filterSelectedOptions
              noOptionsText="No options"
              onInputChange={debounce((_event, value) => {
                setSearchPrefix(value.trim())
              }, 300)}
              sx={{ ml: 4, width: '100%' }}
              renderTags={() => null}
              ListboxProps={{
                isFetchingNextPage,
                hasNextPage,
                fetchNextPage,
                fetchScrollItemPosition: 400,
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    ":hover": {
                      backgroundColor: '#e0e0e0'
                    },
                  },
                }
              } as any}
              renderOption={(props, option) => {
                const { key, ...rest } = props
                return <Tooltip title={`${option.key}:${option.value}`} placement="right" key={key} arrow disableInteractive>
                  <li >
                    <Grid
                      container
                      flexDirection="row"
                      alignItems='stretch'
                      sx={{
                        '&:hover': {
                          backgroundColor: '#e0e0e0',
                        }
                      }}
                      alignContent={'space-between'}
                      wrap="nowrap"
                    >
                      <Grid
                        item
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        flexGrow={1}
                      >
                        <span
                          {...rest}
                          style={{
                            paddingRight: 0,
                            paddingLeft: 13,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {`${option.key}:${option.value}`}
                        </span>
                      </Grid>

                      <Grid
                        item
                      >
                        <span
                          {...rest}
                          style={{
                            paddingLeft: 5,
                            paddingRight: 9,
                            position: 'static',
                          }}
                        >
                          ({option.occurances})
                        </span>
                      </Grid>
                    </Grid>
                  </li>
                </Tooltip>

              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search tags"
                  InputProps={{
                    ...params.InputProps,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                    endAdornment: (
                      <>
                        {isFetchingNextPage ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      minHeight: '28px',
                      fontSize: '12px',
                    },
                  }}
                />
              )}
            />
            {tags.length > 0 && (
              <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                {filters.tags.map((tag) => (
                  <Tooltip title={`${tag.key}:${tag.value}`} key={`${tag.key}:${tag.value}`} disableInteractive>
                    <FilteredChip
                      key={`${tag.key}:${tag.value}`}
                      label={`${tag.key}:${tag.value}`}
                      size="small"
                      onDelete={(event) => {
                        handleTagsChange(event, [tag], 'removeOption')
                      }}
                      sx={{
                        margin: 0.2,
                      }}
                    />
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        </Collapse>)
    }
