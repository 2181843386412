import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { SourceImageDetails } from './sourceImageDetails/sourceImageDetails'
import { ComponentAttributes, PolicyComponentAttributes } from '@draftt-io/shared-types'

export const Ec2ImageDetails = ({ component, policyComponent }: {component: ComponentAttributes, policyComponent: PolicyComponentAttributes}) => {
  const hasSourceAmi = (component.details?.amiChain || []).length > 0

  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid container>
        <Typography variant="subtitle2" gutterBottom>
          Image Details
        </Typography>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            ID:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.imageId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Architecture:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.imageArchitecture}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Creation Time:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(component.details.imageCreationDate).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      {component.details.imageDeprecationTime && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Deprecation Date:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {dayjs(component.details.imageDeprecationTime).format('MMM D, YYYY, h:mm A')}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Platform:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.imagePlatformDetails}
          </Typography>
        </Grid>
      </Grid>

      {hasSourceAmi && <SourceImageDetails component={component} policyComponent={policyComponent} />}
    </Grid>
  )
}
