import { useState } from 'react'
import { DataGridPro, GridColDef, GridPagination, useGridApiRef } from '@mui/x-data-grid-pro'
import { Button, Chip, Divider, Grid, LinearProgress, Paper, Tooltip, Typography } from '@mui/material'
import ComponentDetails from './components/componentDetails'
import _ from 'lodash'
import { Assistant, AutoAwesomeOutlined, FileDownload, Paid } from '@mui/icons-material'
import { exportDataToCsv } from './exportData'
import { useUser } from '@descope/react-sdk'
import { muiDataGridProDetailsColumn } from '../../../../consts'
import dayjs from 'dayjs'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

const ComponentsTable = ({ data, filters, onTagClick, filterModel, onFilterModelChange }: any) => {
  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns.filter((column) => column.field !== muiDataGridProDetailsColumn).map((column) => column.field)
  }
  const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 })

  const columns = [
    { field: 'technology', headerName: 'Technology', flex: 3 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      renderCell: (params: any) => {
        const name = _.get(params, 'row.name')
        const isDrafttAvailable = _.get(params, 'row.drafttDetails.id')
        const extendedSupportAnnualCost = _.get(params, 'row.extendedSupportAnnualCost')
        const status = _.get(params, 'row.status')
        const isSupported = status === PolicyComponentStatuses.Supported

        return (
          <Grid
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            wrap="nowrap"
            sx={{
              height: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              minWidth: 0,
            }}
          >
            {isDrafttAvailable != null && (
              <Tooltip
                title={
                  <Grid>
                    <strong> Upgrade Analysis Available! </strong> <br></br>
                    <Typography variant="body2" color="white" sx={{ fontSize: '11px' }}>
                      This component has been analyzed by Draftt’s AI engine, delivering insights into potential gaps, an impact analysis, and a
                      tailored upgrade plan to ensure smooth maintenance and optimization.
                    </Typography>
                  </Grid>
                }
              >
                <Assistant color="primary" sx={{ fontSize: '18px', m: '0 3px 1px 0' }} />
              </Tooltip>
            )}

            {extendedSupportAnnualCost != null && !isSupported && (
              <Tooltip
                title={
                  <Grid>
                    <Typography variant="body2" color="white" sx={{ fontSize: '11px' }}>
                      Extended support fees apply
                    </Typography>
                  </Grid>
                }
              >
                <Paid color="warning" sx={{ fontSize: '18px', m: '0 3px 1px 0' }} />
              </Tooltip>
            )}
            <Typography
              variant="body2"
              noWrap
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minWidth: 0,
              }}
            >
              {name}
            </Typography>
          </Grid>
        )
      },
    },
    {
      field: 'score',
      headerName: 'Score',
      flex: 2,
      renderCell: (params: any) => (
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Tooltip title={params.value}>
              <LinearProgress variant="determinate" value={params.value} />
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      renderCell: (params: any) => (
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          {params.value === PolicyComponentStatuses.Impending && (
            <Tooltip title={'The component is approaching its End-of-Life (EOL) and needs attention soon to remain supported.'}>
              <Chip label={params.value} color="warning" size="small" variant="outlined" />
            </Tooltip>
          )}
          {params.value === PolicyComponentStatuses.Supported && (
            <Tooltip title={'The component is within its supported lifecycle and requires no immediate action'}>
              <Chip label={params.value} color="success" size="small" variant="outlined" />
            </Tooltip>
          )}
          {params.value === PolicyComponentStatuses.Outdated && (
            <Tooltip title={'The component has surpassed its End-of-Life (EOL) and is no longer supported, requiring immediate action.'}>
              <Chip label={params.value} color="error" size="small" variant="outlined" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
    {
      field: 'currentVersion',
      headerName: 'Current Version',
      flex: 2,
      renderCell: (params: any) => {
        const guessExplanation = _.get(params.row, 'ecrDetails.details.technologyDetails.guessedExplanation')

        return (
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              variant="body2"
              noWrap
              sx={{ fontWeight: 400, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: 0 }}
            >
              {guessExplanation && (
                <Tooltip title={guessExplanation}>
                  <AutoAwesomeOutlined fontSize="small" sx={{ mr: '4px', width: '15px', verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {params.row.currentVersion}
            </Typography>
          </Grid>
        )
      },
    },
    { field: 'requiredVersion', headerName: 'Required Version', flex: 2 },
    { field: 'recommendedVersion', headerName: 'Recommended Version', flex: 2 },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 2,
      valueGetter: (params: any) => {
        return params ? params : 'No Due Date'
      },
      sortable: true,
      filterable: true,
      sortComparator: (v1: string | undefined, v2: string | undefined) => {
        if (v1 === 'No Due Date') return 1
        if (v2 === 'No Due Date') return -1

        const date1 = dayjs(v1)
        const date2 = dayjs(v2)

        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0
      },
      renderCell: (params: any) => {
        const sourceAmi = _.get(params.row, 'details.earliestDeprecationDateAmi')

        return (
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              variant="body2"
              noWrap
              sx={{ fontWeight: 400, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: 0 }}
            >
              {sourceAmi && (
                <Tooltip title={'Deprecation date extracted from the source image'}>
                  <AutoAwesomeOutlined fontSize="small" sx={{ mr: '4px', width: '15px', verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {params.value}
            </Typography>
          </Grid>
        )
      },
    },
  ]
  const getDetailPanelContent = (params: any) => {
    return <ComponentDetails componentData={params.row} filters={filters} onTagClick={onTagClick} />
  }

  const onRowClick = (params: any, event: any, gridDetails: any) => {
    gridDetails.api.toggleDetailPanel(params.id)
  }
  const { user } = useUser()
  const userTenant = _.get(user, 'userTenants[0].tenantId')
  const apiRef = useGridApiRef()
  return (
    <Paper elevation={1} sx={{ p: 2, ml: 2 }}>
      <DataGridPro
        apiRef={apiRef}
        rows={data}
        columns={columns}
        filterModel={filterModel}
        slots={{
          footer: () => (
            <div>
              <Divider />
              <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid
                  item
                  sx={{ cursor: 'pointer', fontSize: '12px', ':hover': { backgroundColor: '#e0e0e0' }, ml: 2 }}
                  onClick={() => {
                    userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' &&
                      exportDataToCsv({
                        data,
                        columns: apiRef.current.getVisibleColumns().filter((column) => column.field !== muiDataGridProDetailsColumn),
                      })
                  }}
                >
                  {userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' && (
                    <Button size="small" variant="outlined">
                      {<FileDownload fontSize="small" />} Export
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <GridPagination />
                </Grid>
              </Grid>
            </div>
          ),
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        density="compact"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        pageSizeOptions={[10, 25, 50, 100]}
        onRowClick={onRowClick}
        disableRowSelectionOnClick
        onFilterModelChange={(newFilterModel) => onFilterModelChange(newFilterModel)}
        disableColumnFilter={false}
        initialState={{
          sorting: {
            sortModel: [{ field: 'score', sort: 'desc' }],
          },
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Paper>
  )
}

export default ComponentsTable
