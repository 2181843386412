import { FC } from 'react'
import { ImpactAnalysisTimelineBaseline } from './components'
import { TimelineLabel, TimelineWrapper } from './ImpactAnalysisTimelineStyles'

export interface ImpactAnalysisTimelineProps {
  eolDate: Date
  eosDate?: Date
}

export const ImpactAnalysisTimeline: FC<ImpactAnalysisTimelineProps> = ({ eolDate, eosDate }) => {
  const today = new Date()

  const todayMs = today.getTime()
  const eolMs = eolDate.getTime()
  eosDate = eosDate ?? eolDate
  const eosMs = eosDate.getTime()
  const equalDates = todayMs === eosMs && eolMs === eosMs

  let [fractionToday, fractionEOL, fractionEOS] = getFractions(todayMs, eolMs, eosMs)


  if (!equalDates) {
    const diffPaddingNumber = 0.08
    let diff = fractionToday - fractionEOL

    if (Math.abs(diff) < diffPaddingNumber) {
      fractionToday += diffPaddingNumber
    }

    diff = fractionToday - fractionEOS

    if (Math.abs(diff) < diffPaddingNumber) {
      fractionToday += diffPaddingNumber
    }

    diff = fractionEOL - fractionEOS

    if (Math.abs(diff) < diffPaddingNumber) {
      fractionEOS += diffPaddingNumber
    }
  }


  const formatMarkerDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    })
  }

  return (
    <TimelineWrapper>
      <ImpactAnalysisTimelineBaseline
        eol={fractionEOL}
        eos={fractionEOS}
        today={fractionToday}
        eolDate={eolDate}
        eosDate={eosDate}
        equalDates={equalDates}
      />
      <TimelineLabel style={{ left: `${(fractionEOL * 100).toFixed(2)}%` }}>{equalDates ? 'Today' : formatMarkerDate(eolDate)}</TimelineLabel>
      <TimelineLabel
        style={{ left: `${(fractionEOS * 100).toFixed(2)}%`, display: equalDates || todayMs === eosMs || eolMs === eosMs ? 'none' : 'block' }}
      >
        {formatMarkerDate(eosDate)}
      </TimelineLabel>
      <TimelineLabel style={{ left: `${(fractionToday * 100).toFixed(2)}%`, display: equalDates ? 'none' : 'block' }}>Today</TimelineLabel>
    </TimelineWrapper>
  )
}

function getFractions(todayMs: number, eolMs: number, eosMs: number): [number, number, number] {
  const minMapped = 0.15
  const maxMapped = 1
  const mappedRange = maxMapped - minMapped

  const timelineStart = Math.min(eolMs, eosMs, todayMs)
  const timelineEnd = Math.max(eolMs, eosMs, todayMs)

  if (timelineStart === timelineEnd) {
    return [1, 0.15, 0.15]
  }

  function getRatio(dateMs: number) {
    let ratio = (dateMs - timelineStart) / (timelineEnd - timelineStart)
    return Math.max(0, Math.min(1, ratio))
  }

  const todayRatio = getRatio(todayMs)
  const eolRatio = getRatio(eolMs)
  const eosRatio = getRatio(eosMs)

  function mapRatio(ratio: number) {
    return minMapped + ratio * mappedRange
  }

  const todayMapped = mapRatio(todayRatio)
  const eolMapped = mapRatio(eolRatio)
  const eosMapped = mapRatio(eosRatio)

  return [todayMapped, eolMapped, eosMapped]
}
