import { ComponentAttributes } from '@draftt-io/shared-types'
import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'

export const EcrDetailsV2 = ({ componentData }: {componentData: ComponentAttributes}) => {
  const architectures = componentData.details?.architectures || []
  const osArchitectures = architectures.map((arch: Partial<any>) => [arch.os, arch.architecture, arch.variant].filter(elem => elem).join('/'))

  return (
    <Grid container flexDirection="row">
      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Latest Image:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details?.tagInfo?.latestImage ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Multi Architecture:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details?.isMultiArchitecture ? 'Yes' : 'No'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Pushed At:
          </Typography>
        </Grid>

        {componentData.details?.tagInfo?.pushedAt && <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.details?.tagInfo?.pushedAt).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>}
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Last Pull:
          </Typography>
        </Grid>

       {componentData.details?.tagInfo?.lastestPulledAt && <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.details?.tagInfo?.lastestPulledAt).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>}
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={1.5}>
          <Typography variant="body2" gutterBottom>
            Digest:
          </Typography>
        </Grid>

        <Grid item xs={10.5}>
          <Typography variant="body2" gutterBottom>
            {componentData.details?.imageManifest.digest}
          </Typography>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" item xs={12}>
        <Grid>
          <Typography variant="body2" gutterBottom>
            OS/Architecture/Variant:
          </Typography>
        </Grid>

        <Grid>
          <ul style={{ margin: 0 }}>
            {osArchitectures?.map((osArchitecture: any) => (
              <li key={osArchitecture}>{osArchitecture}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Grid>
  )
}
