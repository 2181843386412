import type { IntegrationAttributes, IntegrationType } from "@draftt-io/shared-types"
import { Collapse, Grid, Autocomplete, Tooltip, TextField, AutocompleteChangeReason, CircularProgress } from "@mui/material"
import { FC, useState } from "react"
import { FilteredChip } from "./filtersSidebar.style"
import { executeDrafttQLQuery, FiltersIntegration, InventoryFilters } from "../../inventoryV2"
import { DrafttQLInput } from "@draftt-io/drafttql-syntax"
import { useInfiniteQuery } from "@tanstack/react-query"
import { InfiniteListbox } from "../../../shared/infiniteScrollListbox"
import { debounce } from "lodash"
export interface IntegrationTypeFilterProps{
    type: IntegrationType
    expanded: boolean
    setFilters:React.Dispatch<React.SetStateAction<InventoryFilters>>  
    filters: InventoryFilters
    
}
export const IntegrationTypeFilters: FC<IntegrationTypeFilterProps> = (input) => {
  const {expanded, filters, setFilters, type} = input
  const [searchPrefix, setSearchPrefix] = useState<string | undefined>(undefined)
  const handleIntegrationIdsChange = (_event: React.SyntheticEvent | null, value: (string | Partial<FiltersIntegration>)[], reason: AutocompleteChangeReason) => {  
      if (reason && reason === 'clear') {
        setFilters((prev) => ({
          ...prev,
          integrations: prev.integrations.filter(filteredIntegration => filteredIntegration.type !== type)
        }))
      }else if (reason && reason === 'removeOption'){
        let integrationValue = value[0] as FiltersIntegration
        setFilters((prev) => ({
          ...prev,
          integrations: prev.integrations.filter((integration) => integration.id!== integrationValue.id)
        }))
      }
      else if (value) {
        setFilters((prev) => ({
          ...prev,
          integrationTypes: prev.integrationTypes.includes(type) ? prev.integrationTypes : [...prev.integrationTypes, type],
          integrations: value as FiltersIntegration[]
        }))
      }
    }
    
    const fetchIntegrations = async (input: {pageParam: string | undefined}) => {
      const query: DrafttQLInput<IntegrationAttributes> = {
          select: ['id', 'name', 'type'],
          filter: {type},
          resource: 'integration',
          pageSize: 1000,
        }
        if (searchPrefix){
            query.filter!['name'] = {$ilike: `*${searchPrefix}*`}
        }
        return await executeDrafttQLQuery({...query, pageToken: input.pageParam})
    }
  const {
      data,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    } = useInfiniteQuery({
      queryKey: ['Integrations', type, searchPrefix],
      initialPageParam: undefined,
      queryFn: fetchIntegrations,
      getNextPageParam: (lastPage) => lastPage.nextPageToken,
      enabled: expanded,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000*60*30
    })

    const pages = data?.pages || []
    // TODO: Improve performance here
    const integrations = pages.reduce((acc, page) => acc.concat(page.items as FiltersIntegration[]), [] as FiltersIntegration[])
    
  
  return (
      <Collapse in={expanded} sx={{ width: '100%' }}>
        <Grid container>
          <Autocomplete
            multiple
            freeSolo
            options={integrations.filter(integration => !filters.integrations.find(filtersIntegration => filtersIntegration.id === integration.id))}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
            filterOptions={(options) => options}
            ListboxComponent={InfiniteListbox}
            value={filters.integrations}
            onChange={handleIntegrationIdsChange}
            filterSelectedOptions
            noOptionsText="No options"
            onInputChange={debounce((_event, value) => {
                            setSearchPrefix(value.trim())
                          }, 300)}
            sx={{ ml: 4, width: '100%' }}
            renderTags={() => null}
            ListboxProps={{
              isFetchingNextPage,
              hasNextPage,
              fetchNextPage,
              sx: {
                '& .MuiAutocomplete-option': {
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  ":hover": {
                    backgroundColor: '#e0e0e0'
                  },
                },
              }
            } as any}
            renderOption={(props, option) => {
              const { key, ...rest } = props
              return <Tooltip title={option.name} placement="right" key={key} arrow disableInteractive>
                <li >
                  <Grid
                    container
                    flexDirection="row"
                    alignItems='stretch'
                    sx={{
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      }
                    }}
                    alignContent={'space-between'}
                    wrap="nowrap"
                  >
                    <Grid
                      item
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      flexGrow={1}
                    >
                      <span
                        {...rest}
                        style={{
                          paddingRight: 0,
                          paddingLeft: 13,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {option.name}
                      </span>
                    </Grid>

                  </Grid>
                </li>
              </Tooltip>

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
                placeholder={`Search Integrations`}
                InputProps={{
                  ...params.InputProps,
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                  endAdornment: (
                    <>
                      {isFetchingNextPage ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  mb: 1,
                  '& .MuiOutlinedInput-root': {
                    minHeight: '28px',
                    fontSize: '12px',
                  },
                }}
              />
            )}
          />
          {integrations.filter(integration => integration.type === type).length > 0 &&(
            <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
              {filters.integrations.filter(integration => integration.type === type).map((integration) => (
                <Tooltip title={integration.name} key={`${integration.type}-${integration.name}`} disableInteractive>
                  <FilteredChip
                    key={`${integration.type}-${integration.name}`}
                    label={integration.name}
                    size="small"
                    onDelete={(event) => {
                      handleIntegrationIdsChange(event, [integration], 'removeOption')
                    }}
                    sx={{
                      margin: 0.2,
                    }}
                  />
                </Tooltip>
              ))}
            </Grid>
          )}
        </Grid>
      </Collapse>)
  }
