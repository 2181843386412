import { FC, useEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react'
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPagination,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { Button, Chip, CircularProgress, Divider, Grid, LinearProgress, Paper, Tooltip, Typography } from '@mui/material'
import { Assistant, AutoAwesomeOutlined, FileDownload, Paid } from '@mui/icons-material'
import { useUser } from '@descope/react-sdk'
import { muiDataGridProDetailsColumn } from '../../../../consts'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { DrafttQLInput, DrafttQLResponse, DrafttQLSort, LeafPaths, SortDirection } from '@draftt-io/drafttql-syntax'
import {
  ComponentAttributes,
  ComponentTechnology,
  PolicyComponentAttributes,
  PolicyComponentStatuses,
  TagAttributes,
  TECHNOLOGY_TYPE_DISPLAY_NAMES,
} from '@draftt-io/shared-types'
import Loader from '../../../loader'
import { exportDataToCsv } from './exportDataV2'
import { ComponentDetailsV2 } from './components/componentDetails/componentDetailsV2'
import { allowedGridFilterOperators, GridFilterOperation } from '../../../../helpers/helpers'
import { InventoryFilters, executeDrafttQLQuery } from '../../inventoryV2'

export interface ComponentTableProps {
  onTagClick: (tag: Pick<TagAttributes, 'key' | 'value'>) => void
  filterModel: GridFilterModel
  filters: InventoryFilters
  onFilterModelChange: Dispatch<SetStateAction<GridFilterModel>>
  activeQuery: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes>
  totalItemsCount: number
}
export const ComponentsTableV2: FC<ComponentTableProps> = ({
  filters,
  onTagClick,
  filterModel,
  onFilterModelChange,
  activeQuery,
  totalItemsCount: totalPolicyComponents,
}) => {
  const allowedStringColumnFilterOperators = useMemo(
    () =>
      getGridStringOperators().filter((operator) => {
        return allowedGridFilterOperators.includes(operator.value as GridFilterOperation)
      }),
    [],
  )

  const allowedNumericColumnFilterOperators = useMemo(
    () =>
      getGridNumericOperators().filter((operator) => {
        return allowedGridFilterOperators.includes(operator.value as GridFilterOperation)
      }),
    [],
  )
  const apiRef = useGridApiRef()
  const [nextPageToken, setNextPageToken] = useState<string>()
  const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 })
  const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: 'urgency', sort: 'desc' }])
  const [exportLoading, setExportLoading] = useState(false)
  const updatePaginationModel = (model: GridPaginationModel, _details: GridCallbackDetails<'pagination'>) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ page: 0, pageSize: model.pageSize })
      setNextPageToken(undefined)
    } else {
      setPaginationModel(model)
    }
  }
  const updateSortModel = (model: GridSortModel, _details: GridCallbackDetails) => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }))
    setNextPageToken(undefined)
    setSortModel([...model])
  }
  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns.filter((column) => column.field !== muiDataGridProDetailsColumn).map((column) => column.field)
  }

  useEffect(() => {
    setNextPageToken((prev) => undefined)
    setPaginationModel((prev) => ({ ...prev, page: 0 }))
  }, [activeQuery])

  const currentPage = useQuery({
    queryKey: [activeQuery, paginationModel.page, paginationModel.pageSize, sortModel],
    queryFn: () => {
      const sort = sortModel.reduce((acc, next) => {
        acc[next.field as LeafPaths<PolicyComponentAttributes>] = next.sort as SortDirection
        return acc
      }, {} as DrafttQLSort<PolicyComponentAttributes>)
      const pageToken = paginationModel.page === 0 ? undefined : nextPageToken
      const query = { ...activeQuery, pageToken, pageSize: paginationModel.pageSize, sort }
      query.join!.query.select = ['hasAvailableDraftts', 'details$.technologyDetails$.guessedExplanation']
      return executeDrafttQLQuery(query)
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30,
    placeholderData: (prev) => prev,
  })

  useEffect(() => {
    setNextPageToken(currentPage.data?.nextPageToken)
  }, [currentPage.data])

  const columns: GridColDef[] = [
    {
      field: 'technology',
      headerName: 'Technology',
      flex: 3,
      valueGetter: (value: ComponentTechnology) => {
        return TECHNOLOGY_TYPE_DISPLAY_NAMES[value] || value
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      filterOperators: allowedStringColumnFilterOperators,
      renderCell: (
        params: GridRenderCellParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>,
      ): React.ReactNode => {
        const name = params.row.name
        const isDrafttAvailable = params.row.joined?.component.hasAvailableDraftts
        const extendedSupportAnnualCost = params.row.extendedSupportAnnualCost

        return (
          <Grid
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            wrap="nowrap"
            sx={{
              height: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              minWidth: 0,
            }}
          >
            {isDrafttAvailable && (
              <Tooltip
                title={
                  <Grid>
                    <strong> Upgrade Analysis Available! </strong> <br></br>
                    <Typography variant="body2" color="white" sx={{ fontSize: '11px' }}>
                      This component has been analyzed by Draftt’s AI engine, delivering insights into potential gaps, an impact analysis, and a
                      tailored upgrade plan to ensure smooth maintenance and optimization.
                    </Typography>
                  </Grid>
                }
              >
                <Assistant color="primary" sx={{ fontSize: '18px', m: '0 3px 1px 0' }} />
              </Tooltip>
            )}

            {extendedSupportAnnualCost != null && params.row.status !== PolicyComponentStatuses.Supported && (
              <Tooltip
                title={
                  <Grid>
                    <Typography variant="body2" color="white" sx={{ fontSize: '11px' }}>
                      Extended support fees apply
                    </Typography>
                  </Grid>
                }
              >
                <Paid color="warning" sx={{ fontSize: '18px', m: '0 3px 1px 0' }} />
              </Tooltip>
            )}
            <Typography
              variant="body2"
              noWrap
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minWidth: 0,
              }}
            >
              {name}
            </Typography>
          </Grid>
        )
      },
    },
    {
      field: 'urgency',
      headerName: 'Score',
      filterOperators: allowedNumericColumnFilterOperators,
      flex: 2,
      renderCell: (params: GridRenderCellParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>) => (
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Tooltip title={Math.floor(params.value)}>
              <LinearProgress variant="determinate" value={Math.floor(params.value)} />
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      renderCell: (params: GridRenderCellParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>) => (
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ height: '100%' }}>
          {params.value === PolicyComponentStatuses.Impending && (
            <Tooltip title={'The component is approaching its End-of-Life (EOL) and needs attention soon to remain supported.'}>
              <Chip label={params.value} color="warning" size="small" variant="outlined" />
            </Tooltip>
          )}
          {params.value === PolicyComponentStatuses.Supported && (
            <Tooltip title={'The component is within its supported lifecycle and requires no immediate action'}>
              <Chip label={params.value} color="success" size="small" variant="outlined" />
            </Tooltip>
          )}
          {params.value === PolicyComponentStatuses.Outdated && (
            <Tooltip title={'The component has surpassed its End-of-Life (EOL) and is no longer supported, requiring immediate action.'}>
              <Chip label={params.value} color="error" size="small" variant="outlined" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
    {
      field: 'currentVersion',
      headerName: 'Current Version',
      flex: 2,
      renderCell: (params: GridRenderCellParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>) => {
        const guessExplanation = params.row.joined?.component?.details?.ecrDetails?.technologyDetails?.guessedExplanation

        return (
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              variant="body2"
              noWrap
              sx={{ fontWeight: 400, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: 0 }}
            >
              {guessExplanation && (
                <Tooltip title={guessExplanation}>
                  <AutoAwesomeOutlined fontSize="small" sx={{ mr: '4px', width: '15px', verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {params.row.currentVersion}
            </Typography>
          </Grid>
        )
      },
    },
    { field: 'desiredVersion', headerName: 'Required Version', flex: 2 },
    { field: 'recommendedVersion', headerName: 'Recommended Version', flex: 2 },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 2,
      valueGetter: (dueDate: string) => {
        return dueDate ? dayjs(dueDate).format('MMM D, YYYY') : 'No Due Date'
      },
      sortable: true,
      filterable: true,
      filterOperators: allowedNumericColumnFilterOperators,
      sortComparator: (v1: string | undefined, v2: string | undefined) => {
        if (v1 === 'No Due Date') return 1
        if (v2 === 'No Due Date') return -1

        const date1 = dayjs(v1)
        const date2 = dayjs(v2)

        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0
      },
      renderCell: (params: GridRenderCellParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>) => {
        const sourceAmi = params.row?.details?.earliestDeprecationDateAmi

        return (
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              variant="body2"
              noWrap
              sx={{ fontWeight: 400, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: 0 }}
            >
              {sourceAmi && (
                <Tooltip title={'Deprecation date extracted from the source image'}>
                  <AutoAwesomeOutlined fontSize="small" sx={{ mr: '4px', width: '15px', verticalAlign: 'middle' }} />
                </Tooltip>
              )}
              {params.value}
            </Typography>
          </Grid>
        )
      },
    },
  ].map((col) => ({ filterOperators: allowedStringColumnFilterOperators, ...col }))
  const getDetailPanelContent = (
    params: GridRowParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>,
  ) => {
    return <ComponentDetailsV2 policyComponentData={params.row as PolicyComponentAttributes} filters={filters} onTagClick={onTagClick} />
  }

  const onRowClick = (
    params: GridRowParams<Partial<DrafttQLResponse<PolicyComponentAttributes, ComponentAttributes>['items'][number]>>,
    event: any,
    gridDetails: any,
  ) => {
    gridDetails.api.toggleDetailPanel(params.id)
  }
  const { user } = useUser()
  const userTenant = user?.userTenants?.[0]?.tenantId
  if (currentPage.error) {
    console.error(currentPage.error)
    return null
  } else if (!currentPage.data) {
    return <Loader></Loader>
  } else {
    return (
      <Paper elevation={1} sx={{ p: 2, ml: 2 }}>
        <DataGridPro
          apiRef={apiRef}
          rows={currentPage.data?.items}
          columns={columns}
          filterModel={filterModel}
          filterMode="server"
          rowCount={totalPolicyComponents}
          loading={currentPage.isFetching}
          slots={{
            footer: () => (
              <div>
                <Divider />
                <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Grid
                    item
                    sx={{ cursor: 'pointer', fontSize: '12px', ':hover': { backgroundColor: '#e0e0e0' }, ml: 2 }}
                    onClick={async () => {
                      if (userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB') {
                        setExportLoading(true)
                        await exportDataToCsv({
                          query: activeQuery,
                          columns: apiRef.current.getVisibleColumns().filter((column) => column.field !== muiDataGridProDetailsColumn),
                        })
                        setExportLoading(false)
                      }
                    }}
                  >
                    {userTenant === 'T2kbVh34U3Q8j0SSe1BUQUkhrlVB' &&
                      (exportLoading ? (
                        <CircularProgress size={25} sx={{ ml: 4, mt: '5px' }} color="primary" />
                      ) : (
                        <Button size="small" variant="outlined">
                          {<FileDownload fontSize="small" />} Export
                        </Button>
                      ))}
                  </Grid>
                  <Grid item>
                    <GridPagination disabled={currentPage.isFetching} />
                  </Grid>
                </Grid>
              </div>
            ),
          }}
          slotProps={{
            columnsManagement: {
              getTogglableColumns,
            },
          }}
          density="compact"
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={updatePaginationModel}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={updateSortModel}
          onRowClick={onRowClick}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          onFilterModelChange={(newFilterModel) => onFilterModelChange(newFilterModel)}
          disableColumnFilter={false}
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Paper>
    )
  }
}
