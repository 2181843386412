import React, { useState } from 'react'
import _ from 'lodash'
import {
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material'
import { ChangeDescription, ChangeTitle, HelmIcon } from './requiredChange.style'
import { CheckCircleOutlined, CircleOutlined, Close, HowToReg, KeyboardArrowDown, KeyboardArrowUp, WarningAmber } from '@mui/icons-material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import dic from '../../../../dictionary'
import { DRAFTT_STATUSES, NOTIFICATION_TYPES } from '../../../../consts'
import { useNotification } from '../../../commons/notification/notificationProvider'
import dayjs from 'dayjs'
import { useUser } from '@descope/react-sdk'
import ChangeAlert from './changeAlert'
import DeprecatedResources from './deprecatedResources'

const RequiredChange = (props: any) => {
  const { user } = useUser()
  const { showNotification } = useNotification()
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(true)

  const requiredChange = props.requiredChange

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed)
  }

  const handleConfirmationPopupClickOpen = () => {
    setUpdateDialogOpen(true)
  }

  const handleConfirmationPopupClickClose = () => {
    setUpdateDialogOpen(false)
  }

  const handleConfirm = async () => {
    setUpdateLoading(true)
    try {
      props.requiredChange.status = DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED
      props.requiredChange.acknowledgementDate = dayjs()
      props.requiredChange.acknowledgedBy = user.email
      await props.onConfirmChangeStatus(props.requiredChange.id)
      setUpdateLoading(false)
      setUpdateDialogOpen(false)
      showNotification(dic.draftt.confirmation_dialog.success, NOTIFICATION_TYPES.SUCCESS)
    } catch (error) {
      setUpdateLoading(false)
      setUpdateDialogOpen(false)
      showNotification(dic.draftt.confirmation_dialog.failure, NOTIFICATION_TYPES.ERROR)
    }
  }

  const parseAcknowledgementTooltipTitle = (change: any) => {
    let result

    const acknowledgementDate = dayjs(change.acknowledgementDate).format('DD/MM/YYYY')
    if (change.acknowledgedBy) {
      result = `${dic.draftt.acknowledged_by} ${change.acknowledgedBy} ${dic.draftt.at} ${acknowledgementDate}`
    } else {
      result = `${dic.draftt.acknowledgement_date} ${acknowledgementDate}`
    }

    return result
  }

  return (
    <Grid item xs={12}>
      <Card variant="outlined" sx={{ mt: '10px' }}>
        <CardContent sx={{ p: '6px', '&:last-child': { pb: '6px' } }}>
          <Grid container flexDirection="column" alignItems="start">
            <Grid container flexDirection="row" alignItems="center" justifyContent="space-between">
              <Grid item container alignItems="center" xs>
                <Grid item sx={{ mr: '8px' }}>
                  {(_.get(requiredChange, 'status') === DRAFTT_STATUSES.CHANGE.PENDING ||
                    _.get(requiredChange, 'status') === DRAFTT_STATUSES.CHANGE.INFO) && (
                    <IconButton onClick={handleConfirmationPopupClickOpen}>
                      <CircleOutlined />
                    </IconButton>
                  )}
                  {_.get(requiredChange, 'status') === DRAFTT_STATUSES.CHANGE.WARNING && (
                    <IconButton onClick={handleConfirmationPopupClickOpen}>
                      <WarningAmber />
                    </IconButton>
                  )}
                  {_.get(requiredChange, 'status') === DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED && (
                    <Tooltip title={parseAcknowledgementTooltipTitle(requiredChange)}>
                      <HowToReg sx={{ m: '8px' }} />
                    </Tooltip>
                  )}
                  {_.get(requiredChange, 'status') === DRAFTT_STATUSES.CHANGE.COMPLETED && <CheckCircleOutlined sx={{ m: '8px' }} />}
                </Grid>
                <Grid item>
                  <ChangeTitle>
                    {_.get(requiredChange, 'name')}
                    {_.get(requiredChange, 'helmChart') && (
                      <Tooltip title={_.get(requiredChange, 'helmChart')}>
                        <HelmIcon src={`https://draftt-public.s3.amazonaws.com/icons/helm.svg`} alt="helm" />
                      </Tooltip>
                    )}
                  </ChangeTitle>
                  <ChangeDescription>
                    {_.get(requiredChange, 'currentVersion')} {'->'} {_.get(requiredChange, 'desiredVersion')}
                  </ChangeDescription>
                </Grid>
              </Grid>
              {_.get(requiredChange, 'deprecatedResources') && (
                <Grid item>
                  <IconButton onClick={handleCollapseToggle}>{collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</IconButton>
                </Grid>
              )}
            </Grid>
            <Grid container flexDirection="column" alignItems="start">
              {_.get(requiredChange, 'description') && (
                <ChangeAlert status={_.get(requiredChange, 'status')} description={_.get(requiredChange, 'description')} />
              )}
            </Grid>
            <Collapse in={!collapsed} sx={{ width: '100%' }}>
              <DeprecatedResources deprecatedResources={_.get(requiredChange, 'deprecatedResources')} onRequiredChangeInfoClick={props.onRequiredChangeInfoClick} />
            </Collapse>
          </Grid>
        </CardContent>
      </Card>

      <Dialog open={updateDialogOpen} onClose={handleConfirmationPopupClickClose}>
        <Grid container justifyContent="center" alignItems="center" sx={{ minWidth: '420px', minHeight: '180px' }}>
          {!updateLoading && (
            <Grid>
              <DialogTitle>
                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
                  <span>{dic.draftt.confirmation_dialog.title}</span>
                  <IconButton aria-label="close" onClick={handleConfirmationPopupClickClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {dic.draftt.confirmation_dialog.message}
                  <span style={{ fontWeight: 600 }}>{dic.draftt.confirmation_dialog.undone}</span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmationPopupClickClose} color="primary">
                  {dic.draftt.confirmation_dialog.cancel}
                </Button>
                <Button onClick={handleConfirm} color="primary" disabled={updateLoading}>
                  {dic.draftt.confirmation_dialog.confirm}
                </Button>
              </DialogActions>
            </Grid>
          )}

          {updateLoading && (
            <Grid container flexDirection="row" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </Dialog>
    </Grid>
  )
}

export default RequiredChange
