import dic from '../../../../../dictionary'
import _, { replace } from 'lodash'
import { Paper, Grid } from '@mui/material'
import { AiIcon, WidgetTitle } from '../../../../../common.css'
import { DEFAULT_POLICIES_IDS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import InsightCard from './DemoInsightsCard'

const DemoInsights = (props: any) => {
  const topPriorityItems = [
    {
      type: NOTIFICATION_TYPES.WARNING,
      message:
        'Amazon EKS (PreWebAnalyticsuction) is scheduled to reach its end of support on May 1, 2025. At that point, the resource will be automatically upgraded to the next version.',
      link: replace(ROUTES.POLICIES.SINGLE, ':policyId', DEFAULT_POLICIES_IDS.EOL),
    },
    {
      type: NOTIFICATION_TYPES.WARNING,
      message:
        'RDS PostgreSQL (bitbucket-aurora) reached its end-of-life on February 29, 2024. Following this date, extended support incurs a yearly cost of $39,903.',
      link: replace(ROUTES.POLICIES.SINGLE, ':policyId', DEFAULT_POLICIES_IDS.EOL),
    },
    {
      type: NOTIFICATION_TYPES.WARNING,
      message:
        'The SSL certificate of *.draftt-codes.com is set to expire on April 25, 2025. Once expired, connections will be flagged as insecure unless a new certificate is issued.',
      link: replace(ROUTES.POLICIES.SINGLE, ':policyId', DEFAULT_POLICIES_IDS.EOL),
    },
  ]

  return (
    <Paper variant="outlined" sx={{ p: '16px' }}>
      <Grid container flexDirection="column">
        <Grid item>
          <WidgetTitle>
            <AiIcon fontSize="small" sx={{ mr: '10px', verticalAlign: 'text-top' }} />
            {dic.dashboard.insights.title}
          </WidgetTitle>
        </Grid>
        <Grid container item alignItems={'center'} sx={{ mt: '16px' }}>
          {_.map(topPriorityItems, (insight, index) => {
            return (
              <Grid item xs={12} key={`card-${index}`}>
                <InsightCard key={index} type={insight.type} message={insight.message} link={insight.link} />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DemoInsights
