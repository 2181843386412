import { Grid, Paper } from '@mui/material'
import { FC, useState } from 'react'
import DemoInsights from './DemoInsights'
import DemoIntegrationsSummary from './DemoIntegrationsSummary'
import DemoMetricSummary from './DemoMetricsSummary'
import DemoChart from './DemoChart'
import DemoMetricsInsightsSummary from './DemoMetricsInsightsSummary'
import { filter, groupBy } from 'lodash'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

export interface DemoDashboardProps {
  integrations: any
  policyComponents: any
  components: any
  certificates: any
  policies: any
  drafts: any
}

export const DemoDashboard: FC<DemoDashboardProps> = ({ integrations, policyComponents, certificates, components, policies, drafts }) => {
  const extendedSupportPolicyComponents = policyComponents.filter(
    (policyComponent: any) => policyComponent.details?.cost?.extendedSupport?.oneYearCost,
  )
  const potentialSaving = extendedSupportPolicyComponents.reduce((acc: any, policyComponent: any) => {
    return acc + policyComponent.details.cost.extendedSupport.oneYearCost
  }, 0)

  const policyNames = policies?.map((policy: any) => policy.name)
  const [selectedPolicy, setSelectedPolicy] = useState(policies?.length ? policies[0] : null)

  const policyCountById: { [key: string]: any } = {}
  Object.entries(groupBy(policyComponents, 'policyId')).forEach(([policyId, grouppedPolicyComponents]) => {
    policyCountById[policyId] = {
      compliant: filter(grouppedPolicyComponents, { status: PolicyComponentStatuses.Supported }).length,
      attention: filter(grouppedPolicyComponents, { status: PolicyComponentStatuses.Impending }).length,
      violations: filter(grouppedPolicyComponents, { status: PolicyComponentStatuses.Outdated }).length,
      total: grouppedPolicyComponents.length,
    }
  })

  const selectedPolicyCountData = policyCountById[selectedPolicy.id]
  const outdatedPercentage = selectedPolicyCountData.violations / selectedPolicyCountData.total

  return (
    <Grid container direction="row" justifyContent="space-between" width={'100%'}>
      <Grid item xs={12} sx={{ mb: '10px' }}>
        <DemoInsights integrations={integrations} policyComponents={policyComponents} />
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', width: '100%' }}>
        <Paper variant="outlined" sx={{ p: '16px', width: '50%', height: '100%' }}>
          <DemoChart
            policies={policies}
            policyNames={policyNames}
            selectedPolicy={selectedPolicy}
            setSelectedPolicy={setSelectedPolicy}
            selectedPolicyCountData={selectedPolicyCountData}
          />
        </Paper>
        <Paper variant="outlined" sx={{ p: '16px', mb: '16px', flexGrow: '1', height: '100%' }}>
          <DemoMetricsInsightsSummary potentialSaving={potentialSaving} outdatedPrecentage={outdatedPercentage} />
        </Paper>
      </div>
      <Grid item xs={6} sx={{ mb: '10px' }}></Grid>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', width: '100%' }}>
        <Paper variant="outlined" sx={{ p: '16px', width: '50%', height: '100%' }}>
          <DemoIntegrationsSummary integrations={integrations} components={components} certificates={certificates} />
        </Paper>
        <Paper variant="outlined" sx={{ p: '16px', mb: '16px', flexGrow: '1', height: '100%' }}>
          <DemoMetricSummary integrations={integrations} components={components} draftts={drafts} policies={policies} certificates={certificates} />
        </Paper>
      </div>
    </Grid>
  )
}
