import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import { FC, useMemo } from 'react'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

type PolicyData = { month: string; compliant: number; attention: number; violations: number; total: number }
export interface DemoChartProps {
  policies: any
  policyNames: string[]
  selectedPolicy: any
  setSelectedPolicy: any
  selectedPolicyCountData: any
}

const DemoChart: FC<DemoChartProps> = ({ policies, policyNames, selectedPolicy, setSelectedPolicy, selectedPolicyCountData }) => {
  const policyDataMap = policyNames?.reduce((acc: any, policy: string) => {
    acc[policy] = generateChartData(policy, selectedPolicyCountData)
    return acc
  }, {})

  const policyData = useMemo<PolicyData[]>(() => {
    if (!selectedPolicy) return []

    return policyDataMap[selectedPolicy.name]
  }, [selectedPolicy])

  const width = document.documentElement.clientWidth * 0.4
  const height = document.documentElement.clientHeight * 0.3

  const handlePolicyChange = (event: SelectChangeEvent) => {
    setSelectedPolicy(event.target.value)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', width: '100%', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1">Policy Breakdown Over Time</Typography>
        <Select
          value={selectedPolicy}
          onChange={handlePolicyChange}
          placeholder="Select a policy"
          renderValue={(value) => <div style={{ fontSize: '14px' }}>{(value as any).name}</div>}
          size="small"
          sx={{ width: '26%' }}
        >
          {policies.map((policy: any) => (
            <MenuItem key={policy.id} value={policy}>
              {policy.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          variant="subtitle1"
          style={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            textOrientation: 'mixed',
            marginRight: '10px',
            color: 'text.secondary',
            fontSize: '12px',
          }}
        >
          Components
        </Typography>
        <LineChart
          xAxis={[{ scaleType: 'band', data: policyData.map((d) => d.month) }]}
          series={[
            {
              data: policyData.map((d) => d.compliant),
              stack: 'count',
              stackOrder: 'reverse',
              label: 'Compliant',
              area: true,
              showMark: false,
              color: '#64BB7B',
            },
            {
              data: policyData.map((d) => d.attention),
              stack: 'count',
              label: PolicyComponentStatuses.Impending,
              area: true,
              showMark: false,
              color: '#e6b629',
            },
            {
              data: policyData.map((d) => d.violations),
              stack: 'count',
              label: PolicyComponentStatuses.Outdated,
              area: true,
              showMark: false,
              color: '#EC757C',
            },
          ]}
          width={width}
          height={height}
          slotProps={{
            legend: {
              position: { horizontal: 'middle', vertical: 'bottom' },
              padding: { top: 10 },
              itemMarkHeight: 7,
              itemMarkWidth: 7,
              labelStyle: { fontSize: '12px' },
            },
          }}
          title="Comparison"
        />
      </div>
    </>
  )
}

export default DemoChart

function generateChartData(policy: string, selectedPolicyCountData: any) {
  const months = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb']

  const endOfLifeRatios = [
    { green: 0.24, yellow: 0.45, red: 0.4 },
    { green: 0.35, yellow: 0.46, red: 0.19 },
    { green: 0.36, yellow: 0.6, red: 0.15 },
    { green: 0.47, yellow: 0.22, red: 0.31 },
    { green: 0.42, yellow: 0.48, red: 0.2 },
    { green: 0.68, yellow: 0.31, red: 0.15 },
    { green: 0.43, yellow: 0.24, red: 0.25 },
    { green: 0.56, yellow: 0.15, red: 0.3 },
    { green: 0.72, yellow: 0.21, red: 0.15 },
    { green: 0.5, yellow: 0.2, red: 0.3 },
    { green: 0.53, yellow: 0.15, red: 0.16 },
    { green: 0.59, yellow: 0.28, red: 0.14 },
  ]
  const latestRatios = [
    { green: 0.1, yellow: 0.1, red: 0.7 },
    { green: 0.1, yellow: 0.05, red: 0.75 },
    { green: 0.09, yellow: 0.06, red: 0.65 },
    { green: 0.15, yellow: 0.15, red: 0.8 },
    { green: 0.2, yellow: 0.1, red: 0.6 },
    { green: 0.14, yellow: 0.08, red: 0.68 },
    { green: 0.2, yellow: 0.25, red: 0.55 },
    { green: 0.45, yellow: 0.1, red: 0.45 },
    { green: 0.15, yellow: 0.28, red: 0.55 },
    { green: 0.45, yellow: 0.15, red: 0.4 },
    { green: 0.33, yellow: 0.05, red: 0.51 },
    { green: 0.27, yellow: 0.12, red: 0.61 },
  ]

  const ratios = policy === 'End Of Life' ? endOfLifeRatios : latestRatios

  const startTotal = 5312
  const endTotal = 9309
  const steps = months.length - 1

  const data = months.map((month, i) => {
    const total = Math.round(startTotal + ((endTotal - startTotal) * i) / steps)
    return {
      month,
      compliant: Math.round(ratios[i].green * total),
      attention: Math.round(ratios[i].yellow * total),
      violations: Math.round(ratios[i].red * total),
      total,
    }
  })

  data.push({
    month: 'Mar',
    compliant: selectedPolicyCountData.compliant,
    attention: selectedPolicyCountData.attention,
    violations: selectedPolicyCountData.violations,
    total: selectedPolicyCountData.total,
  })

  return data
}
