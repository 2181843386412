import { FC } from 'react'
import styles from './ImpactAnalysisTimelineBaseline.module.css'

export interface ImpactAnalysisTimelineBaselineLineProps {
  positionStart: number
  positionEnd: number
  color: string
}

export const ImpactAnalysisTimelineBaselineLine: FC<ImpactAnalysisTimelineBaselineLineProps> = ({ positionStart, positionEnd, color }) => {
  return (
    <div
      className={styles.line}
      style={{
        left: `calc(${(positionStart * 100).toFixed(2)}% - 7px)`,
        width: `${(positionEnd - positionStart) * 100}%`,
        backgroundColor: color,
      }}
    />
  )
}
