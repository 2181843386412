import React from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid, Paper, Typography } from '@mui/material'
import { DEFAULT_POLICIES_IDS } from '../../../../consts'
import { ChartsLegend, ChartsTooltip, PiePlot, ResponsiveChartContainer } from '@mui/x-charts'

const latestAndGreatestPieChart = (props: any) => {
  const latestAndGreatestPolicyComponent = _.filter(props.policyComponents, { policyId: DEFAULT_POLICIES_IDS.LATEST_AND_GREATEST })
  const latestAndGreatestPolicyCompliantComponents = _.filter(latestAndGreatestPolicyComponent, { isCompliant: true })
  const latestAndGreatestPolicyComponentsViolations = _.filter(latestAndGreatestPolicyComponent, { isCompliant: false })

  const graphData = [
    { label: 'Compliant', value: latestAndGreatestPolicyCompliantComponents.length, color: '#34A853' },
    { label: 'Violations', value: latestAndGreatestPolicyComponentsViolations.length, color: '#EA4335' },
  ]

  return (
    <Paper variant="outlined" sx={{ p: '16px', ml: '8px' }}>
      <Typography variant="subtitle1">{dic.dashboard.latestAndGreatestChart.title}</Typography>
      <Grid item sx={{ height: '215px' }}>
        <ResponsiveChartContainer
          margin={{ right: 0, left: 120 }}
          series={[
            {
              type: 'pie',
              data: graphData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
        >
          <ChartsTooltip trigger="item" />
          <ChartsLegend
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'left' },
                itemMarkWidth: 5,
                itemMarkHeight: 5,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
          />
          <PiePlot />
        </ResponsiveChartContainer>
      </Grid>
    </Paper>
  )
}

export default latestAndGreatestPieChart
