import { Paper, Grid, Typography } from '@mui/material'
import React from 'react'

const MetricSummaryCard = (props: any) => {
  return (
    <Grid item xs sx={{ height: '100%', display: 'flex' }}>
      <Paper sx={{ p: '20px', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }} variant="outlined">
        <Grid container flexDirection="column" justifyContent="center" sx={{ minWidth: '120px', flexGrow: 1, height: '100%', alignItems: 'center' }}>
          <Typography variant="h6">{props.metric.value}</Typography>
          <Typography variant="body2">{props.metric.title}</Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default MetricSummaryCard
