import styled from '@emotion/styled'
import { Grid } from '@mui/material'

export const CodeBlockContainer = styled(Grid)`
  margin-top: 16px;
  min-width: 38%;
  width: fit-content;
  max-width: 850px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`
