import { FC, useRef, useState } from 'react'
import styles from './ImpactAnalysisTimelineBaseline.module.css'
import { Popover } from '@mui/material'
import { ImpactAnalysisTimelineBaselinePopover } from './ImpactAnalysisBaselinePopover'

export interface ImpactAnalysisTimelineBaselineDotProps {
  position: number
  color: string
  date: Date
  status: string
}

export const ImpactAnalysisTimelineBaselineDot: FC<ImpactAnalysisTimelineBaselineDotProps> = ({ position, color, date, status }) => {
  const [hovering, setHovering] = useState(false)
  const anchorEl = useRef(null)

  return (
    <div
      className={styles.dot}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      ref={anchorEl}
      style={{
        left: `calc(${(position * 100).toFixed(2)}% - 7px)`,
        backgroundColor: color,
      }}
    >
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={hovering}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          pointerEvents: 'none',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key={`impact-analysis-baseline-dot-${position}-${color}`}
        onClose={() => setHovering(false)}
        disableAutoFocus
      >
        <ImpactAnalysisTimelineBaselinePopover
          color={color}
          date={date}
          anchorEl={anchorEl.current}
          isOpen={hovering}
          onClose={() => setHovering(false)}
          status={status}
        />
      </Popover>
    </div>
  )
}
