import { CloseOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Dispatch, FC, SetStateAction } from 'react'

interface DeprecatedResourceDetailsType {
  name: string
  version: string
  namespace: string
  lastApplied: boolean
  helmRelease: Record<string, unknown>
  requiredChanges: RequiredChange[]
}

interface RequiredChange {
  description: string
  isBreaking: boolean
  newValue: boolean | NewValueDefinition
  path: string
  type: string
}

interface NewValueDefinition {
  description: string
  type: string
  items?: {
    description: string
    enum: string
    type: string
  }
  default?: string
  required?: boolean
}

export interface DeprecatedResourcesDetailsProps {
  resource?: DeprecatedResourceDetailsType
  setIsCardVisible: Dispatch<SetStateAction<boolean>>
}

export const DeprecatedResourcesDetails: FC<DeprecatedResourcesDetailsProps> = ({ resource, setIsCardVisible }) => {
  const changes = resource?.requiredChanges ?? []

  const columns: GridColDef[] = [
    {
      field: 'path',
      headerName: 'Path',
      sortable: false,
      resizable: true,
      minWidth: 350,
      renderHeader: () => <Typography variant="subtitle2">Path</Typography>,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      resizable: true,
      minWidth: 200,
      renderHeader: () => <Typography variant="subtitle2">Type</Typography>,
      renderCell: ({ row }) => (
        <Box alignItems={'center'} height={'100%'} display={'flex'}>
          <Typography variant="body2">{prettifyDetailType(row.type)}</Typography>
        </Box>
      ),
    },
    {
      field: 'isBreaking',
      headerName: 'Is Breaking',
      width: 120,
      sortable: true,
      resizable: false,
      renderHeader: () => <Typography variant="subtitle2">Is Breaking</Typography>,
      renderCell: ({ row }) => (
        <Box alignItems={'center'} height={'100%'} display={'flex'}>
          <Typography variant="body2">{row.isBreaking ? 'Yes' : 'No'}</Typography>
        </Box>
      ),
    },
  ]

  const getDetailPanelContent = ({ row }: { row: RequiredChange; [key: string]: any }) => (
    <Grid container flexDirection="column" justifyContent="space-between" alignItems="flex-start" width={'80%'} padding={'20px'}>
      <Typography variant="subtitle1">{row.description} </Typography>
    </Grid>
  )

  return (
    <Grid sx={{ padding: '20px', position: 'relative', height: '100%' }}>
      <Grid sx={{ marginBottom: '20px'}}>
        <Typography variant="h5" fontWeight={500}>Resource Deprecation Details</Typography>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ marginBottom: '15px' }}>
        <Grid>
          <Typography variant="h6">{resource?.name}</Typography>
          <Typography variant="subtitle1">{resource?.version}</Typography>
        </Grid>
        <Grid>
          <IconButton onClick={() => setIsCardVisible(false)}>
            <CloseOutlined />
          </IconButton>
        </Grid>
      </Grid>
      <DataGridPro
        sx={{ height: '80%', position: 'relative' }}
        rows={changes.map((change, index) => ({ ...change, id: index }))}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={(params: any) => 'auto'}
        columns={columns}
        disableRowSelectionOnClick={true}
        pagination
        pageSizeOptions={[10]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'isBreaking', sort: 'desc' }],
          },
        }}
      />
    </Grid>
  )
}

function prettifyDetailType(type: string) {
  switch(type) {
    case 'PROPERTY_REQUIRED':
      return 'Required'
    case 'PROPERTY_ADDED':
      return 'Added'
    default:
      return type
  }
}
