import { IntegrationAttributes, IntegrationType, integrationTypes, integrationTypesToCloudProviders } from "@draftt-io/shared-types"
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material" 
import { Collapse, Grid, IconButton, FormControlLabel, Checkbox, Typography, CircularProgress } from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { aggregateCountDrafttQLQuery, InventoryFilters } from "../../inventoryV2"
import { DrafttQLInput } from "@draftt-io/drafttql-syntax"
import { useQuery } from "@tanstack/react-query"
import { IntegrationTypeFilters } from "./integrationTypeFilter"

export interface IntegrationFiltersProps{
    expanded: boolean
    setFilters: Dispatch<SetStateAction<InventoryFilters>>
    filters: InventoryFilters
} 
export const IntegrationFilters: FC<IntegrationFiltersProps> = (input) => {
    const {expanded, filters, setFilters} = input
    const [expandedVendors, setExpandedExpandedVendors] = useState<Partial<Record<IntegrationType, boolean>>>({})
    const toggleExpandedCloudProviders = (cloudProvider: IntegrationType) => {
        const newCloudProviders: Partial<Record<IntegrationType, boolean>> = { ...expandedVendors }
        newCloudProviders[cloudProvider] = !expandedVendors[cloudProvider]
        setExpandedExpandedVendors(newCloudProviders)
    } 
    const {data: integrationsAggregate, error, isLoading} = useQuery({queryKey: ['IntegrationsAggregate'], queryFn: async () => {
        const query: DrafttQLInput<IntegrationAttributes> = {
            resource: 'integration',
            filter: {type: {$in: [integrationTypes.aws, integrationTypes.gcp, integrationTypes.azure, integrationTypes["mongodb-atlas"]]}}
        }
        return aggregateCountDrafttQLQuery(query, 'type')
      }, 
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000*60*30,
        enabled: expanded
      })
    if (error){
        console.error(error)
        return null
    }else if (isLoading){
        return <div style={{marginLeft:'50%'}}>
          <CircularProgress  color="inherit" size={20} />    
        </div>
    }else{
    
    const filteredIntegrationTypes = Object.keys(integrationsAggregate?.aggregateCount || []).sort() as IntegrationType[]
    return (<Collapse in={expanded}>
    {filteredIntegrationTypes.map((integrationType) => (
      <Grid item container key={integrationType} paddingLeft={3} alignItems={'center'}>
        <Grid item xs={0.5}>
          <IconButton size="small"
            onClick={() => toggleExpandedCloudProviders(integrationType)}
            sx={{ cursor: 'pointer' }}>{expandedVendors[integrationType] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
        </Grid>
        <Grid item>
          <FormControlLabel
            key={integrationType}
            control={
              <Checkbox
                checked={filters.integrationTypes.includes(integrationType)}
                size="small"
                onChange={(e) => {
                  const newIntegrationTypes = e.target.checked ? [...filters.integrationTypes, integrationType] : filters.integrationTypes.filter((type) => type !== integrationType)
                  setFilters((prev) => ({
                    ...prev,
                    integrationTypes: newIntegrationTypes
                  })
                  )
                }}
                sx={{ ml: 4 }}
              />
            }
            label={
                <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                  {integrationTypesToCloudProviders[integrationType] || integrationType}
                </Typography>
            }
          />
        </Grid>
        <IntegrationTypeFilters expanded={expandedVendors[integrationType] ?? false} filters={filters} setFilters={setFilters} type={integrationType}/>
      </Grid>

    ))}
  </Collapse>
  )}
    }