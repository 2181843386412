import { Dispatch, FC, SetStateAction } from 'react'
import { Grid, Link, Typography } from '@mui/material'
import { CodeViewer, FileDropzone } from '../../../../../../commons'
import dic from '../../../../../../../dictionary'
import { IntegrationAttributes } from '@draftt-io/shared-types'
import config from '../../../../../../../config'

interface GCPWorkloadIdentityFederationUIProps {
  setJsonPayload: Dispatch<SetStateAction<IntegrationAttributes | null>>
}

export const GCPWorkloadIdentityFederationUI: FC<GCPWorkloadIdentityFederationUIProps> = ({ setJsonPayload }) => {
  const gcpWifCliInstructionsDict = dic.integrations.instructions.gcp.wif.ui

  const handleJsonPayloadChange = (payload: Record<string, any> | null) => {
    setJsonPayload(payload as IntegrationAttributes)
  }

  return (
    <>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.connect}</Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_1.title}</h3>
        <Grid flexDirection="column" gap={2}>
          <Grid container flexDirection="row" alignItems="center" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_1.title}</Typography>
            <Link target="blank" href="https://console.cloud.google.com/iam-admin/serviceaccounts">
              {gcpWifCliInstructionsDict.step_1.step_1.link}
            </Link>
          </Grid>

          <Grid container flexDirection="row" alignItems="center" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_2.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_1.step_2.action}
            </Typography>
          </Grid>

          <Grid container flexDirection="row" alignItems="center" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_3.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-int-readonly" size="small" style={{ width: '250px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="center" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_4.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-int-readonly" size="small" style={{ width: '250px' }} />
          </Grid>
          <Grid container flexDirection="column" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_5.title}</Typography>
            <CodeViewer language="text" code="A read only service Account for Draftt integration" size="small" style={{ width: '480px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_6.title}</Typography>
            <CodeViewer language="text" code="Viewer" size="small" style={{ width: '90px' }} />
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_6.title_2}</Typography>
            <CodeViewer language="text" code="iam.securityReviewer" size="small" style={{ width: '210px' }} />
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_1.step_6.title_3}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_2.title}</h3>
        <Grid flexDirection="column" gap={2}>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_1.title}</Typography>
            <Link target="blank" href="https://console.cloud.google.com/iam-admin/workload-identity-pools">
              {gcpWifCliInstructionsDict.step_2.step_1.link}
            </Link>
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_2.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_2.step_2.action}
            </Typography>
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_3.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-integration" size="small" style={{ width: '240px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_4.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-integration" size="small" style={{ width: '240px' }} />
          </Grid>

          <Grid container flexDirection="column" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_5.title}</Typography>
            <CodeViewer language="text" code="Workloak identity Pool for Draftt.io integration" size="small" style={{ width: '460px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_6.title_1}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_2.step_6.action_1}
            </Typography>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_6.title_2}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_2.step_6.action_2}
            </Typography>
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_7.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-aws-integration" size="small" style={{ width: '270px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_8.title}</Typography>
            <CodeViewer language="text" code="draftt-wif-aws-integration" size="small" style={{ width: '270px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_9.title}</Typography>
            <CodeViewer language="text" code={`${config.awsAccountId}`} size="small" style={{ width: '145px' }} />
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_10.title}</Typography>
          </Grid>

          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_11.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_2.step_11.action}
            </Typography>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.step_11.title_2}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_3.title}</h3>
        <Grid flexDirection="column" gap={2}>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_1.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_3.step_1.action}
            </Typography>
          </Grid>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_2.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_3.step_2.action}
            </Typography>
          </Grid>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_3.title}</Typography>
          </Grid>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_4.title}</Typography>

            <CodeViewer language="text" code="aws_role" size="small" style={{ width: '110px' }} />
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_4.title_2}</Typography>
          </Grid>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <CodeViewer
              language="text"
              code={`arn:aws:sts::${config.awsAccountId}:assumed-role/draftt-fetcher`}
              size="small"
              style={{ width: '500px' }}
            />
          </Grid>
          <Grid container flexDirection="row" alignItems="start" gap={'5px'} margin="10px 0">
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_5.title}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_3.step_5.action}
            </Typography>
          </Grid>
          <Grid container flexDirection="row" alignItems="start" sx={{ marginBottom: '16px' }}>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_6.title_1}</Typography>
            <Typography variant="subtitle1" fontWeight={'600'}>
              {gcpWifCliInstructionsDict.step_3.step_6.action_1}
            </Typography>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_6.title_2}</Typography>
            <CodeViewer language="text" code="draftt-wif-aws-integration" size="small" style={{ width: '270px', marginLeft: '5px' }} />
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_6.title_3}</Typography>
            <Typography variant="subtitle1">
              <Link href={gcpWifCliInstructionsDict.step_3.step_6.link_3.url} target="_blank" style={{ margin: '0 5px' }}>
                {gcpWifCliInstructionsDict.step_3.step_6.link_3.title}
              </Link>
            </Typography>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_6.title_4}</Typography>
          </Grid>
          <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
            <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.step_7.title}</Typography>
            <FileDropzone onFileUpload={handleJsonPayloadChange} accepts={{ 'application/json': ['.json'] }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
