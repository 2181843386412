import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../dictionary'
import { INTEGRATIONS, ROUTES } from '../../consts'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import _ from 'lodash'
import { CardActions, CardContent, Divider, Grid, Switch, Tooltip } from '@mui/material'
import { IntegrationCard, IntegrationDescription, IntegrationIcon } from './integrations.style'
import Button from '@mui/material/Button'

const Integrations = () => {
  const navigate = useNavigate()
  const [integrationsData, setIntegrationsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const parseIntegrationData = (integrations: any) => {
    return _.map(_.values(INTEGRATIONS), (integration: any) => {
      const integrationsByType = _.find(integrations, { type: integration.type })
      return {
        ...integration,
        isActive: !!integrationsByType,
      }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const integrations = await getAxiosInstance().get(`/integration`)
        const parsedIntegrations = parseIntegrationData(integrations.data)
        setIntegrationsData(parsedIntegrations)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const onViewIntegrationsClick = (integration: any) => {
    const integrationType = _.get(integration, 'type')
    if (integration.isActive) {
      navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', integrationType))
    } else {
      navigate(_.replace(ROUTES.INTEGRATIONS.CREATE, ':type', integrationType))
    }
  }

  return (
    <Grid>
      <h1>{dic.integrations.all.title}</h1>
      <Grid container flexDirection="row">
        {_.map(integrationsData, (integration: any) => (
          <IntegrationCard variant="outlined" key={_.get(integration, 'type')}>
            <CardContent>
              <Grid container flexDirection="row" justifyContent="space-between" sx={{ mb: '15px' }}>
                <IntegrationIcon
                  src={`https://draftt-public.s3.amazonaws.com/icons/${_.get(integration, 'type')}.svg`}
                  alt={_.get(integration, 'type')}
                />
                <Switch checked={_.get(integration, 'isActive')} />
              </Grid>
              <Tooltip title={_.get(integration, 'description')} arrow placement="right-end">
                <IntegrationDescription>{_.get(integration, 'description')}</IntegrationDescription>
              </Tooltip>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container flexDirection="row" justifyContent="end">
                <Button variant="text" onClick={() => onViewIntegrationsClick(integration)}>
                  {dic.integrations.all.view_integration}
                </Button>
              </Grid>
            </CardActions>
          </IntegrationCard>
        ))}
      </Grid>
    </Grid>
  )
}

export default Integrations
