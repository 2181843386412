import { ChevronLeft, ChevronRight, RestartAlt } from '@mui/icons-material'
import { CircularProgress, Grid, IconButton, Paper, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react'
import { ObjectValues } from '../../../../helpers/helpers'
import dayjs from 'dayjs'
import { TimelineScaleOption } from './futureTimelineV2V2.style'
import { aggregateCountDrafttQLQuery, countDrafttQLQuery, InventoryFilters, InventoryQuery } from '../../inventoryV2'
import { useQuery } from '@tanstack/react-query'
import { DrafttQLAggregateCountResponse } from '@draftt-io/drafttql-syntax'
import { TimelineNodeComponentV2 } from './timelineNodeV2'


function getPositionInPeriod(period:  TimelinePeriodV2, dueDate: string) {
  const totalDays = dayjs(period.end).diff(dayjs(period.start), 'days')
  const daysFromStart = dayjs(dueDate).diff(dayjs(period.start), 'days')
  return (daysFromStart / totalDays) * 100
}

export interface TimelineNodeV2 {
  positionPercentage: number
  totalComponentAmount: number
  dueDate: string
}
export interface TimelinePeriodV2{
  start: Date
  end: Date
  label: string
  nodesByDate: {
    [key:string]: TimelineNodeV2
  }
}

function addDueDateToPeriod(period: TimelinePeriodV2, dueDate: string, componentsCount: number) {
  if (period.nodesByDate[dueDate]){
    period.nodesByDate[dueDate].totalComponentAmount += componentsCount
  }else{
    period.nodesByDate[dueDate] = {
      positionPercentage: getPositionInPeriod(period, dueDate),
      dueDate,
      totalComponentAmount:componentsCount
    }
  } 
}
const timelineScale = {
  YEAR: 'YEAR',
  '6_MONTHS': '6_MONTHS',
  '3_MONTHS': '3_MONTHS',
  MONTH: 'MONTH',
} as const
type TimelineScale = ObjectValues<typeof timelineScale>

export const FutureTimelineV2V2 = ({
  activeQuery,
  filtersData,
}: {
  activeQuery: InventoryQuery,
  filtersData: { filters: InventoryFilters; setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  const currentDate = useMemo(() => dayjs(new Date()).startOf('hour').toDate(), [])
  const initialStartDate = useMemo(() => dayjs(currentDate).startOf('month').toDate(), [currentDate])
  const initialEndDate = useMemo(() => dayjs(initialStartDate).add(1, 'year').toDate(), [initialStartDate])
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate]= useState(initialEndDate)
  const [periodLengthMonths, setPeriodLength] = useState(3)
  const [monthAmount, setMonthAmount] = useState(5)
  const [timelineScale, setTimelineScale] = useState<TimelineScale>('YEAR')
  const calculatePeriods = (input: { startDate: Date,  aggregate?: DrafttQLAggregateCountResponse['aggregateCount']}) => {
    const periods: TimelinePeriodV2[] = []
    let previousMonth = dayjs(input.startDate).startOf('month').toDate()
    for (let i = 0; i < monthAmount; i++) {
      const nextMonth = dayjs(previousMonth).add(periodLengthMonths, 'months').toDate()
      const period: TimelinePeriodV2 = {
        start: previousMonth,
        end: nextMonth,
        label: dayjs(previousMonth).format('MMM YYYY').toString(),
        nodesByDate:{}
      }
      if (input.aggregate){
        for (const [dueDate, componentCount] of Object.entries(input.aggregate)) {
          const date = new Date(dueDate)
          if (date < period.end && date >= period.start) {
            addDueDateToPeriod(period,date.toISOString(), componentCount)
          }
        }
      }
      periods.push(period)
      previousMonth = nextMonth
    }
    return periods
  }
  const changePeriods = (mode: 'next' | 'previous') => {
    let nextStartDate
    if (mode === 'next'){
      nextStartDate = dayjs(startDate).add(periodLengthMonths, 'months').toDate()
    }else{
      nextStartDate = dayjs(startDate).subtract(periodLengthMonths, 'months').toDate()
    }
      setStartDate(nextStartDate)
      const periods = calculatePeriods({ startDate: nextStartDate })
      setEndDate(periods.slice(-1)[0].start)
  }

  const handleTimelineChange = (e: MouseEvent, newScale: TimelineScale) => {
    if (newScale !== null){
      
    let newPeriodLength: number
    let newMonthAmount: number
    switch (newScale) {
      case 'YEAR':
        newPeriodLength = 3
        newMonthAmount = 5
        break
      case '6_MONTHS':
        newPeriodLength = 2
        newMonthAmount = 4
        break
      case '3_MONTHS':
        newPeriodLength = 1
        newMonthAmount = 3
        break
      case 'MONTH':
        newPeriodLength = 1
        newMonthAmount = 2
        break
    }
    const monthList = []
    for (let month = 0; month <= 11; month += newPeriodLength) {
      monthList.push(month)
    }
    const newStartDate = dayjs(initialStartDate)
    setTimelineScale(newScale)
    setPeriodLength(newPeriodLength)
    setMonthAmount(newMonthAmount)
    setStartDate(newStartDate.toDate())
  }
}
useEffect(() => {
  setEndDate(dayjs(startDate).add((monthAmount - 1) * periodLengthMonths, 'months').toDate())
}, [startDate, monthAmount, periodLengthMonths])
  const onResetClick = () => {
    setStartDate(initialStartDate)
    filtersData.setFilters((previous) => ({
      ...previous,
      dueDate: undefined,
    }))
  }
  const {data: result, error, isFetching} = useQuery({queryKey: ['futureTimelineAggregate', activeQuery, startDate.toISOString(), endDate.toISOString()], queryFn: async () => {
    const [aggregate, next, previous] = await Promise.all([
      aggregateCountDrafttQLQuery({
      ...activeQuery, 
      filter: {...activeQuery.filter, $and: [{dueDate: {$gte: startDate.toISOString()}}, {dueDate: {$lte: endDate.toISOString()}}]}}, 
      'dueDate'),
      countDrafttQLQuery({...activeQuery, filter: {...activeQuery.filter, $and: [{dueDate: {$gt: endDate.toISOString()}}, {dueDate: {$exists: true}}]}}),
      countDrafttQLQuery({...activeQuery, filter: {...activeQuery.filter, dueDate: {$lt: startDate.toISOString()}}})
  ])
  return {
    aggregate: aggregate.aggregateCount,
    hasNext: next.count > 0,
    hasPrevious: previous.count > 0
  }
  }, 
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000*60*30,
    placeholderData: (prev) => ({...prev, hasPrevious: false, hasNext: false} as {aggregate: Record<any, number>, hasPrevious: boolean, hasNext: boolean})
  })
  if (error){
    console.error(error)
    return null
  }else{
    const periods = calculatePeriods({startDate, aggregate: result?.aggregate})
  return (
    <Grid>
      <Paper sx={{ mb: 2, ml: 2 }}>
        <Grid container direction="column" spacing={1} overflow="hidden">
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{
              paddingBottom: '20px',
              '& > .MuiGrid-item': {
                flexShrink: 0,
              },
            }}
          >
            <Grid item sx={{ flexGrow: 1, pl: 2 }} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
              <Typography variant="subtitle2" sx={{ paddingTop: '2px'}}>Timeline</Typography>
              {(isFetching && <CircularProgress size={'16px'} />)}
            </Grid>
            <Grid item>
              <ToggleButtonGroup value={timelineScale} onChange={handleTimelineChange} exclusive size="small" >
                <TimelineScaleOption value="YEAR">12 Months</TimelineScaleOption>
                <TimelineScaleOption value="6_MONTHS">6 Months</TimelineScaleOption>
                <TimelineScaleOption value="3_MONTHS">3 Months</TimelineScaleOption>
                <TimelineScaleOption value="MONTH">1 Month</TimelineScaleOption>
              </ToggleButtonGroup>
            </Grid>

            <Grid item sx={{ pl: 1 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => changePeriods('previous')}
                    disabled={!result?.hasPrevious}
                    sx={{
                      padding: '4px',
                      '&:hover': { backgroundColor: 'action.hover' },
                      '&.Mui-disabled': {
                        opacity: 0.3,
                      },
                    }}
                  >
                    <ChevronLeft fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => changePeriods('next')}
                    disabled={!result?.hasNext}
                    sx={{
                      padding: '4px',
                      '&:hover': { backgroundColor: 'action.hover' },
                    }}
                  >
                    <ChevronRight fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Tooltip title="Reset">
                <IconButton onClick={() => onResetClick()}>
                  <RestartAlt />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 6,
                marginRight: 2,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '2px',
                  paddingRight: '3%',
                  backgroundColor: '#e0e0e0',
                  width: '100%',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {periods.map((period, periodIndex) => {
                  return (
                    <div
                      key={`period-${periodIndex}-${period.label}`}
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        key={`tick-${periodIndex}`}
                        style={{
                          height: '20px',
                          width: '2px',
                          display: 'flex',
                          position: 'relative',
                          backgroundColor: '#e0e0e0',
                          transform: 'translateX(-50%)',
                        }}
                      />
                    </div>
                  )
                })}
                {periods.map((period, periodIndex) => {
                  const divs = Object.entries(period.nodesByDate).map(([date, node], nodeIndex) => {
              
                    const startingPercentage = (periodIndex / (monthAmount - 1)) * 100
                    const periodPercentage = 1 / (monthAmount - 1)
                    const totalPercentageMultiplier = 0.97
                    const absoluteLocation = (startingPercentage + node.positionPercentage * periodPercentage) * totalPercentageMultiplier
                    return (
                      <div
                        key={`node-${periodIndex}-${date}-${nodeIndex}`}
                        style={{
                          left: `${absoluteLocation}%`,
                          position: 'absolute',
                          borderRadius: '50%',
                          transform: 'translateX(-50%)',
                          cursor: 'pointer',
                          backgroundColor: '#4E3CAE',
                          width: 8,
                          height: 8,
                          zIndex: '100'
                        }}
                      >
                        <TimelineNodeComponentV2
                          nodeData={{
                            date,
                            node,
                            passedDueDate: dayjs(date).isBefore(dayjs(currentDate)) ? true : false,
                          }}
                          isSelected={filtersData.filters.dueDate ? dayjs(filtersData.filters.dueDate).isSame(dayjs(date)) : false}
                          setFilters={filtersData.setFilters}
                          key={`node-${periodIndex}-${date}-${nodeIndex}`}
                          activeQuery={activeQuery}
                        />
                      </div>
                    )
                  })
                  if (currentDate <= period.end && currentDate >= period.start) {
                    const startingPercentage = (periodIndex / (monthAmount - 1)) * 100
                    const periodPercentage = 1 / (monthAmount - 1)
                    const totalPercentageMultiplier = 0.97
                    const currentDatePositionPercentage = getPositionInPeriod(period, currentDate.toString())
                    const absoluteLocation = (startingPercentage + currentDatePositionPercentage * periodPercentage) * totalPercentageMultiplier
                    divs.push(
                      <div
                        key={`today`}
                        style={{
                          pointerEvents: 'none',
                          position: 'absolute',
                          left: `${absoluteLocation}%`,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'nowrap',
                            top: '-17px',
                            left: '-15px',
                            position: 'absolute',
                            color: 'rgba(0, 0, 0, 0.54)',
                            marginBottom: '4px',
                            fontSize: '10px',
                          }}
                        >
                          Today
                        </Typography>

                        <div
                          style={{
                            width: '1px',
                            transform: 'translateX(-50%)',
                            height: '10px',
                            backgroundColor: 'rgba(0, 0, 0, 0.54)',
                          }}
                        />
                      </div>,
                    )
                  }
                  return divs
                })}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={3}
            sx={{
              paddingBottom: '40px',
              position: 'relative',
              overflow: 'hidden',
              top: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '0px',
                  paddingRight: '1%',
                  backgroundColor: '#e0e0e0',
                  width: '100%',
                  maxWidth: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {periods.map((period, index) => (
                  <div key={`label-${index}-${period.label}`}>
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: 'nowrap',
                        color: 'black',
                        fontWeight: '500',
                      }}
                    >
                      {period.label}
                    </Typography>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
}
