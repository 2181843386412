import { Grid, Paper } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { InventoryFilters } from '../../inventoryV2'
import { DrafttQLInput } from '@draftt-io/drafttql-syntax/dist/DrafttQLTypes'
import { ComponentAttributes, PolicyComponentAttributes } from '@draftt-io/shared-types'
import { PolicyStatusGraphV2 } from '../policyStatusGraph/policyStatusGraphV2'

export const TopBarV2 = ({
  totalPolicyComponentCount,
  activeQuery,
  filtersData,
}: {
  totalPolicyComponentCount: number
  activeQuery: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes>
  filtersData: { setFilters: Dispatch<SetStateAction<InventoryFilters>> }
}) => {
  return (
    <div>
      <Paper elevation={1} sx={{ p: 2, ml: 2, mb: 2 }}>
        <Grid container flexDirection="row" spacing={2} alignItems={'center'} overflow={'hidden'} flexWrap="nowrap">
          <Grid item xs={12}>
            <PolicyStatusGraphV2 filtersData={filtersData} activeQuery={activeQuery} totalPolicyComponentCount={totalPolicyComponentCount}></PolicyStatusGraphV2>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

