import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dic from '../../../dictionary'
import { DRAFTT_STATUSES, ROUTES } from '../../../consts'
import { getAxiosInstance } from '../../../axiosInstance'
import Loader from '../../loader'
import { every, find, get, map } from 'lodash'
import { Box, Card, CardContent, Grid, IconButton } from '@mui/material'
import { ArrowBack, CheckCircleOutlined, CircleOutlined } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import RequiredChange from './requiredChange'
// import { useUser } from '@descope/react-sdk'
// import DemoDrafttDetails from './DemoDrafttDetails'
import { DeprecatedResourcesDetails } from './DeprecatedResourcesDetails'

const DrafttDetails = () => {
  // const { user } = useUser()
  const location = useLocation()
  const [draftt, setDraftt] = useState(location.state?.draftt)
  const { drafttId } = useParams<{ drafttId: string }>()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState(null)
  const [drafttData, setDrafttData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [isCardVisible, setIsCardVisible] = useState(false)
  const [selectedRequiredChangeResource, setSelectedRequiredChangeResource] = useState<any>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const drafttDetails = await getAxiosInstance().get(`/draftt/${drafttId}`)
        if (get(drafttDetails, 'data.length') === 0) {
          navigate(ROUTES.DRAFTTS.ALL)
        }

        setDraftt(get(drafttDetails, 'data[0]'))
        updateDrafttData(get(drafttDetails, 'data[0].details', []))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        navigate(ROUTES.DRAFTTS.ALL)
      }
    }
    fetchData()
  }, [])

  const updateDrafttData = (drafttData: any) => {
    let result

    result = map(drafttData, (drafttSection: any) => {
      let isCompleted = false
      if (get(drafttSection, 'changes', []).length === 0) {
        isCompleted = true
      } else {
        isCompleted = every(get(drafttSection, 'changes'), (change: any) => {
          return change.status === DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED || change.status === DRAFTT_STATUSES.CHANGE.COMPLETED
        })
      }

      return {
        ...drafttSection,
        isCompleted,
      }
    })

    setDrafttData(result)

    if (result.length > 0) {
      let currentActiveSection = find(result, { title: get(selectedItem, 'title') })
      if (!currentActiveSection || currentActiveSection.isCompleted === true) {
        let firstActiveSection = find(result, (section) => section.isCompleted === false)
        setSelectedItem(firstActiveSection || result[0])
      }
    }

    return result
  }

  if (loading) {
    return <Loader />
  }

  // const userTenant = get(user, 'userTenants[0].tenantId')
  // const demoTennantIds = ['T2gmCAcui7hboYnu4U8ywwgOGvce', 'T2h3BLxkCYc7fSYSGpbtATqN6Kot']
  // const isDemoAccount = userTenant && demoTennantIds.includes(userTenant)

  // if (isDemoAccount) {
  //   return <DemoDrafttDetails draftt={draftt} drafttData={drafttData} setDrafttData={setDrafttData} />
  // }

  const onRequiredChangeInfoClick = (resource: any) => {
    setIsCardVisible(true)
    setSelectedRequiredChangeResource(resource)
  }

  const onBackIconClick = () => {
    navigate(ROUTES.DRAFTTS.ALL)
  }

  const onConfirmChangeStatus = async (changeId: string) => {
    await getAxiosInstance().put(`/draftt/${drafttId}/change/${changeId}`, {
      status: DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED,
    })

    updateDrafttData(drafttData)
  }

  return (
    <Grid>
      <Grid container direction="column" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
          <IconButton onClick={onBackIconClick}>
            <ArrowBack />
          </IconButton>
          <h1>
            {dic.draftt.title} / {get(draftt, 'name')} / {get(draftt, 'currentVersion')} {'=>'} {get(draftt, 'desiredVersion')}
          </h1>
        </Box>
      </Grid>

      <Grid container flexDirection="row" gap={2}>
        <Grid item xs={3}>
          <Card variant="outlined">
            {map(drafttData, (drafttSection) => (
              <List key={get(drafttSection, 'title')} dense={true} sx={{ p: 0 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemButton divider={true} selected={selectedItem === drafttSection} onClick={() => setSelectedItem(drafttSection)}>
                    <ListItemIcon>
                      {get(drafttSection, 'isCompleted') === false && <CircleOutlined />}
                      {get(drafttSection, 'isCompleted') === true && <CheckCircleOutlined />}
                    </ListItemIcon>
                    <ListItemText primary={get(drafttSection, 'title')} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Card>
        </Grid>
        <Grid item xs={8} sx={{ position: 'relative ' }}>
          <Card
            variant="outlined"
            sx={{ display: isCardVisible ? 'block' : 'none', zIndex: 1, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, height: '100%', width: '100%' }}
          >
            <CardContent sx={{ pt: '0px', position: 'relative', height: '100%' }}>
              <DeprecatedResourcesDetails resource={selectedRequiredChangeResource} setIsCardVisible={setIsCardVisible} />
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent sx={{ pt: '0px' }}>
              <Grid container flexDirection="column">
                <Grid container flexDirection="column" sx={{ mb: '20px' }}>
                  <h3>{get(selectedItem, 'title')}</h3>
                  <span>{get(selectedItem, 'description')}</span>
                </Grid>
                {selectedItem && get(selectedItem, 'changes', []).length === 0 && <strong>{dic.draftt.no_required_changes}</strong>}
                {selectedItem &&
                  get(selectedItem, 'changes', []).length > 0 &&
                  map(get(selectedItem, 'changes'), (change) => (
                    <RequiredChange
                      key={get(change, 'name')}
                      requiredChange={change}
                      onConfirmChangeStatus={onConfirmChangeStatus}
                      onRequiredChangeInfoClick={onRequiredChangeInfoClick}
                    />
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DrafttDetails
