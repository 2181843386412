const config = {
  descope: {
    projectId: process.env.REACT_APP_DESCOPE_PROJECT_ID || '',
  },
  backendUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000',
  muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY || '',
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY || '',
  awsAccountId: process.env.REACT_APP_DRAFTT_AWS_ACCOUNT_ID || '',
}
export default config
