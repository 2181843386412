import { Typography, Grid, Box, CircularProgress } from '@mui/material'
import { DrafttClickableChip } from './componentDetails.style'
import dayjs from 'dayjs'
import { AWS_TECHNOLOGIES } from '../../../../../../consts'
import {
  ComponentAttributes,
  ComponentTechnology,
  IntegrationAttributes,
  PolicyComponentAttributes,
  TagAttributes,
  TECHNOLOGY_TYPE_DISPLAY_NAMES,
} from '@draftt-io/shared-types'
import { useQuery } from '@tanstack/react-query'
import { getAxiosInstance } from '../../../../../../axiosInstance'
import { InventoryFilters } from '../../../../inventoryV2'
import { DrafttQLInput } from '@draftt-io/drafttql-syntax'
import { Ec2DetailsV2 } from './ec2Details/ec2DetailsV2'
import { EcrDetailsV2 } from './ecrDetails/ecrDetailsV2'
import { DrafttAvailableBadge } from './drafttAvailableBadge/drafttAvailableBadgeV2'
import { ImpactAnalysis } from './ImpactAnalysis'

async function getComponentData(id: ComponentAttributes['id']) {
  const result = await getAxiosInstance().get(`/component/${id}`)
  return result.data[0] as ComponentAttributes
}

async function getIntegrationData(id: IntegrationAttributes['id']) {
  const query: DrafttQLInput<IntegrationAttributes> = {
    resource: 'integration',
    filter: { id },
  }
  const result = await getAxiosInstance().post('/query', { query })
  return result?.data?.items[0]
}
export const ComponentDetailsV2 = (props: {
  policyComponentData: PolicyComponentAttributes
  onTagClick: (tag: Pick<TagAttributes, 'key' | 'value'>) => void
  filters: InventoryFilters
}) => {
  const {
    data: component,
    error: componentError,
    isLoading: componentIsLoading,
  } = useQuery({
    queryKey: ['component', props.policyComponentData],
    queryFn: () => getComponentData(props.policyComponentData.componentId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30,
  })
  const {
    data: integration,
    error: integrationError,
    isLoading: integrationIsLoading,
  } = useQuery({
    queryKey: ['integration', component?.integrationId],
    queryFn: () => getIntegrationData(component?.integrationId as bigint),
    enabled: !!component,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30,
  })
  const displayDueDate = dayjs(props.policyComponentData.dueDate).format('MMM D, YYYY')
  if (!componentIsLoading && component && !integrationIsLoading) {
    if (componentError || integrationError) {
      console.error(componentError)
      console.error(integrationError)
    }
    let description = dayjs(props.policyComponentData.dueDate).isAfter(dayjs())
      ? `${TECHNOLOGY_TYPE_DISPLAY_NAMES[props.policyComponentData.technology as ComponentTechnology] || props.policyComponentData.technology} is running on version ${component.version}. Update version to ${props.policyComponentData.desiredVersion} by ${displayDueDate}.`
      : `This component has reached end-of-life on ${displayDueDate}.`

    let impact

    if (props.policyComponentData.extendedSupportAnnualCost != null) {
      impact = `Using this outdated version incurs an annual extended support fee of $${props.policyComponentData.extendedSupportAnnualCost}/year.`
    } else if (props.policyComponentData.technology === 'Google CloudSQL MySQL') {
      impact = `Starting ${displayDueDate}, this component will be enrolled automatically into extended support, a paid service with hourly costs calculated per core. These fees can add up significantly. To avoid extended support charges and the eventual need for a forced upgrade, it’s essential to act proactively and prepare in advance.`
    } else if (props.policyComponentData.technology === 'Amazon EKS') {
      impact = `Effective ${displayDueDate}, this component will automatically enter extended support, a paid service with costs calculated hourly per core. AWS charges $0.60 per cluster per hour, amounting to approximately $4,380 per year. These expenses can escalate quickly. To avoid incurring extended support fees and the eventual necessity of a forced upgrade, it’s crucial to take proactive steps and prepare ahead of time.`
    }

    const extractGcpProject = () => {
      let result
      if (component.uniqueIdentifier.startsWith('projects/')) {
        result = component.uniqueIdentifier.split('/')?.[1]
      } else if (component.uniqueIdentifier.includes('googleapis.com/')) {
        result = component.uniqueIdentifier.split('/')[5]
      }

      return result
    }

    return (
      <Grid>
        {props.policyComponentData.dueDate && (
          <Grid sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Description
            </Typography>

            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </Grid>
        )}

        {props.policyComponentData.dueDate !== 'No Due Date' && <ImpactAnalysis policyComponent={props.policyComponentData} />}

        <Grid sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Details
          </Typography>

          <Grid container flexDirection="row">
            {integration && (
              <Grid container item xs={6}>
                <Grid item xs={3}>
                  <Typography variant="body2" gutterBottom>
                    Integration:
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    {integration.name}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container item xs={6}>
              <Grid item xs={3}>
                <Typography variant="body2" gutterBottom>
                  Last Updated:
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="body2" gutterBottom>
                  {dayjs(props.policyComponentData.updatedAt).format('MMM D, YYYY, h:mm A')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {(component.uniqueIdentifier.startsWith('projects/') || component.uniqueIdentifier.includes('googleapis.com/')) && (
            <Grid container flexDirection="row">
              <Grid container item xs={6}>
                <Grid item xs={3}>
                  <Typography variant="body2" gutterBottom>
                    Project:
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Typography variant="body2" gutterBottom>
                    {extractGcpProject()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={6}></Grid>
            </Grid>
          )}

          {component.technology === AWS_TECHNOLOGIES.ECR && <EcrDetailsV2 componentData={component} />}
          {component.technology === AWS_TECHNOLOGIES.EC2 && <Ec2DetailsV2 component={component} policyComponent={props.policyComponentData} />}
        </Grid>

        {component.tags && Object.keys(component.tags).length > 0 && (
          <Grid sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              {component.technology === AWS_TECHNOLOGIES.ECR ? 'Repository Tags' : 'Tags'} ({Object.keys(component.tags).length})
            </Typography>

            <Grid container flexDirection="row" alignItems="center">
              {Object.entries(component.tags).map(([key, value]) => {
                const fullTag = `${key}:${value}`

                return (
                  <Box key={fullTag} mr={1}>
                    <DrafttClickableChip
                      size="small"
                      color="secondary"
                      variant={
                        props.filters.tags.find((selectedTag) => selectedTag.key === key && selectedTag.value === value) ? 'filled' : 'outlined'
                      }
                      label={fullTag}
                      onClick={() => props.onTagClick({ key, value })}
                    />
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        )}

        {component.hasAvailableDraftts && <DrafttAvailableBadge component={component} />}
      </Grid>
    )
  } else {
    return (
      <Grid container justifyContent="center" alignItems="center" height={100} width="100%">
        <CircularProgress color="primary" />
      </Grid>
    )
  }
}
