import { FC } from 'react'
import { ImpactAnalysisTimelineLineBaselineLine } from './ImpactAnalysisTimelineBaselineStyles'

export interface ImpactAnalysisTimelineBaselineLineProps {
  positionStart: number
  positionEnd: number
  color: string
}

export const ImpactAnalysisTimelineBaselineLine: FC<ImpactAnalysisTimelineBaselineLineProps> = ({ positionStart, positionEnd, color }) => {
  return (
    <ImpactAnalysisTimelineLineBaselineLine
      style={{
        left: `${(positionStart * 100).toFixed(2)}%`,
        width: `${(positionEnd - positionStart) * 100}%`,
        backgroundColor: color,
      }}
    />
  )
}
