import React from 'react'
import { Typography, Grid, Stepper, StepLabel, Step, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import _, { map } from 'lodash'
import { Circle } from '@mui/icons-material'

const SourceImageDetails = ({ componentData }: any) => {
  const sourceAmiChain = _.get(componentData, 'details.amiChain', [])

  const originImage = {
    id: _.get(componentData, 'details.imageId'),
    imageArchitecture: _.get(componentData, 'details.imageArchitecture'),
    imageCreationDate: componentData.details.imageCreationDate,
    imageDeprecationTime: componentData.details.imageDeprecationTime,
    imagePlatformDetails: componentData.details.imagePlatformDetails,
    version: componentData.currentVersion,
  }

  if (!_.some(sourceAmiChain, { id: originImage.id })) {
    sourceAmiChain.unshift(originImage)
  }

  let earliestDeprecationDateAmi: any = undefined
  if (!componentData.isCompliant) {
    earliestDeprecationDateAmi = _.get(componentData, 'details.earliestDeprecationDateAmi')
    if (!earliestDeprecationDateAmi && originImage.imageDeprecationTime) {
      earliestDeprecationDateAmi = originImage.id
    }
  }

  const getTooltipTitle = (ami: any) => {
    return (
      <Grid container flexDirection="column">
        <Typography variant="caption">
          <b>ID:</b> {ami.id}
        </Typography>
        <Typography variant="caption">
          <b>Architecture:</b> {ami.imageArchitecture}
        </Typography>
        <Typography variant="caption">
          <b>Creation Date:</b> {dayjs(ami.imageCreationDate).format('MMM D, YYYY')}
        </Typography>
        <Typography variant="caption">
          <b>Platform:</b> {ami.imagePlatformDetails}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container sx={{ mt: 1 }}>
      <Typography variant="subtitle2" gutterBottom>
        Source Image Details
      </Typography>
      <Grid container>
        <Stepper orientation="vertical">
          {map(sourceAmiChain, (ami, index) => (
            <Step key={index}>
              <Tooltip title={getTooltipTitle(ami)}>
                <StepLabel icon={<Circle color={earliestDeprecationDateAmi === ami.id ? 'error' : 'disabled'} />}>
                  <Grid container flexDirection="column">
                    <Typography variant="caption" sx={{ color: earliestDeprecationDateAmi === ami.id ? 'error.main' : 'black' }}>
                      {ami.version}
                    </Typography>
                    <Typography variant="caption" sx={{ color: earliestDeprecationDateAmi === ami.id ? 'error.main' : 'black' }}>
                      {ami.imageDeprecationTime ? `Deprecation: ${dayjs(ami.imageDeprecationTime).format('MMM D, YYYY')}` : undefined}
                    </Typography>
                  </Grid>
                </StepLabel>
              </Tooltip>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  )
}

export default SourceImageDetails
