import { Typography, Grid, Chip } from '@mui/material'
import { Assistant } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { DrafttAvailableBadgePaper } from './drafttAvailableBadgeV2.style'
import { ComponentAttributes, DrafttAttributes } from '@draftt-io/shared-types'
import { DrafttQLInput } from '@draftt-io/drafttql-syntax'
import { getAxiosInstance } from '../../../../../../../axiosInstance'
import { useQuery } from '@tanstack/react-query'

async function getComponentActiveDraftts(id: ComponentAttributes['id']){
  const query: DrafttQLInput<DrafttAttributes> = {
    resource: 'draftt',
    filter: {componentId:id, status: 'active'}
  }
  const result = await getAxiosInstance().post('/query', {query})
  // Ignoring next page as no component will have more than 100 draftts
  return result.data.items as DrafttAttributes[]
}
export const DrafttAvailableBadge = ({ component }: { component: ComponentAttributes }) => {
  const {data: draftts, error, isLoading: loading} = useQuery({queryKey: ['draftts', component.integrationId], queryFn: () => getComponentActiveDraftts(component.id as bigint ), enabled: !!component, 
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000*60*30})
  if (error){
    console.error(error)
    return null
  }
  if (loading){
    return null
  }
  return <Grid container direction={'column'}>
  {draftts && draftts.map(draftt => {
  const inventoryUrl = `${window.location.origin}/draftt/${draftt.id}`
  return (
    <Grid item key={draftt.id}>
    <DrafttAvailableBadgePaper elevation={0} sx={{ m: 2, p: 1.5, borderRadius: '10px' }}>
      <Grid container flexDirection="row">
        <Grid item xs={10} alignItems="center" justifyContent="center">
          <Grid container flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
            <Assistant color="primary" sx={{ mr: '3px' }} />
            <Typography variant="subtitle2" color="primary">
              Upgrade Plan Available
            </Typography>
            <Chip label="In Progress" size="small" sx={{ ml: 1, borderRadius: 1 }} />
          </Grid>
          <Grid container flexDirection="row">
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              Upgrade {component.technology} to {draftt.desiredVersion}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={2} container flexDirection="column" alignItems="flex-end" justifyContent="center">
          <Button variant="contained" size="small" component="a" href={inventoryUrl} target="_blank" rel="noopener noreferrer" sx={{ mr: 1 }}>
            View Plan
          </Button>
        </Grid>
      </Grid>
    </DrafttAvailableBadgePaper>
  </Grid>
  )
})}
</Grid>
}
