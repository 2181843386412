import { Collapse, Grid, Autocomplete, Tooltip, TextField, CircularProgress } from "@mui/material"
import { FC, Fragment, useState } from "react"
import { FilteredChip } from "./filtersSidebar.style"
import { aggregateCountDrafttQLQuery, InventoryFilters, InventoryQuery } from "../../inventoryV2"
import { useQuery } from "@tanstack/react-query"
import { ComponentTechnology, TECHNOLOGY_TYPE_DISPLAY_NAMES } from "@draftt-io/shared-types"
export interface TechnologiesFilterProps{
    expanded: boolean
    filters: InventoryFilters
    handleTechnologiesChange: (event: React.SyntheticEvent | null, value?: string[] | string) => void 
    query: InventoryQuery
}
export const TechnologiesFilter
: FC<TechnologiesFilterProps> = (input: TechnologiesFilterProps) => {
    const {expanded, query, filters, handleTechnologiesChange} = input
    const [open, setOpen] = useState(false)
    const handleOpen = () => {setOpen(true)}
    const handleClose = () => {setOpen(false)}
    const {data: technologies, error, isLoading} = useQuery({queryKey: ['TechnologiesAggregate'], queryFn: async () => {
        return aggregateCountDrafttQLQuery(query, 'technology')
      }, 
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000*60*30,
        enabled: expanded
      })
    if (error){
        console.error(error)
    }
    return <Collapse in={expanded} sx={{ width: '100%' }}>
    <Grid container>
      <Autocomplete
        multiple
        freeSolo
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        loading={isLoading} 
        options={
          Object.entries(technologies?.aggregateCount || [])
          .map(([tech, count]) => ({technology: tech, count }))
          .filter(tech => !filters.technologies.includes(tech.technology))
          .sort((prev,next) => next.count - prev.count)}
        value={filters.technologies}
        onChange={(e, value) => handleTechnologiesChange(e, value.map(val => typeof val === 'string' ? val : val.technology))}
        noOptionsText="No options"
        loadingText={<div style={{fontSize:'12px', width: '100%'}}>Loading...</div>}
        sx={{ ml: 4, width: '100%',}}
        getOptionLabel={(option) => {
          const displayOption = typeof option === 'string' ? option : option.technology
          const res = TECHNOLOGY_TYPE_DISPLAY_NAMES[displayOption as ComponentTechnology] || displayOption
          return res
        }}
        renderTags={() => null}
        ListboxProps={{
          sx: {
            '& .MuiAutocomplete-option': {
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            },
          },
        }}
        renderOption={(props, option) => {
          const { key, ...rest } = props
          const displayName = TECHNOLOGY_TYPE_DISPLAY_NAMES[option.technology as ComponentTechnology] || option.technology
          return (
            <Tooltip title={displayName} placement="right" key={key} arrow disableInteractive>
              <li>
                    <Grid
                    container
                    flexDirection="row"
                    alignItems='stretch'
                    sx={{
                        '&:hover': {
                        backgroundColor: '#e0e0e0',
                        }
                    }}
                    alignContent={'space-between'}
                    wrap="nowrap"
                    >
                    <Grid
                        item
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        flexGrow={1}
                    >
                        <span
                        {...rest}
                        style={{
                            paddingRight: 0,
                            paddingLeft: 13,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        >
                        {displayName}
                        </span>
                    </Grid>

                    <Grid
                        item
                    >
                        <span
                        {...rest}
                        style={{
                            paddingLeft: 5,
                            paddingRight: 9,
                            position: 'static',
                        }}
                        >
                        ({option.count})
                        </span>
                    </Grid>
                    </Grid>
                </li>
            </Tooltip>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Search technologies"
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
              endAdornment: (
                <Fragment>
                  {open && isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            sx={{
              mb: 1,
              
              '& .MuiOutlinedInput-root': {
                minHeight: '28px',
                fontSize: '12px',
              },
            }}
          />
        )}
      />
      {filters.technologies.length > 0 && (
        <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
          {filters.technologies.map((technology) => (
            <Tooltip title={technology} key={technology} disableInteractive>
              <FilteredChip
                key={technology}
                label={TECHNOLOGY_TYPE_DISPLAY_NAMES[technology as ComponentTechnology] || technology
                }
                size="small"
                onDelete={() => {
                  handleTechnologiesChange(null, technology)
                }}
                sx={{
                  margin: 0.2,
                }}
              />
            </Tooltip>
          ))}
        </Grid>
      )}
    </Grid>
  </Collapse>
}