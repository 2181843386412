import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { ImpactAnalysisTimeline } from './ImpactAnalysisTimeline'

const DemoImpactAnalysis = ({ componentData }: any) => {
  let impact: string | any

  const isTimelineTechnology = componentData.technology === 'Amazon EKS' || componentData.technology === 'Amazon RDS PostgreSQL'

  const calcTodayImpact = () => {
    let result = 'No immediate impact.'

    if (componentData.extendedSupportAnnualCost) {
      result = `Incurring extended support fees of $${componentData.extendedSupportAnnualCost}/year. Risk of security vulnerabilities.`
    }

    return result
  }

  const randomPrice = () => {
    return _.random(20000, 45000)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      .split('.')[0]
  }

  const calcExtendedSupportImpact = () => {
    let result

    if (componentData.extendedSupportAnnualCost) {
      result = undefined
    } else {
      if (componentData.technology === 'Amazon EKS') {
        result = {
          title: dayjs(componentData.dueDate).format('MMM DD, YYYY'),
          subtitle: 'Incurring extended support fees of $4,380 per year. Risk of security vulnerabilities.',
        }
      } else if (componentData.technology === 'Amazon RDS PostgreSQL') {
        result = {
          title: dayjs(componentData.dueDate).format('MMM DD, YYYY'),
          subtitle: `Incurring extended support fees of ${randomPrice()} per year. Risk of security vulnerabilities.`,
        }
      }
    }

    return result
  }

  const eosJump = componentData.technology === 'Amazon EKS' ? 1 : 3
  const eosDate = dayjs(componentData.dueDate).add(eosJump, 'year')
  const eolDate = dayjs(componentData.dueDate)

  if (isTimelineTechnology) {
    impact = {
      today: calcTodayImpact(),
      extendedSupport: calcExtendedSupportImpact(),
      forcedUpgrade: {
        title: `${eosDate.format('MMM DD, YYYY')} (Forced Upgrade)`,
        subtitle: 'Potential outage/downtime due to compatibility issues and breaking changes.',
      },
    }
  } else {
    if (componentData.extendedSupportAnnualCost != null) {
      impact = `Using this outdated version incurs an annual extended support fee of $${componentData.extendedSupportAnnualCost}/year.`
    } else if (componentData.technology === 'Google CloudSQL MySQL') {
      impact = `Starting ${componentData.dueDate}, this component will be enrolled automatically into extended support, a paid service with hourly costs calculated per core. These fees can add up significantly. To avoid extended support charges and the eventual need for a forced upgrade, it’s essential to act proactively and prepare in advance.`
    } else if (componentData.technology === 'Amazon EKS') {
      impact = `Effective ${componentData.dueDate}, this component will automatically enter extended support, a paid service with costs calculated hourly per core. AWS charges $0.60 per cluster per hour, amounting to approximately $4,380 per year. These expenses can escalate quickly. To avoid incurring extended support fees and the eventual necessity of a forced upgrade, it’s crucial to take proactive steps and prepare ahead of time.`
    } else if (componentData.technology.includes('Amazon MQ')) {
      impact = `When a version reaches end of support, Amazon MQ upgrades all brokers on this version to the next supported version automatically. This upgrade takes place during your broker's scheduled maintenance windows, within the 45 days following the end-of-support date.`
    } else if (componentData.technology === 'Amazon Neptune') {
      impact = `When an engine version reaches its end of life, you will no longer be able to create new clusters or instances using that version, nor will autoscaling be able to create instances using that version.\nAn engine version that actually reaches its end of life will automatically be upgraded during a maintenance window.`
    }
  }

  return (
    <Grid sx={{ p: 2 }}>
      {isTimelineTechnology && (
        <Grid>
          <Typography variant="subtitle2" gutterBottom>
            Impact Timeline
          </Typography>
          <Grid container alignItems={'center'} alignContent={'center'}>
            <Grid container flexDirection="column" alignItems={'flex-start'} width={'100%'}>
              <ImpactAnalysisTimeline eolDate={eolDate.toDate()} eosDate={eosDate.toDate()} />
            </Grid>

            <Grid flexGrow={1} maxWidth={'100%'}>
              <Grid container alignItems="center">
                <Typography variant="subtitle2" gutterBottom>
                  Today:&nbsp;
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {impact?.today}
                </Typography>
              </Grid>

              {impact?.extendedSupport && (
                <Grid container alignItems="center">
                  <Typography variant="subtitle2" gutterBottom>
                    {impact?.extendedSupport?.title}:&nbsp;
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {impact?.extendedSupport?.subtitle}
                  </Typography>
                </Grid>
              )}

              <Grid container alignItems="center">
                <Typography variant="subtitle2" gutterBottom>
                  {impact?.forcedUpgrade?.title}:&nbsp;
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {impact?.forcedUpgrade?.subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isTimelineTechnology && impact?.length && (
        <Grid>
          <Typography variant="subtitle2" gutterBottom>
            Impact
          </Typography>

          <Typography variant="body2" gutterBottom>
            {impact}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default DemoImpactAnalysis
