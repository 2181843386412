import styled from '@emotion/styled'

export const TimelineWrapper = styled.div`
  position: relative;
  width: 90%;
  margin: 10px 10px;
  padding: 15px 0;
  overflow: visible;
`

export const TimelineLabel = styled.div`
  position: absolute;
  padding-top: 10px;
  transform: translateX(-50%);
  font-size: 12px;
  white-space: nowrap;
`
