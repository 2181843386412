import { CircularProgress } from "@mui/material";
import React, { createContext, forwardRef, useContext } from "react";
import { ListOnScrollProps, FixedSizeList } from "react-window";

const OuterElementContext = createContext({});

// Change OuterElementType to render a <ul>
const OuterElementType = forwardRef((props: any, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <ul ref={ref} {...props} {...outerProps} />;
});

interface InfiniteListProps {
  children: React.ReactNode[];
  itemSize?: number;
  listHeight?: number;
  isFetchingNextPage: boolean;
  fetchScrollItemPosition?: number;
  hasNextPage: boolean;
  fetchNextPage: (...args: any[]) => any;
}

/**
 * Component intended to be used as the ListboxComponent prop in MUI Autocomplete.
 * It accepts functions responsible for infinite scrolling.
 */
export const InfiniteListbox = forwardRef<HTMLUListElement, InfiniteListProps & any>(function ListboxComponent(props, ref) {
  const {
    children,
    isFetchingNextPage,
    hasNextPage,
    itemSize: inputSize,
    listHeight: inputHeight,
    fetchScrollItemPosition: inputFetchScrollItemPosition,
    fetchNextPage,
    ...other
  } = props;

  const items = React.Children.toArray(children);
  const itemSize = inputSize ?? 30;
  const maxListHeight = inputHeight || 200;
  const listHeight = Math.min(maxListHeight, items.length * itemSize);
  const itemCount = isFetchingNextPage ? items.length + 1 : items.length;
  const fetchScrollItemPosition = inputFetchScrollItemPosition ?? 10;

  const handleScroll = (input: ListOnScrollProps) => {
    const totalHeight = items.length * itemSize;
    if (input.scrollOffset + listHeight >= totalHeight - itemSize * fetchScrollItemPosition) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <div ref={ref as any} {...other}>
    <FixedSizeList
      height={listHeight}
      width="100%"
      onScroll={handleScroll}
      itemSize={itemSize}
      itemCount={itemCount}
      itemData={items}
      outerElementType={OuterElementType}
      outerRef={ref}
      {...other}
    >
      {({ index, style, data }) => {
        if (isFetchingNextPage && index === data.length) {
          return (
            <li
              style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={20} />
            </li>
          );
        }
        const item = data[index];
        return React.cloneElement(item as React.ReactElement, {
          style: { ...style },
        });
      }}
    </FixedSizeList>
  </div>
  );
});
