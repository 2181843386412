import React, { useEffect, useState } from 'react'
import dic from '../../dictionary'
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { StyledTableHead } from '../policies/policies.style'
import _ from 'lodash'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import { CenteredIconTableCell, IconTableCell, TechnologyIcon } from './catalog.style'
import { ClickableTableRow } from '../../common.css'
import { ROUTES } from '../../consts'
import { useNavigate } from 'react-router-dom'
import EllipsisTableCell from '../commons/ellipsisTableCell'

const Catalog = () => {
  const navigate = useNavigate()
  const [components, setComponents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchComponents = async () => {
      try {
        const response = await getAxiosInstance().get('/component/catalog')
        setComponents(response.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchComponents()
  }, [])

  if (loading) {
    return <Loader />
  }

  const onRowClick = (technologyName: any) => {
    navigate(_.replace(ROUTES.CATALOG.SPECIFIC_TECHNOLOGY, ':technologyName', technologyName))
  }

  return (
    <Grid>
      <h1>
        {dic.catalog.all.title} ({components.length})
      </h1>
      <TableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <IconTableCell></IconTableCell>
              <TableCell>{dic.catalog.all.table.name}</TableCell>
              <TableCell>{dic.catalog.all.table.type}</TableCell>
              <TableCell>{dic.catalog.all.table.versions}</TableCell>
              <TableCell>{dic.catalog.all.table.occurrences}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {_.map(components, (technology) => (
              <ClickableTableRow key={_.get(technology, 'name')} onClick={() => onRowClick(_.get(technology, 'name'))}>
                <CenteredIconTableCell>
                  <TechnologyIcon
                    src={`https://draftt-public.s3.amazonaws.com/icons/${_.get(technology, 'name')}.svg`}
                    alt={_.get(technology, 'name')}
                  />
                </CenteredIconTableCell>
                <EllipsisTableCell value={_.get(technology, 'displayName')} />
                <TableCell>{dic.catalog.all.table.infrastructure}</TableCell>
                <EllipsisTableCell value={_.get(technology, 'versions')} />
                <TableCell>{_.get(technology, 'count')}</TableCell>
              </ClickableTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default Catalog
