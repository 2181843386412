import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { ComponentAttributes, PolicyComponentAttributes } from '@draftt-io/shared-types'
import { Ec2ImageDetails } from './ec2ImageDetails/ec2ImageDetailsV2'

export const Ec2DetailsV2 = ({ component, policyComponent }: {component: ComponentAttributes, policyComponent: PolicyComponentAttributes}) => {
  return (
    <Grid container flexDirection="row" sx={{ mt: 1 }}>
      <Grid container>
        <Typography variant="subtitle2" gutterBottom>
          EC2 Instance Details
        </Typography>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            ID:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.uniqueIdentifier}
          </Typography>
        </Grid>
      </Grid>

      {component.details.instanceLifeCycle && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Lifecycle:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {component.details.instanceLifeCycle}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Type:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.instanceType}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            State:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {component.details.instanceState}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Launch Time:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(component.details.instanceLaunchTime).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      <Ec2ImageDetails component={component} policyComponent={policyComponent}  />
    </Grid>
  )
}
