import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const IntegrationIcon = styled.img`
  height: 35px;
  margin-left: 10px;
`
export const IntegrationSummaryCardContainer = muiStyled(Grid)`
  align-content: center;
  display: grid;
  place-items: center;
  margin: 0 8px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`
