export const CODE_EDITOR_THEMES = {
  agate: 'agate',
  'an-old-hope': 'an-old-hope',
  androidstudio: 'androidstudio',
  'arduino-light': 'arduino-light',
  arta: 'arta',
  ascetic: 'ascetic',
  'atom-one-dark-reasonable': 'atom-one-dark-reasonable',
  'atom-one-dark': 'atom-one-dark',
  'atom-one-light': 'atom-one-light',
  'brown-paper': 'brown-paper',
  'codepen-embed': 'codepen-embed',
  'color-brewer': 'color-brewer',
  dark: 'dark',
  default: 'default',
  devibeans: 'devibeans',
  docco: 'docco',
  far: 'far',
  felipec: 'felipec',
  foundation: 'foundation',
  'github-dark-dimmed': 'github-dark-dimmed',
  'github-dark': 'github-dark',
  github: 'github',
  gml: 'gml',
  googlecode: 'googlecode',
  'gradient-dark': 'gradient-dark',
  'gradient-light': 'gradient-light',
  grayscale: 'grayscale',
  hybrid: 'hybrid',
  idea: 'idea',
  'intellij-light': 'intellij-light',
  'ir-black': 'ir-black',
  'isbl-editor-dark': 'isbl-editor-dark',
  'isbl-editor-light': 'isbl-editor-light',
  'kimbie-dark': 'kimbie-dark',
  'kimbie-light': 'kimbie-light',
  lightfair: 'lightfair',
  lioshi: 'lioshi',
  magula: 'magula',
  'mono-blue': 'mono-blue',
  'monokai-sublime': 'monokai-sublime',
  monokai: 'monokai',
  'night-owl': 'night-owl',
  'nnfx-dark': 'nnfx-dark',
  'nnfx-light': 'nnfx-light',
  nord: 'nord',
  obsidian: 'obsidian',
  'panda-syntax-dark': 'panda-syntax-dark',
  'panda-syntax-light': 'panda-syntax-light',
  'paraiso-dark': 'paraiso-dark',
  'paraiso-light': 'paraiso-light',
  pojoaque: 'pojoaque',
  purebasic: 'purebasic',
  'qtcreator-dark': 'qtcreator-dark',
  'qtcreator-light': 'qtcreator-light',
  rainbow: 'rainbow',
  routeros: 'routeros',
  'school-book': 'school-book',
  'shades-of-purple': 'shades-of-purple',
  srcery: 'srcery',
  'stackoverflow-dark': 'stackoverflow-dark',
  'stackoverflow-light': 'stackoverflow-light',
  sunburst: 'sunburst',
  'tokyo-night-dark': 'tokyo-night-dark',
  'tokyo-night-light': 'tokyo-night-light',
  'tomorrow-night-blue': 'tomorrow-night-blue',
  'tomorrow-night-bright': 'tomorrow-night-bright',
  vs: 'vs',
  vs2015: 'vs2015',
  xcode: 'xcode',
  xt256: 'xt256',
} as const

export const CodeEditorThemes = Object.values(CODE_EDITOR_THEMES)

export type CodeEditorTheme = (typeof CodeEditorThemes)[number]
