import { useState, useEffect } from 'react'
import { GridFilterModel, GridFilterItem, GridLogicOperator } from '@mui/x-data-grid'
import { getAxiosInstance } from '../../axiosInstance'
import { find, map, sortBy } from 'lodash'
import Loader from '../loader'
import { Grid } from '@mui/material'
import { TopBarV2 } from './components/topBar/topBarV2'
import { DrafttQLInput } from '@draftt-io/drafttql-syntax'
import { useQuery } from '@tanstack/react-query'
import { ComponentAttributes, IntegrationAttributes, IntegrationType, PolicyComponentAttributes, PolicyComponentStatuses, TagAttributes } from '@draftt-io/shared-types'
import {
  BaseFilter,
  DotNotationPaths,
  DrafttQLAggregateCountResponse,
  DrafttQLCountResponse,
  DrafttQLResponse,
  ResourceAttributes,
} from '@draftt-io/drafttql-syntax/dist/DrafttQLTypes'
import { ComponentsTableV2 } from './components/componentsTable/componentsTableV2'
import { FiltersSidebarV2 } from './components/filtersSidebar/filtersSidebarV2'
import { FutureTimelineV2V2 } from './components/futureTimeline/futureTimelineV2V2'
import { GridFilterOperation, gridFilterToDrafttQLOperator } from '../../helpers/helpers'

const FILTERS_SIDEBAR_WIDTH = '220px'
export interface InventoryTableData {
  id: number
  technology: string
  name: string
  score: number
  status: string
  isCompliant: boolean
  currentVersion: string
  componentId: ComponentAttributes['id']
  requiredVersion: string
  recommendedVersion: string
  dueDate: string
  lastUpdate: string
  integrationName: string
  integrationType: IntegrationType
  extendedSupportAnnualCost: number
  tags: string[]
  drafttDetails: {
    id: number | undefined
    desiredVersion: string | undefined
  }
}

export type FiltersIntegration = Pick<IntegrationAttributes, 'id' | 'name' | 'type'>

export interface InventoryFilters {
  statuses: PolicyComponentAttributes['status'][]
  policy: PolicyComponentAttributes['policyId']
  technologies: PolicyComponentAttributes['technology'][]
  tags: Pick<TagAttributes, 'key' | 'value'>[]
  integrationTypes: IntegrationType[]
  integrations: FiltersIntegration[]
  dueDate?: string
  extendedSupport?: boolean
  drafttAvailable?: boolean
}

export type IntegrationNamesByType = Partial<Record<IntegrationType, Set<string>>>
export interface InventoryPolicy {
  id: number | bigint
  name: string
}
export type InventoryQuery = DrafttQLInput<PolicyComponentAttributes, ComponentAttributes>
export async function executeDrafttQLQuery<T extends ResourceAttributes, J extends ResourceAttributes | undefined = undefined>(
  query: DrafttQLInput<T, J>,
) {
  const result = await getAxiosInstance().post('/query', { query })
  return result.data as DrafttQLResponse<T, J>
}
export async function countDrafttQLQuery<T extends ResourceAttributes, J extends ResourceAttributes | undefined = undefined>(
  query: DrafttQLInput<T, J>,
) {
  const result = await getAxiosInstance().post('/query/count', { query })
  return result.data as DrafttQLCountResponse
}
export async function aggregateCountDrafttQLQuery<T extends ResourceAttributes, J extends ResourceAttributes | undefined = undefined>(
  query: DrafttQLInput<T, J>,
  aggregateField: DotNotationPaths<T>,
) {
  const result = await getAxiosInstance().post('/query/aggregateCountByField', { query, aggregateField })
  return result.data as DrafttQLAggregateCountResponse
}
export const emptyFilters = () => ({
  policy: BigInt(1),
  statuses: [],
  technologies: [],
  integrationTypes: [],
  integrations: [],
  tags: [],
})
export function defaultDrafttQLInventoryQuery(policyId: bigint): DrafttQLInput<PolicyComponentAttributes, ComponentAttributes> {
  const query: DrafttQLInput<PolicyComponentAttributes, ComponentAttributes> = {
    resource: 'policyComponent',
    context: { policyId },
    join: {
      relation: { local: 'componentId', foreign: 'id' },
      query: { resource: 'component' },
    },
  }
  return query
}
const DEFAULT_POLICY: InventoryPolicy = { id: 1, name: 'End of Life' }
export const InventoryV2 = () => {
  const [filters, setFilters] = useState<InventoryFilters>(emptyFilters())

  const [dataGridFilterModel, setDataGridFilterModel] = useState<GridFilterModel>({
    items: [] as GridFilterItem[],
  })
  const [selectedPolicy, setSelectedPolicy] = useState<InventoryPolicy>(DEFAULT_POLICY)
  const [policies, setPolicies] = useState<InventoryPolicy[]>([])
  const [loading, setLoading] = useState(true)
  const [activeDrafttQLQuery, setActiveDrafttQLQuery] = useState(defaultDrafttQLInventoryQuery(selectedPolicy.id as bigint))

  const { data: count } = useQuery({
    queryKey: ['count', activeDrafttQLQuery],
    queryFn: async () => {
      const res = await countDrafttQLQuery(activeDrafttQLQuery)
      return res.count
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30,
  })

  useEffect(() => {
    const fetchPolicies = async () => {
      setLoading(true)
      const response = await getAxiosInstance().post('/v1/policy/search')

      const parsedPolicies = sortBy(
        map(response, (policy) => ({
          id: policy.id,
          name: policy.name,
        })),
        'id',
      )

      const defaultPolicy = parsedPolicies.find((policy) => policy.id === DEFAULT_POLICY.id)

      setPolicies(parsedPolicies)
      setSelectedPolicy(defaultPolicy ?? parsedPolicies[0])
      setLoading(false)
    }

    fetchPolicies()
  }, [])

  useEffect(() => {
    const applyFilters = () => {
      const query = defaultDrafttQLInventoryQuery(selectedPolicy.id as bigint)
      query.filter = {}
      query.join!.query.filter = {}
      const logicalOperator = dataGridFilterModel.logicOperator === GridLogicOperator.Or ? '$or' : '$and'
      const dataGridFilters = dataGridFilterModel.items.reduce((acc, filter) => {
        const operator = gridFilterToDrafttQLOperator(
          filter.field as DotNotationPaths<PolicyComponentAttributes>,
          filter.value,
          filter.operator as GridFilterOperation,
        )
        if (operator) {
          acc.push(operator)
        }
        return acc
      }, [] as BaseFilter<PolicyComponentAttributes>[])
      if (dataGridFilters.length > 0) {
        query.filter[logicalOperator] = dataGridFilters
      }
      if (filters.statuses?.length) {
        query.filter['status'] = { $in: filters.statuses }
      }
      if (filters.technologies?.length) {
        query.filter['technology'] = { $in: filters.technologies }
      }
      if (filters.tags?.length) {
        filters.tags.forEach((tag) => {
          query.join!.query.filter![`tags$.${tag.key}`] = tag.value
        })
      }

      if (filters.integrationTypes?.length) {
        query.join!.query.filter['vendor'] = { $in: filters.integrationTypes }
      }
      if (filters.integrations?.length) {
        query.join!.query.filter['integrationId'] = { $in: filters.integrations.map((integration) => integration.id) }
      }

      if (filters.extendedSupport) {
        query.filter['extendedSupportAnnualCost'] = { $exists: true }
        query.filter['status'] = { $ne: PolicyComponentStatuses.Supported }
      }
      if (filters.drafttAvailable) {
        query.join!.query.filter.hasAvailableDraftts = { $eq: true }
      }

      if (filters.dueDate) {
        query.filter['dueDate'] = { $eq: filters.dueDate }
      }
      setActiveDrafttQLQuery(query)
    }

    applyFilters()
  }, [filters, dataGridFilterModel, selectedPolicy])

  const handleTagClick = (tag: Pick<TagAttributes, 'key' | 'value'>) => {
    setFilters((prev) => ({
      ...prev,
      tags: prev.tags.find((existingTag) => existingTag.key === tag.key && existingTag.value && tag.value)
        ? prev.tags.filter((t) => t.key !== tag.key && t.value !== tag.value)
        : [...prev.tags, tag],
    }))
  }
  if (loading) {
    return <Loader />
  }
  return (
    <Grid container flexDirection="row">
      <Grid item sx={{ width: `${FILTERS_SIDEBAR_WIDTH}` }}>
        <FiltersSidebarV2
          policies={policies}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          filters={filters}
          setDataGridFilterModel={setDataGridFilterModel}
          setFilters={setFilters}
        />
      </Grid>
      <Grid item sx={{ width: `calc(100% - ${FILTERS_SIDEBAR_WIDTH})` }}>
        <TopBarV2 filtersData={{ setFilters }} activeQuery={activeDrafttQLQuery} totalPolicyComponentCount={count || 0} />
        <FutureTimelineV2V2 activeQuery={activeDrafttQLQuery} filtersData={{ setFilters, filters }} />
        <ComponentsTableV2
          totalItemsCount={count || 0}
          filters={filters}
          onTagClick={handleTagClick}
          filterModel={dataGridFilterModel}
          onFilterModelChange={setDataGridFilterModel}
          activeQuery={activeDrafttQLQuery}
        />
      </Grid>
    </Grid>
  )
}
