import { Paper, Grid, Typography } from '@mui/material'
import React from 'react'

const DemoMetricSummaryCard = (props: any) => {
  return (
    <Grid item>
      <Paper sx={{ p: '20px' }} variant="outlined">
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid container alignItems="center" justifyContent="center">
            <Grid  container flexDirection={'column'} sx={{ minWidth: '120px', alignItems: 'center' }}>
              <Typography variant="h6">{props.metric.value}</Typography>
              <Typography variant="body2">{props.metric.title}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default DemoMetricSummaryCard
