import styled from '@emotion/styled'

export const DemoMetricsInsightsSummaryCardContainer = styled.div`
  align-content: center;
  display: grid;
  place-items: center;
  padding: 20px;
  margin: 0 8px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`
