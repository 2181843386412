import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { INTEGRATIONS, ROUTES } from '../../../consts'
import AwsIntegrationInstructions from './instructions/aws'
import K8sExplorerIntegrationInstructions from './instructions/k8sExplorer'
import MongodbAtlasIntegrationInstructions from './instructions/mongodbAtlas'
import AzureIntegrationInstructions from './instructions/azure'
import { GcpIntegrationInstructions } from './instructions/gcp'

const componentMap: Record<any, any> = {
  [INTEGRATIONS.aws.type]: AwsIntegrationInstructions,
  [INTEGRATIONS.gcp.type]: GcpIntegrationInstructions,
  [INTEGRATIONS.k8sExplorer.type]: K8sExplorerIntegrationInstructions,
  [INTEGRATIONS.mongodbAtlas.type]: MongodbAtlasIntegrationInstructions,
  [INTEGRATIONS.azure.type]: AzureIntegrationInstructions,
}

const IntegrationsInstructions = () => {
  const { type } = useParams()
  const navigate = useNavigate()
  let ComponentToRender: any = null

  if (type) {
    ComponentToRender = componentMap[type]
  }

  if (!ComponentToRender) {
    navigate(ROUTES.INTEGRATIONS.ALL)
  }

  return <ComponentToRender />
}

export default IntegrationsInstructions
