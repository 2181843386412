import React, { useEffect, useRef } from 'react'
import hljs from 'highlight.js'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CODE_EDITOR_THEMES } from './CodeViewerConstants'
import { useNotification } from '../notification/notificationProvider'
import { CodeViewerContainer } from './CodeViewer.style'

interface CodeViewerProps {
  code: string
  theme?: string
  language: string
  showCopyButton?: boolean
  fontSize?: string | number
  size?: 'normal' | 'small'
  borderRadius?: string | number
  style?: React.CSSProperties
}

export const CodeViewer: React.FC<CodeViewerProps> = ({
  code,
  language,
  theme = CODE_EDITOR_THEMES['atom-one-light'],
  showCopyButton = true,
  fontSize = 14,
  size = 'normal',
  borderRadius = '15px',
  style,
}) => {
  const codeRef = useRef<HTMLElement>(null)
  const { showNotification } = useNotification()

  useEffect(() => {
    const linkId = 'hljs-theme'
    let linkElement = document.getElementById(linkId) as HTMLLinkElement | null
    const themeUrl = `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.7.0/styles/${theme}.min.css`

    if (linkElement) {
      if (linkElement.href !== themeUrl) {
        linkElement.href = themeUrl
      }
    } else {
      linkElement = document.createElement('link')
      linkElement.id = linkId
      linkElement.rel = 'stylesheet'
      linkElement.href = themeUrl
      document.head.appendChild(linkElement)
    }
  }, [theme])

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.removeAttribute('data-highlighted')
      hljs.highlightElement(codeRef.current)
    }
  }, [code, language])

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code)
      showNotification('Code copied to clipboard')
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  const codeBlockStyle: React.CSSProperties = {
    userSelect: 'text',
    pointerEvents: 'auto',
    margin: 0,
    fontSize: `${fontSize}px`,
    borderRadius: `calc(${borderRadius} + 0px)` ?? '15px',
    padding: size === 'small' ? '5px 10px' : '15px',
  }

  return (
    <CodeViewerContainer
      className={`hljs-comment`}
      style={{
        ...style,
        padding: 0,
        fontSize: codeBlockStyle.fontSize,
        borderRadius: codeBlockStyle.borderRadius,
      }}
    >
      {showCopyButton && (
        <IconButton
          onClick={handleCopy}
          style={{
            position: 'absolute',
            padding: '5px',
            fontSize: `calc(${fontSize}px - 4px)`,
            zIndex: 1,
            backgroundColor: 'transparent',
            ...(size === 'small'
              ? {
                  top: '50%',
                  right: '1%',
                  transform: 'translateY(-50%)',
                }
              : {
                  top: '2%',
                  right: '1%',
                  transform: 'translateX(+15%)',
                }),
          }}
        >
          <ContentCopyIcon style={{ fontSize: `calc(${fontSize}px + 2px)` }} />
        </IconButton>
      )}
      <pre style={{ margin: 0 }}>
        <code ref={codeRef} className={language} style={codeBlockStyle}>
          {code}
        </code>
      </pre>
    </CodeViewerContainer>
  )
}
