import React from 'react'
import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import App from './app'
import { AuthProvider } from '@descope/react-sdk'
import config from './config'
import { NotificationProvider } from './components/commons/notification/notificationProvider'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey(config.muiLicenseKey)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          textTransform: 'none', // Removes uppercase styling
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4E3CAE',
      dark: '#342E88',
      light: '#ECEEFB',
    },
    secondary: {
      main: '#4D5358',
      light: '#697077',
    },
    background: {
      default: '#FBFCFF',
    },
  },
})

root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider projectId={config.descope.projectId}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
)

// reportWebVitals();
