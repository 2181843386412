import { Dispatch, FC, Fragment, SetStateAction, useState } from 'react'
import { Grid, Link, TextField, Typography } from '@mui/material'
import { CodeViewer, FileDropzone } from '../../../../../../commons'
import config from '../../../../../../../config'
import dic from '../../../../../../../dictionary'
import { IntegrationAttributes } from '@draftt-io/shared-types'
import { CodeBlockContainer } from './GCPWorkloadIdentiyFederationStyles.style'

interface GCPWorkloadIdentityFederationCliProps {
  setJsonPayload: Dispatch<SetStateAction<IntegrationAttributes | null>>
}

export const GCPWorkloadIdentityFederationCli: FC<GCPWorkloadIdentityFederationCliProps> = ({ setJsonPayload }) => {
  const gcpWifCliInstructionsDict = dic.integrations.instructions.gcp.wif.cli

  const [projectId, setProjectId] = useState<string | null>('')
  const [projectNumber, setProjectNumber] = useState<string | null>('')

  const handleProjectIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectId(event.target.value)
  }
  const handleProjectNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectNumber(event.target.value)
  }
  const handleJsonPayloadChange = (payload: Record<string, any> | null) => {
    setJsonPayload(payload as IntegrationAttributes)
  }

  const replaceCodenames = (code: string) => {
    return code
      .replaceAll('$PROJECT_ID', projectId?.length ? projectId : `$PROJECT_ID`)
      .replaceAll('$PROJECT_NUMBER', projectNumber?.length ? projectNumber : `$PROJECT_NUMBER`)
      .replaceAll('$AWS_ACCOUNT_ID', config.awsAccountId)
  }

  return (
    <>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.connect}</Typography>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_1.title}</h3>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>
          <TextField
            variant="standard"
            size="small"
            label="Project ID"
            value={projectId}
            onChange={handleProjectIdChange}
            placeholder="my-project-id"
            style={{ minWidth: '120px' }}
          />
          <TextField
            variant="standard"
            size="small"
            label="Project Number"
            value={projectNumber}
            onChange={handleProjectNumberChange}
            placeholder="1234567890"
            style={{ minWidth: '120px' }}
          />
        </div>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_2.title}</h3>
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_2.description}</Typography>

        <CodeBlockContainer flexDirection="column">
          <CodeViewer
            language={'bash'}
            code={`gcloud services enable \\
    cloudresourcemanager.googleapis.com \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}"`}
          />

          <CodeViewer
            language={'bash'}
            code={`gcloud services enable \\
    iamcredentials.googleapis.com \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_3.title}</h3>
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_3.description}</Typography>

        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud iam workload-identity-pools create \\
    "draftt-wif-integration" \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}" \\
    --location "global" \\
    --display-name "Draftt WIF integration"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_4.title}</h3>
        <Typography variant="subtitle1">{replaceCodenames(gcpWifCliInstructionsDict.step_4.description)}</Typography>
        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud iam workload-identity-pools providers create-aws \\
    "draftt-wif-aws-integration" \\
    --workload-identity-pool="draftt-wif-integration" \\
    --account-id="${config.awsAccountId}" \\
    --attribute-mapping="attribute.aws_role=assertion.arn.contains('assumed-role') ? \\
      assertion.arn.extract('{account_arn}assumed-role/') + 'assumed-role/' + \\
      assertion.arn.extract('assumed-role/{role_name}/') : assertion.arn, \\
      google.subject=assertion.arn" \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}" \\
    --location="global"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_5.title}</h3>
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_5.description}</Typography>
        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud iam service-accounts create \\
    "draftt-wif-int-readonly" \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}" \\
    --display-name="Draftt WIF ReadOnly Integration"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_6.title}</h3>
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_6.description}</Typography>
        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud projects add-iam-policy-binding \\
    "${projectId?.length ? projectId : '$PROJECT_ID'}" \\
    --member="serviceAccount:draftt-wif-int-readonly@${projectId?.length ? projectId : '$PROJECT_ID'}.iam.gserviceaccount.com" \\
    --role="roles/viewer" \\
    --project="${projectId?.length ? projectId : '$PROJECT_ID'}"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_7.title}</h3>
        <Typography variant="subtitle1">{replaceCodenames(gcpWifCliInstructionsDict.step_7.description)}</Typography>
        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud iam service-accounts add-iam-policy-binding \\
  "draftt-wif-int-readonly@${projectId?.length ? projectId : '$PROJECT_ID'}.iam.gserviceaccount.com" \\
  --role="roles/iam.workloadIdentityUser" \\
  --member="principalSet://iam.googleapis.com/projects/${projectNumber?.length ? projectNumber : '$PROJECT_NUMBER'}/locations/global/workloadIdentityPools/draftt-wif-integration/attribute.aws_role/arn:aws:sts::${config.awsAccountId}:assumed-role/draftt-fetcher" \\
  --project="${projectId?.length ? projectId : '$PROJECT_ID'}"`}
          />
        </CodeBlockContainer>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_8.title}</h3>
        <Typography variant="subtitle1">{gcpWifCliInstructionsDict.step_8.description}</Typography>
        <CodeBlockContainer>
          <CodeViewer
            language={'bash'}
            code={`gcloud iam workload-identity-pools create-cred-config \\
  --aws "projects/${projectNumber?.length ? projectNumber : '$PROJECT_NUMBER'}/locations/global/workloadIdentityPools/draftt-wif-integration/providers/draftt-wif-aws-integration" \\
  --service-account="draftt-wif-int-readonly@${projectId?.length ? projectId : '$PROJECT_ID'}.iam.gserviceaccount.com" \\
  --output-file="draftt_gcp_wiff_integration_credentials.json" \\
  --project="${projectId?.length ? projectId : '$PROJECT_ID'}"`}
          />
        </CodeBlockContainer>
        <Typography variant="subtitle1" style={{ marginTop: '16px', fontStyle: 'italic', fontSize: '14px' }}>
          {gcpWifCliInstructionsDict.step_8.note.link
            ? gcpWifCliInstructionsDict.step_8.note.text.split('$LINK').map((text, index, arr) => (
                <Fragment key={index}>
                  {text}
                  {index < arr.length - 1 && (
                    <Link target="blank" href={gcpWifCliInstructionsDict.step_8.note.link.href}>
                      {gcpWifCliInstructionsDict.step_8.note.link.text}
                    </Link>
                  )}
                </Fragment>
              ))
            : gcpWifCliInstructionsDict.step_8.note.text}
        </Typography>
      </Grid>
      <Grid container flexDirection="column" alignItems="start" sx={{ marginBottom: '16px' }}>
        <h3>{gcpWifCliInstructionsDict.step_9.title}</h3>
        <FileDropzone onFileUpload={handleJsonPayloadChange} accepts={{ 'application/json': ['.json'] }} />
      </Grid>
    </>
  )
}
