import { FC } from 'react'
import { ImpactAnalysisTimelineBaselineDot } from './ImpactAnalysisBaselineDot'
import { ImpactAnalysisTimelineBaselineLine } from './ImpactAnalysisBaselineLine'
import { ImpactAnalysisTimelineBaselineContainer, ImpactAnalysisTimelineBaselineTodayBar } from './ImpactAnalysisTimelineBaselineStyles'

export interface ImpactAnalysisTimelineBaselineProps {
  eol: number
  eos: number
  today: number
  eosDate: Date
  eolDate: Date
  equalDates: boolean
}

export const ImpactAnalysisTimelineBaseline: FC<ImpactAnalysisTimelineBaselineProps> = ({ eol, eos, today, eosDate, eolDate, equalDates }) => {

  return (
    <ImpactAnalysisTimelineBaselineContainer>
      <ImpactAnalysisTimelineBaselineLine positionStart={0} positionEnd={eol} color={'#2F7C31'} />
      <ImpactAnalysisTimelineBaselineLine positionStart={eol} positionEnd={eos} color={'#f77a14'} />
      <ImpactAnalysisTimelineBaselineLine positionStart={eos} positionEnd={today < eos ? eos : today + 0.01} color={'#f70202'} />

      <ImpactAnalysisTimelineBaselineDot position={eol} color={'#f77a14'} date={eolDate} status="Extended Support Period" />
      <ImpactAnalysisTimelineBaselineDot position={eos} color={'#f70202'} date={eosDate} status="Force Upgrade" />

      <ImpactAnalysisTimelineBaselineTodayBar
        style={{
          left: `calc(${(today * 100).toFixed(2)}%)`,
          display: equalDates || today === eos ? 'none' : 'block',
        }}
      />
    </ImpactAnalysisTimelineBaselineContainer>
  )
}
