import { Route, Routes, Navigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from './consts'
import Policies from './components/policies'
import Policy from './components/policy'
import Grid from '@mui/material/Grid'
import Sidebar from './components/sidebar'
import { AppGrid, MainContentGrid } from './common.css'
import PageLoader from './components/loader'
import { useInitializeAxiosWithAuth } from './axiosInstance'
import Catalog from './components/catalog'
import TechnologyCatalog from './components/catalog/technologies/'
import { useSession, useUser } from '@descope/react-sdk'
import { Descope } from '@descope/react-sdk'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import AccessKeys from './components/settings/accessKeys'
import ActiveDraftts from './components/draftts'
import Draftt from './components/draftts/drafttDetails'
import Integrations from './components/integrations'
import IntegrationsByType from './components/integrations/integrationsByType'
import IntegrationsInstructions from './components/integrations/integrationsInstructions'
import AccountSettings from './components/settings/account'
import Dashboard from './components/dashboard'
import UserManagement from './components/settings/userManagement'
import EcrRepoView from './components/catalog/technologies/ecr/repoView'
import Certificates from './components/certificate'
import Inventory from './components/inventory'
import AwsMarketplaceSignup from './components/signup/awsSignup'
import { init, setUserId, Identify, identify } from '@amplitude/analytics-browser'
import config from './config'
import { get } from 'lodash'
import { useEffect } from 'react'
import { defaultInventoryV2, isInternalEmployee } from './helpers/helpers'
import { InventoryV2 } from './components/inventoryV2/inventoryV2'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

dayjs.extend(relativeTime)

const App = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession()
  const { user, isUserLoading } = useUser()
  const [queryParams, setQueryParams] = useSearchParams()
  const showInventoryV2 = defaultInventoryV2(user?.userTenants?.[0]?.tenantId as string)
  useInitializeAxiosWithAuth()
  const queryClient= new QueryClient({defaultOptions: {queries: {refetchOnMount: false}}})
  useEffect(() => {
    if (!isUserLoading && isAuthenticated && !isInternalEmployee(user.email, sessionToken)) {
      // const sessionReplayTracking = sessionReplayPlugin({
      //   sampleRate: 1,
      //   privacyConfig: {
      //     defaultMaskLevel: 'medium',
      //   },
      // })

      init(config.amplitudeApiKey, {
        autocapture: {
          attribution: true,
          pageViews: true,
          sessions: true,
          formInteractions: true,
          fileDownloads: true,
          elementInteractions: true,
        },
      })
      // add(sessionReplay/Tracking)
      setUserId(user.email)
      const identityObject = new Identify()
      identityObject.set('name', user.name || '')
      identityObject.set('email', user.email || '')
      identityObject.set('organizationName', get(user, 'userTenants[0].tenantName', ''))
      identify(identityObject)
    }
  }, [isAuthenticated, isUserLoading])

  if (!isAuthenticated) {
    if (queryParams.get('alreadyRegisteredAws') && queryParams.get('alreadyRegisteredAws') === 'true') {
      return (
        <Grid container justifyContent="center">
          <Descope
            flowId="aws-marketplace-already-signed-up"
            onSuccess={(e) => {
              setQueryParams()
            }}
          />
        </Grid>
      )
    } else if (queryParams.get('awsMarketplaceToken')) {
      return (
        <Grid container justifyContent="center">
          <AwsMarketplaceSignup awsMarketplaceToken={queryParams.get('awsMarketplaceToken') as string} />
        </Grid>
      )
    } else {
      return (
        <Grid container justifyContent="center">
          <Descope flowId="sign-in" onError={(e) => console.log('Could not log in!')} />
        </Grid>
      )
    }
  }

  if (isSessionLoading || isUserLoading) {
    return <PageLoader />
  }

  if (!isUserLoading && isAuthenticated) {
    return (
      <QueryClientProvider client={queryClient}>
      <AppGrid>
        <Sidebar />
        <MainContentGrid>
          <Routes>
            <Route path={ROUTES.POLICIES.ALL} element={<Policies />} />
            <Route path={ROUTES.POLICIES.SINGLE} element={<Policy />} />
            <Route path={ROUTES.CATALOG.ALL} element={<Catalog />} />
            <Route path={ROUTES.CATALOG.SPECIFIC_TECHNOLOGY} element={<TechnologyCatalog />} />
            <Route path={ROUTES.CATALOG.ECR_REPO} element={<EcrRepoView />} />
            <Route path={ROUTES.SETTINGS.ACCESS_KEYS} element={<AccessKeys />} />
            <Route path={ROUTES.SETTINGS.USER_MANAGEMENT} element={<UserManagement />} />
            <Route path={ROUTES.DRAFTTS.ALL} element={<ActiveDraftts />} />
            <Route path={ROUTES.DRAFTTS.DETAILS} element={<Draftt />} />
            <Route path={ROUTES.INTEGRATIONS.ALL} element={<Integrations />} />
            <Route path={ROUTES.INTEGRATIONS.BY_TYPE} element={<IntegrationsByType />} />
            <Route path={ROUTES.INTEGRATIONS.CREATE} element={<IntegrationsInstructions />} />
            <Route path={ROUTES.SETTINGS.ACCOUNT} element={<AccountSettings />} />
            <Route path={ROUTES.CERTIFICATES.ALL} element={<Certificates />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.INVENTORY.ALL} element={
              showInventoryV2  ? 
              <InventoryV2 />
               : <Inventory />
              } />
            {isInternalEmployee(user.email, sessionToken) && <Route path={ROUTES.INVENTORY_V2.ALL} element={<InventoryV2 />} />}
            <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
          </Routes>
        </MainContentGrid>
      </AppGrid>
      </QueryClientProvider>
    )
  }

  return <PageLoader />
}

export default App
