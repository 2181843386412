import React from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid, Paper, Typography } from '@mui/material'
import { DEFAULT_POLICIES_IDS } from '../../../../consts'
import { ChartsLegend, PiePlot, ResponsiveChartContainer, ChartsTooltip } from '@mui/x-charts'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

const EolPieChart = (props: any) => {
  const eolPolicyComponent = _.filter(props.policyComponents, { policyId: DEFAULT_POLICIES_IDS.EOL })
  const eolPolicyCompliantComponents = _.filter(eolPolicyComponent, { status: PolicyComponentStatuses.Supported })
  const eolPolicyImpendingComponents = _.filter(eolPolicyComponent, { status: PolicyComponentStatuses.Impending })
  const eolPolicyComponentsViolations = _.filter(eolPolicyComponent, { status: PolicyComponentStatuses.Outdated })

  const graphData = [
    { label: PolicyComponentStatuses.Supported, value: eolPolicyCompliantComponents.length, color: '#34A853' },
    { label: PolicyComponentStatuses.Impending, value: eolPolicyImpendingComponents.length, color: '#FFA500' },
    { label: PolicyComponentStatuses.Outdated, value: eolPolicyComponentsViolations.length, color: '#EA4335' },
  ]

  return (
    <Paper variant="outlined" sx={{ p: '16px', mr: '8px' }}>
      <Typography variant="subtitle1">{dic.dashboard.eolChart.title}</Typography>
      <Grid item sx={{ height: '215px' }}>
        <ResponsiveChartContainer
          margin={{ right: 0, left: 120 }}
          series={[
            {
              type: 'pie',
              data: graphData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
        >
          <PiePlot />
          <ChartsTooltip trigger="item" />
          <ChartsLegend
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'left' },
                itemMarkWidth: 5,
                itemMarkHeight: 5,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
          />
        </ResponsiveChartContainer>
      </Grid>
    </Paper>
  )
}

export default EolPieChart
