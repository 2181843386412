import { FC } from 'react'
import { ImpactAnalysisTimelineBaseline } from './components'
import styles from './ImpactAnalysisTimeline.module.css'

export interface ImpactAnalysisTimelineProps {
  eolDate: Date
  eosDate: Date
}

export const ImpactAnalysisTimeline: FC<ImpactAnalysisTimelineProps> = ({ eolDate, eosDate }) => {
  const todayMs = Date.now()
  const eolMs = eolDate.getTime()
  const eosMs = eosDate.getTime()

  const startMs = Math.min(todayMs, eolMs)
  const extendedRangeMs = Math.max(eosMs, todayMs) - startMs

  const fractionEOS = (eosMs - startMs) / extendedRangeMs
  const fractionToday = Math.min(Math.max((todayMs - startMs) / extendedRangeMs, 0.05), 1)
  const totalRange = eosMs - startMs

  let fractionEOL_raw = (eolMs - startMs) / totalRange
  if (fractionEOL_raw < 0.15) fractionEOL_raw = 0.15
  if (fractionEOL_raw > 1) fractionEOL_raw = 1

  const fractionEOL = fractionEOL_raw * fractionEOS

  const formatMarkerDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    })
  }

  return (
    <div className={styles.wrapper}>
      <ImpactAnalysisTimelineBaseline eol={fractionEOL} eos={fractionEOS} today={fractionToday} eolDate={eolDate} eosDate={eosDate} />

      <div className={styles.label} style={{ left: `${fractionEOL * 100}%` }}>
        {formatMarkerDate(eolDate)}
      </div>
      <div className={styles.label} style={{ left: `${(fractionEOS * 100).toFixed(2)}%` }}>
        {formatMarkerDate(eosDate)}
      </div>
      <div className={styles.label} style={{ left: `${(fractionToday * 100).toFixed(2)}%` }}>
        Today
      </div>
    </div>
  )
}
