import React from 'react'
import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import SourceImageDetails from './sourceImageDetails'

const Ec2ImageDetails = ({ componentData }: any) => {
  const hasSourceAmi = _.get(componentData, 'details.amiChain', []).length > 0

  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid container>
        <Typography variant="subtitle2" gutterBottom>
          Image Details
        </Typography>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            ID:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imageId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Architecture:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imageArchitecture}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Creation Time:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.details.imageCreationDate).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      {componentData.details.imageDeprecationTime && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Deprecation Date:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {dayjs(componentData.details.imageDeprecationTime).format('MMM D, YYYY, h:mm A')}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Platform:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imagePlatformDetails}
          </Typography>
        </Grid>
      </Grid>

      {hasSourceAmi && <SourceImageDetails componentData={componentData} />}
    </Grid>
  )
}

export default Ec2ImageDetails
