import React, { forwardRef, useState } from 'react'
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  Autocomplete,
  TextField,
  Tooltip,
  Divider,
} from '@mui/material'
import { emptyFilters, IntegrationNamesByType, InventoryFilters, InventoryPolicy } from '../..'
import _, { uniq } from 'lodash'
import { Clear, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { FilteredChip } from './filtersSidebar.style'
import { PolicyComponentStatuses } from '@draftt-io/shared-types'

//TODO: move this to a shared library with db-client
export const integrationTypesToCloudProviders = {
  aws: 'AWS',
  gcp: 'GCP',
  azure: 'Azure',
  'mongodb-atlas': 'MongoDB',
} as const

export type IntegrationType = keyof typeof integrationTypesToCloudProviders
interface SidebarProps {
  filters: InventoryFilters
  setFilters: React.Dispatch<React.SetStateAction<InventoryFilters>>
  policies: InventoryPolicy[]
  selectedPolicy: InventoryPolicy
  setSelectedPolicy: React.Dispatch<React.SetStateAction<InventoryPolicy>>
  tags: { tag: string; amount: number }[]
  technologies: string[]
  integrationTypes: IntegrationType[]
  integrationNamesMap: IntegrationNamesByType
}

const FiltersSidebar: React.FC<SidebarProps> = ({
  policies,
  selectedPolicy,
  setSelectedPolicy,
  filters,
  setFilters,
  tags,
  technologies,
  integrationTypes,
  integrationNamesMap,
}) => {
  const [statusExpanded, setStatusExpanded] = useState(true)
  const [tagsExpanded, setTagsExpanded] = useState(false)
  const [additionalFiltersExpanded, setAdditionalFiltersExpanded] = useState(false)
  const [technologiesExpanded, setTechnologiesExpanded] = useState(false)
  const [cloudProvidersExpanded, setCloudProvidersExpanded] = useState(false)
  const [expandedCloudProviders, setExpandedCloudProviders] = useState<Partial<Record<IntegrationType, boolean>>>({})
  const handleFilterChange = (filterType: keyof InventoryFilters, value: any) => {
    setFilters((prev) => ({ ...prev, [filterType]: value }))
  }

  const handleSelectedPolicyChange = (policyId: string | number) => {
    const policy = _.find(policies, { id: policyId }) as InventoryPolicy | undefined
    if (policy) {
      setSelectedPolicy(policy)
    }
  }
  const toggleExpandedCloudProviders = (cloudProvider: IntegrationType) => {
    const newCloudProviders: Partial<Record<IntegrationType, boolean>> = { ...expandedCloudProviders }
    newCloudProviders[cloudProvider] = !expandedCloudProviders[cloudProvider]
    setExpandedCloudProviders(newCloudProviders)
  }
  const toggleCloudProvidersExpanded = () => {
    setCloudProvidersExpanded(!cloudProvidersExpanded)
  }
  const toggleStatusExpand = () => {
    setStatusExpanded(!statusExpanded)
  }

  const toggleTagsExpand = () => {
    setTagsExpanded(!tagsExpanded)
  }

  const toggleAdditionalFiltersExpand = () => {
    setAdditionalFiltersExpanded(!additionalFiltersExpanded)
  }

  const toggleTechnologiesExpand = () => {
    setTechnologiesExpanded(!technologiesExpanded)
  }

  const WindowListBox = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const items = React.Children.toArray(children)

    const renderRow = ({ data, index, style }: ListChildComponentProps<React.ReactNode[]>) => <div style={style}>{data[index]}</div>

    return (
      <div ref={ref} {...other}>
        <FixedSizeList height={Math.min(200, 30 * items.length)} width="100%" itemSize={30} itemCount={items.length} itemData={items}>
          {renderRow}
        </FixedSizeList>
      </div>
    )
  })

  const handleTagChange = (event: React.SyntheticEvent | null, value: (string | { tag: string; amount: number })[], reason: string) => {
    if (reason && reason === 'clear') {
      setFilters((prev) => ({
        ...prev,
        tags: [],
      }))
    } else if (value) {
      let operation: 'delete' | 'add' | undefined
      const tagValue = value.map((v) => {
        if (_.isString(v)) {
          operation = 'delete'
          return v
        } else {
          operation = 'add'
          return v.tag
        }
      })
      if (operation && operation === 'add') {
        setFilters((prev) => ({
          ...prev,
          tags: tagValue,
        }))
      } else if (operation && operation === 'delete') {
        setFilters((prev) => ({
          ...prev,
          tags: _.filter(prev.tags, (tag) => !tagValue.includes(tag)),
        }))
      }
    }
  }
  const handleIntegrationNameChange = (
    event: React.SyntheticEvent | null,
    value: string | string[],
    reason: string,
    integrationType: IntegrationType,
  ) => {
    if (value && !Array.isArray(value)) {
      value = [value]
    }
    if (reason && reason === 'clear') {
      setFilters((prev) => {
        const newIntegrationNames = prev.integrationNames.filter((name) => !integrationNamesMap[integrationType]?.has(name))
        return {
          ...prev,
          integrationNames: newIntegrationNames,
        }
      })
    } else if (value) {
      if (reason && reason === 'removeOption') {
        setFilters((prev) => ({
          ...prev,
          integrationNames: prev.integrationNames.filter((name) => !value.includes(name)),
        }))
      } else {
        if (!Array.isArray(value)) {
          value = [value]
        }
        setFilters((prev) => ({
          ...prev,
          integrationNames: uniq([...prev.integrationNames, ...(value as string[])]),
        }))
      }
    }
  }

  const handleTechnologiesChange = (event: React.SyntheticEvent | null, value?: string[] | string) => {
    if (_.isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: value,
      }))
    } else if (_.isString(value)) {
      setFilters((prev) => ({
        ...prev,
        technologies: _.filter(prev.technologies, (technology) => technology !== value),
      }))
    }
  }

  const getTooltipTitle = (status: any) => {
    let result

    if (status === PolicyComponentStatuses.Impending) {
      result = 'The component is approaching its End-of-Life (EOL) and needs attention soon to remain supported.'
    }
    if (status === PolicyComponentStatuses.Outdated) {
      result = 'The component has surpassed its End-of-Life (EOL) and is no longer supported, requiring immediate action.'
    }
    if (status === PolicyComponentStatuses.Supported) {
      result = 'The component is within its supported lifecycle and requires no immediate action.'
    }

    return result
  }

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Grid container>
        <FormControl size="small" fullWidth sx={{ mb: 2 }}>
          <InputLabel>Policy</InputLabel>
          <Select value={selectedPolicy.id} onChange={(e) => handleSelectedPolicyChange(e.target.value)} label="Policy">
            {_.map(policies, (policy) => (
              <MenuItem key={policy.id} value={policy.id}>
                <Typography variant="caption">{policy.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleStatusExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{statusExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Status
          </Typography>
        </Grid>
        <Collapse in={statusExpanded}>
          {_.map([PolicyComponentStatuses.Impending, PolicyComponentStatuses.Outdated, PolicyComponentStatuses.Supported], (status) => (
            <Grid item xs={12} key={status}>
              <FormControlLabel
                key={status}
                control={
                  <Checkbox
                    checked={filters.status.includes(status)}
                    size="small"
                    onChange={(e) => {
                      const newStatus = e.target.checked ? [...filters.status, status] : filters.status.filter((s) => s !== status)

                      handleFilterChange('status', newStatus)
                    }}
                    sx={{ ml: 4 }}
                  />
                }
                label={
                  <Tooltip title={getTooltipTitle(status)} sx={{ pointerEvents: 'none' }} disableInteractive>
                    <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                      {status}
                    </Typography>
                  </Tooltip>
                }
              />
            </Grid>
          ))}
        </Collapse>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleCloudProvidersExpanded} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{cloudProvidersExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Cloud Providers
          </Typography>
        </Grid>
        <Collapse in={cloudProvidersExpanded}>
          {integrationTypes.map((integrationType) => (
            <Grid item container key={integrationType} paddingLeft={3} alignItems={'center'}>
              <Grid item xs={0.5}>
                <IconButton size="small" onClick={() => toggleExpandedCloudProviders(integrationType)} sx={{ cursor: 'pointer' }}>
                  {expandedCloudProviders[integrationType] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
              </Grid>
              <Grid item>
                <FormControlLabel
                  key={integrationType}
                  control={
                    <Checkbox
                      checked={filters.integrationTypes.includes(integrationType)}
                      size="small"
                      onChange={(e) => {
                        const newIntegrationTypes = e.target.checked
                          ? [...filters.integrationTypes, integrationType]
                          : filters.integrationTypes.filter((type) => type !== integrationType)
                        setFilters((prev) => ({
                          ...prev,
                          integrationTypes: newIntegrationTypes,
                        }))
                      }}
                      sx={{ ml: 4 }}
                    />
                  }
                  label={
                    <Tooltip title={getTooltipTitle(integrationType)} disableInteractive>
                      <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                        {integrationTypesToCloudProviders[integrationType as IntegrationType] || integrationType}
                      </Typography>
                    </Tooltip>
                  }
                />
              </Grid>
              <Collapse in={expandedCloudProviders[integrationType]} sx={{ width: '100%', maxWidth: '180px' }}>
                <Grid container>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={
                      integrationNamesMap[integrationType]
                        ? // @ts-ignore - typescript shouts about Array.from on a set even though it is allowed
                          Array.from(integrationNamesMap[integrationType]).filter((name) => !filters.integrationNames.includes(name))
                        : []
                    }
                    value={filters.integrationNames}
                    onChange={(event, value, reason) => handleIntegrationNameChange(event, value, reason, integrationType)}
                    filterSelectedOptions
                    noOptionsText="No options"
                    sx={{ ml: 4, width: '100%' }}
                    renderTags={() => null}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option': {
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          ':hover': {
                            backgroundColor: '#e0e0e0',
                          },
                        },
                      },
                    }}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props
                      return (
                        <Tooltip title={option} placement="right" key={key} arrow disableInteractive>
                          <li {...rest}>{option}</li>
                        </Tooltip>
                      )
                    }}
                    ListboxComponent={WindowListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Search Integrations"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          mb: 1,
                          '& .MuiOutlinedInput-root': {
                            minHeight: '28px',
                            fontSize: '12px',
                          },
                        }}
                      />
                    )}
                  />
                  {filters.integrationNames.length > 0 &&
                    filters.integrationNames.some((name) => integrationNamesMap[integrationType]?.has(name)) && (
                      <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                        {filters.integrationNames
                          .filter((name) => integrationNamesMap[integrationType]?.has(name))
                          .map((integrationName) => (
                            <Tooltip title={integrationName} key={integrationName} disableInteractive>
                              <FilteredChip
                                key={integrationName}
                                label={integrationName}
                                size="small"
                                onDelete={() => {
                                  handleIntegrationNameChange(null, [integrationName], 'removeOption', integrationType)
                                }}
                                sx={{
                                  maxWidth: '140px',
                                  margin: 0.2,
                                }}
                              />
                            </Tooltip>
                          ))}
                      </Grid>
                    )}
                </Grid>
              </Collapse>
            </Grid>
          ))}
        </Collapse>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleTechnologiesExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{technologiesExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Technologies ({filters.technologies.length})
          </Typography>
        </Grid>
        <Collapse in={technologiesExpanded} sx={{ width: '100%' }}>
          <Grid container>
            <Autocomplete
              multiple
              freeSolo
              options={technologies}
              value={filters.technologies}
              onChange={handleTechnologiesChange}
              filterSelectedOptions
              noOptionsText="No options"
              ListboxComponent={WindowListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
              sx={{ ml: 4, width: '100%' }}
              renderTags={() => null}
              ListboxProps={{
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  },
                },
              }}
              renderOption={(props, option) => {
                const { key, ...rest } = props
                return (
                  <Tooltip title={option} placement="right" key={key} arrow disableInteractive>
                    <li {...rest}>{option}</li>
                  </Tooltip>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search technologies"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      minHeight: '28px',
                      fontSize: '12px',
                    },
                  }}
                />
              )}
            />
            {filters.technologies.length > 0 && (
              <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                {_.map(filters.technologies, (technology) => (
                  <Tooltip title={technology} key={technology} disableInteractive>
                    <FilteredChip
                      key={technology}
                      label={technology}
                      size="small"
                      onDelete={() => {
                        handleTechnologiesChange(null, technology)
                      }}
                      sx={{
                        margin: 0.2,
                      }}
                    />
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        </Collapse>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleTagsExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{tagsExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Tags ({filters.tags.length})
          </Typography>
        </Grid>
        <Collapse in={tagsExpanded} sx={{ width: '100%' }}>
          <Grid container>
            <Autocomplete
              multiple
              freeSolo
              options={tags.filter((tag) => !filters.tags.includes(tag.tag))}
              value={filters.tags}
              onChange={handleTagChange}
              filterSelectedOptions
              noOptionsText="No options"
              sx={{ ml: 4, width: '100%' }}
              renderTags={() => null}
              ListboxProps={{
                sx: {
                  '& .MuiAutocomplete-option': {
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    ':hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  },
                },
              }}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.tag)}
              renderOption={(props, option) => {
                const { key, ...rest } = props
                return (
                  <Tooltip title={option.tag} placement="right" key={key} arrow disableInteractive>
                    <li>
                      <Grid
                        container
                        flexDirection="row"
                        alignItems="stretch"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#e0e0e0',
                          },
                        }}
                        alignContent={'space-between'}
                        wrap="nowrap"
                      >
                        <Grid item overflow={'hidden'} whiteSpace={'nowrap'} flexGrow={1}>
                          <span
                            {...rest}
                            style={{
                              paddingRight: 0,
                              paddingLeft: 13,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option.tag}
                          </span>
                        </Grid>

                        <Grid item>
                          <span
                            {...rest}
                            style={{
                              paddingLeft: 5,
                              paddingRight: 9,
                              position: 'static',
                            }}
                          >
                            ({option.amount})
                          </span>
                        </Grid>
                      </Grid>
                    </li>
                  </Tooltip>
                )
              }}
              ListboxComponent={WindowListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Search tags"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      minHeight: '28px',
                      fontSize: '12px',
                    },
                  }}
                />
              )}
            />
            {tags.length > 0 && (
              <Grid container flexDirection="row" sx={{ ml: 4, mt: 1 }}>
                {_.map(filters.tags, (tag) => (
                  <Tooltip title={tag} key={tag} disableInteractive>
                    <FilteredChip
                      key={tag}
                      label={tag}
                      size="small"
                      onDelete={() => {
                        handleTagChange(null, [tag], 'removeOption')
                      }}
                      sx={{
                        margin: 0.2,
                      }}
                    />
                  </Tooltip>
                ))}
              </Grid>
            )}
          </Grid>
        </Collapse>
        <Grid container flexDirection="row" alignItems="center" onClick={toggleAdditionalFiltersExpand} sx={{ cursor: 'pointer' }}>
          <IconButton size="small">{additionalFiltersExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
          <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
            Additional Filters
          </Typography>
        </Grid>
        <Collapse in={additionalFiltersExpanded} sx={{ width: '100%' }}>
          <Grid container>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.extendedSupport}
                  size="small"
                  onChange={(e) => {
                    const newStatus = e.target.checked ? true : false
                    handleFilterChange('extendedSupport', newStatus)
                  }}
                  sx={{ ml: 4 }}
                />
              }
              label={
                <Tooltip title="Components that are subject to extended support fees" sx={{ pointerEvents: 'none' }} disableInteractive>
                  <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                    Extended support
                  </Typography>
                </Tooltip>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.drafttAvailable}
                  size="small"
                  onChange={(e) => {
                    const newStatus = e.target.checked ? true : false

                    handleFilterChange('drafttAvailable', newStatus)
                  }}
                  sx={{ ml: 4 }}
                />
              }
              label={
                <Tooltip title="Components that have an available upgrade analysis (Draftt)" sx={{ pointerEvents: 'none' }} disableInteractive>
                  <Typography variant="subtitle2" sx={{ fontWeight: 400, fontSize: '12px' }}>
                    Draftt Available
                  </Typography>
                </Tooltip>
              }
            />
          </Grid>
        </Collapse>
      </Grid>
      <Divider sx={{ mt: 1 }} />
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        sx={{ cursor: 'pointer', fontSize: '12px', ':hover': { backgroundColor: '#e0e0e0' } }}
        onClick={() => {
          setFilters(emptyFilters())
        }}
      >
        <IconButton size="small">{<Clear fontSize="small" />}</IconButton>
        Clear All Filters
      </Grid>
    </Paper>
  )
}

export default FiltersSidebar
