import { useEffect, useState } from 'react'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { getAxiosInstance } from '../../../../../axiosInstance'
import Loader from '../../../../loader'
import { DRAFTT_STATUSES, INTEGRATIONS } from '../../../../../consts'
import DemoMetricSummaryCard from './DemoMetricSummaryCard'
import { Check, CloudOutlined, IntegrationInstructionsOutlined, PolicyOutlined, Timeline } from '@mui/icons-material'

import { styled as muiStyled } from '@mui/material/styles'
import { Avatar } from '@mui/material'

export const MetricIcon = muiStyled(Avatar)`
  height: 36px;
  width:36px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: black;
  
  img {
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`

const DemoMetricSummary = (props: any) => {
  const allComponents = props.components
  const allDraftts = props.draftts
  const allPolicies = props.policies
  const allIntegrations = props.integrations
  const allCertificates = props.certificates

  const [k8sComponentsData, setK8sComponentsData] = useState([])
  const [metricsData, setMetricsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const calculateMetricsData = () => {
    const completedDraftts = _.filter(allDraftts, { status: DRAFTT_STATUSES.COMPLETED })
    const activeDraftts = _.filter(allDraftts, { status: DRAFTT_STATUSES.ACTIVE })
    const k8sIntegrations = _.filter(allIntegrations, { type: INTEGRATIONS.k8sExplorer.type })

    return [
      {
        title: dic.dashboard.metrics_summary.metrics.total_cloud_components,
        value: allComponents.length + allCertificates.length,
        icon: (
          <MetricIcon>
            <CloudOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.active_draftts,
        value: activeDraftts.length,
        icon: (
          <MetricIcon>
            <Timeline fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.completed_draftts,
        value: completedDraftts.length,
        icon: (
          <MetricIcon>
            <Check fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.policies,
        value: allPolicies.length,
        icon: (
          <MetricIcon>
            <PolicyOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.k8s_integrations,
        value: k8sIntegrations.length,
        icon: (
          <MetricIcon>
            <IntegrationInstructionsOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.k8s_components,
        value: k8sComponentsData.length,
        icon: (
          <MetricIcon>
            <img src={`https://draftt-public.s3.amazonaws.com/icons/kubernetes.svg`} alt={dic.dashboard.metrics_summary.metrics.active_draftts} />
          </MetricIcon>
        ),
      },
    ]
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const k8sActiveK8sComponents = await getAxiosInstance().get(`/component/k8s/active`)
        setK8sComponentsData(k8sActiveK8sComponents.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setMetricsData(calculateMetricsData())
  }, [allComponents, allDraftts, allPolicies, allIntegrations, k8sComponentsData])

  if (loading) {
    return <Loader />
  }

  return (
    <div
      style={{
        display: 'grid',
        gridGap: '15px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        height: '100%',
        alignContent: 'center',
        width: '100%'
      }}
    >
      {_.map(metricsData, (metric, index) => {
        return <DemoMetricSummaryCard metric={metric} key={index} />
      })}
    </div>
  )
}

export default DemoMetricSummary
